import {
  CSSProperties,
  ReactNode,
  Ref,
  forwardRef,
  useState,
  useMemo,
  useRef,
  isValidElement,
  cloneElement,
  ReactElement,
} from 'react';
import classNames from 'classnames';
import {
  autoUpdate,
  offset,
  flip,
  shift,
  useFloating,
  useId,
  useHover,
  useClick,
  useFocus,
  useDismiss,
  useInteractions,
  useMergeRefs,
  useDelayGroup,
  useTransitionStyles,
  FloatingPortal,
  FloatingArrow,
  arrow,
} from '@floating-ui/react';
import type { Placement } from '@floating-ui/react';

import styles, { tooltipBorder } from './tooltip.module.scss';

interface TooltipBaseProps {
  size?: 'default' | 'compact';
  color?: 'default' | 'light' | 'error';
  title?: ReactNode;
  className?: classNames.Argument;
  children?: ReactNode;
  style?: CSSProperties;
}

export const DefaultTooltipDelay = 400;
const ArrowHeight = 8;
const ArrowGap = 4;

/**
 * @deprecated
 * Use the Tooltip component instead or its styles
 */
export const TooltipBase = forwardRef(function TooltipBase(
  { size = 'default', color = 'default', title, className, children, style }: TooltipBaseProps,
  ref: Ref<HTMLDivElement>,
) {
  return (
    <div
      className={classNames(styles.tooltipBase, className, {
        [styles.sizeDefault]: size === 'default',
        [styles.sizeCompact]: size === 'compact',
        [styles.colorDefault]: color === 'default',
        [styles.colorLight]: color === 'light',
        [styles.colorError]: color === 'error',
      })}
      style={style}
      ref={ref}>
      {title !== undefined && <span className={styles.tooltipTitle}>{title}</span>}
      {children}
    </div>
  );
});

type Delay = number | Partial<{ open: number; close: number }>;

interface TooltipProps {
  content: React.ReactNode | undefined;
  placement?: Placement;
  delay?: Delay;
  children: ReactElement;
  trigger?: 'hover' | 'click';
  hasArrow?: boolean;
}

export const Tooltip = forwardRef<HTMLElement, TooltipProps>(function Tooltip(
  { children, content, placement = 'top', trigger = 'hover', delay = 0, hasArrow = true },
  propRef,
) {
  const [open, setOpen] = useState(false);
  const arrowRef = useRef(null);

  const { refs, floatingStyles, context } = useFloating({
    placement,
    open,
    onOpenChange: setOpen,
    middleware: [
      offset(ArrowHeight + ArrowGap),
      shift({ padding: 8 }),
      flip({ fallbackAxisSideDirection: 'start' }),
      arrow({ element: arrowRef }), // Order matters: arrow middleware has to calculate position after offset, shift, flip
    ],
    whileElementsMounted: autoUpdate,
  });

  const { delay: groupDelay, currentId, isInstantPhase } = useDelayGroup(context);

  const hover = useHover(context, {
    delay: groupDelay === 0 ? delay : groupDelay,
    move: false,
    enabled: trigger === 'hover',
  });
  const click = useClick(context, { enabled: trigger === 'click' });
  const focus = useFocus(context);
  const dismiss = useDismiss(context);
  const mergedRefs = useMergeRefs(
    [
      refs.setReference,
      propRef,
      isValidElement(children) && typeof children.type !== 'string' ? (children as any).ref : null,
    ].filter(Boolean),
  );
  const { getReferenceProps, getFloatingProps } = useInteractions([hover, click, focus, dismiss]);

  const tooltipId = useId();
  const tooltipProps = useMemo(getFloatingProps, [getFloatingProps]);
  const triggerProps = useMemo(
    () =>
      getReferenceProps({
        ref: mergedRefs,
        'aria-labelledby': tooltipId,
        ...children.props,
      }),
    [getReferenceProps, mergedRefs, tooltipId, children.props],
  );

  const instantDuration = 0;
  const openDuration = 250;
  const closeDuration = 250;

  const { isMounted, styles: transitionStyles } = useTransitionStyles(context, {
    duration: isInstantPhase
      ? {
          open: instantDuration,
          close: currentId === context.floatingId ? closeDuration : instantDuration,
        }
      : {
          open: openDuration,
          close: closeDuration,
        },
    initial: {
      opacity: 0,
      scale: '0.925',
    },
    common: ({ side }) => ({
      transitionTimingFunction: 'cubic-bezier(.18,.87,.4,.97)',
      transformOrigin: {
        top: 'bottom',
        left: 'right',
        bottom: 'top',
        right: 'left',
      }[side],
    }),
  });

  if (content === undefined || content === '') {
    return children;
  }

  return (
    <>
      {isValidElement(children) && cloneElement(children, triggerProps)}
      {isMounted && (
        <FloatingPortal id={tooltipId}>
          <div role="tooltip" ref={refs.setFloating} style={floatingStyles}>
            <div className={styles.tooltip} style={transitionStyles} {...tooltipProps}>
              {content}

              {hasArrow && (
                <FloatingArrow
                  ref={arrowRef}
                  context={context}
                  tipRadius={1}
                  height={ArrowHeight}
                  stroke={tooltipBorder}
                  strokeWidth={1}
                />
              )}
            </div>
          </div>
        </FloatingPortal>
      )}
    </>
  );
});
