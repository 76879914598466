import { createBasePipeline } from '@/core/pipeline';

import { generateCellId } from '@/core/cell';

import { Exploration, PipelineOperation, Model } from '../types';

export const getModelExploration = (
  model: Pick<Model, 'name' | 'modelId' | 'labels' | 'description'>,
  operations?: PipelineOperation[],
): Exploration => ({
  explorationId: model.modelId,
  name: model.name,
  description: model.description,
  labels: model.labels,
  parameters: [],
  options: {
    explorationForModelId: model.modelId,
  },
  view: {
    cells: [
      {
        id: generateCellId(),
        kind: 'records',
        title: model.name,
        pipeline: createBasePipeline({
          baseModelId: model.modelId,
          operations: operations ?? [],
        }),
      },
    ],
  },
});
