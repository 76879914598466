import { useEffect, useState } from 'react';
import classNames from 'classnames';

import { getDefaultFormattedLocalDate } from '@/lib/date';

import { useToastContext } from '../../components/toast';
import { Select } from '../../components/form/select';
import { Input } from '../../components/form/input';
import { VariableDefinition } from '../types';
import { FormField } from '../../components/form/form-field';
import { generateUniqueVariableKey, isUniqueVariableKey } from '../utils';
import { InlineButton } from '../../components/button';
import { Icon } from '../../components/icon';
import { SortableItem } from '../components/sortable-item';

import formStyles from '../../components/form/form.module.scss';
import styles from './edit-variable.module.scss';

interface VariableDefinitionFormProps {
  definition: VariableDefinition;
  existingKeys: VariableDefinition['key'][];
  onSubmit: (definition: VariableDefinition) => void;
}

const KindOptions: { value: VariableDefinition['kind']; label: string }[] = [
  { value: 'string', label: 'String' },
  { value: 'number', label: 'Number' },
  { value: 'boolean', label: 'Boolean' },
  { value: 'enum', label: 'Select' },
  { value: 'date', label: 'Date' },
  { value: 'time_interval', label: 'Time Interval' },
];

export const VariableDefinitionForm = (props: VariableDefinitionFormProps) => {
  const { definition, existingKeys, onSubmit } = props;

  const addToast = useToastContext();
  const [key, setKey] = useState<VariableDefinition['key']>(definition.key);

  const handleKindChange = (value: VariableDefinition['kind']) => {
    switch (value) {
      case 'string':
        onSubmit({ key: definition.key, kind: value, defaultValue: '' });
        break;
      case 'number':
        onSubmit({ key: definition.key, kind: value, defaultValue: 0 });
        break;
      case 'boolean':
        onSubmit({ key: definition.key, kind: value, defaultValue: false });
        break;
      case 'enum':
        onSubmit({
          key: definition.key,
          kind: value,
          options: [{ value: '' }, { value: '' }, { value: '' }],
          defaultValue: '',
        });
        break;
      case 'date':
        onSubmit({
          key: definition.key,
          kind: value,
          defaultValue: getDefaultFormattedLocalDate(),
        });
        break;
      case 'time_interval':
        onSubmit({ key: definition.key, kind: value, defaultValue: 'month' });
        break;
    }
  };

  const handleKeyChange = () => {
    if (isUniqueVariableKey(key, existingKeys)) {
      return onSubmit({ ...definition, key });
    }

    const { uniqueKey, stepsTaken } = generateUniqueVariableKey(key, existingKeys);

    addToast({
      title: `Variable renamed to “${uniqueKey}”`,
      content: () => `Variable names need to be unique. “-${stepsTaken}” was added to the name.`,
      kind: 'success',
    });

    onSubmit({ ...definition, key: uniqueKey });
  };

  const handleEnumOptionChange = (idx: number, value: string) => {
    if (definition.kind !== 'enum') {
      return;
    }

    const updatedOptions = definition.options.map((option, optionIdx) =>
      optionIdx === idx ? { ...option, value } : option,
    );

    onSubmit({
      ...definition,
      defaultValue: updatedOptions[0].value ?? '',
      options: updatedOptions,
    });
  };

  const handleEnumOptionRemove = (idx: number) => {
    if (definition.kind !== 'enum') {
      return;
    }

    onSubmit({
      ...definition,
      options: [...definition.options.slice(0, idx), ...definition.options.slice(idx + 1)],
    });
  };

  const [draggedIndex, setDraggedIndex] = useState<number | null>(null);
  const onReorder = (fromIndex: number, toIndex: number) => {
    if (definition.kind !== 'enum') {
      return;
    }

    const [option] = definition.options.splice(fromIndex, 1);
    const idx = toIndex;

    onSubmit({
      ...definition,
      options: [...definition.options.slice(0, idx), option, ...definition.options.slice(idx)],
    });
  };

  const handleSubmit = (event: React.FormEvent) => {
    event.preventDefault();

    onSubmit({ ...definition, key });
  };

  return (
    <form className={classNames(formStyles.formHorizontal)} onSubmit={handleSubmit}>
      <div className={formStyles.formRow}>
        <FormField
          label="Name"
          error={isUniqueVariableKey(key, existingKeys) ? undefined : 'Name must be unique'}>
          <Input
            required
            type="text"
            placeholder="Enter variable name"
            value={key}
            onBlur={handleKeyChange}
            onChange={(event) => setKey(event.currentTarget.value)}
          />
        </FormField>
      </div>
      <div className={formStyles.formRow}>
        <FormField label="Type">
          <Select options={KindOptions} value={definition.kind} onChange={handleKindChange} />
        </FormField>
      </div>
      {definition.kind === 'enum' && (
        <>
          {(definition.options ?? []).map((option, idx) => (
            <SortableItem
              className={classNames(styles.option)}
              key={idx}
              scope={'variable-enum-options'}
              index={idx}
              draggedIndex={draggedIndex}
              setDraggedIndex={setDraggedIndex}
              onReorder={onReorder}>
              <Icon name={'DragHandle'} size={10} className={styles.optionDragHandle} />
              <EnumOptionEditor
                idx={idx}
                value={option.value}
                onChange={(value) => handleEnumOptionChange(idx, value)}
                onRemove={() => handleEnumOptionRemove(idx)}
              />
            </SortableItem>
          ))}
          <div>
            <InlineButton
              onClick={() =>
                onSubmit({ ...definition, options: [...definition.options, { value: '' }] })
              }>
              <Icon name="Plus" size={15} /> Option
            </InlineButton>
          </div>
        </>
      )}
    </form>
  );
};

interface EnumOptionEditorProps {
  idx: number;
  value: string;
  onChange: (value: string) => void;
  onRemove: () => void;
}

const EnumOptionEditor = (props: EnumOptionEditorProps) => {
  const [value, setValue] = useState<string>(props.value);

  useEffect(() => setValue(props.value), [props.value]);

  return (
    <FormField label={`Option ${props.idx + 1}`} onRemove={props.onRemove}>
      <Input
        required
        type="text"
        placeholder="Enter name"
        value={value}
        onChange={(e) => setValue(e.currentTarget.value)}
        onKeyUp={(e) => e.key === 'Enter' && props.onChange(value)}
        onBlur={(e) => props.onChange(e.currentTarget.value)}
      />
    </FormField>
  );
};
