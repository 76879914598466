import { useEffect, useState } from 'react';
import classNames from 'classnames';
import { loadPyodide, version as pyodide_version } from 'pyodide';
import { useApolloClient } from '@apollo/client';
import { PyProxy } from 'pyodide/ffi';
import InnerHTML from 'dangerously-set-html-content';
import { python as pythonLanguage } from '@codemirror/lang-python';

import { CodeMirror, lineNumbers, tabIndentExtension } from '@/components/codemirror';
import { useAccountContext } from '@/lib/accounts/context';
import { Button } from '@/components/button';
import { Icon } from '@/components/icon';
import { Loader } from '@/components/loader';
import { ErrorBoundary, GenericFallback } from '@/lib/error';

import { useLoadingStatus } from '@/lib/hooks';

import { setCellPipeline, setCellTitle } from '@/core/cell';

import { Model, Sort, SortItem, PythonCell } from '../../types';
import { PaginatedRecords } from '../../components/paginated-records';
import { HorizontalScrollTable } from '../../components/horizontal-scroll-table';
import { MasterBadge } from '../../components/master-badge';
import { DataTableProperty, DataTableRow } from '../../components/datatable';
import { CollapseButton, CollapsibleContainer, CollapsibleContent } from '../collapsible-cell';
import { CellTitle } from '../cell-title';
import { CellControls } from '../cell-controls';
import { useExplorationContext } from '../exploration-context';
import { useExplorationCellContext } from '../exploration-cell-context';
import {
  getPythonOperation,
  findDataSource,
  loadDataByDataSource,
  parseCustomPackages,
  setPythonOperation,
  convertToPythonType,
} from './utils';
import { getNumberOfChildPipelines } from '../../pipeline/utils';
import { PythonBlockResult, PythonLog, TableResult } from './types';
import { convertPythonResult, isPyProxy } from './converters';
import { useMetadataContext } from '../../metadata-context';

import container from '../exploration.module.scss';
import style from './pythoncell.module.scss';
import editorStyle from '../code-editor.module.scss';

interface PythonCellViewProps {
  cell: PythonCell;
  onSelectCell?: () => void;
  onSetDraggable: (value: boolean) => void;
}

export const PythonCellView = (props: PythonCellViewProps) => {
  const { cell } = props;
  const [isDragHovered, setIsDragHovered] = useState(false);

  const { setCell, isCollapsible, copyCell } = useExplorationCellContext();
  const { exploration } = useExplorationContext();

  const [result, setResult] = useState<PythonBlockResult>(undefined);
  const operation = getPythonOperation(cell.pipeline);
  const code = operation?.parameters.code ?? '';

  useEffect(() => {
    const { rows, fields } = operation?.parameters ?? {};
    if (result !== undefined || rows !== undefined || fields !== undefined) {
      setCell(setCellPipeline(cell, setPythonOperation(cell.pipeline, code, result)));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [code, result]);

  const handleSetCode = (code: string) => {
    setCell(setCellPipeline(cell, setPythonOperation(cell.pipeline, code, result)));
  };

  const handleSetIsDragHovered = (value: boolean) => {
    props.onSetDraggable(value);
    setIsDragHovered(value);
  };

  const deleteAllowed = getNumberOfChildPipelines(exploration, cell.pipeline) === 0;

  return (
    <CollapsibleContainer className={container.cohortViewCell} onClick={props.onSelectCell}>
      <div className={container.cellHeader}>
        <div className={container.cellControlsContainer}>
          <Icon
            name="DragHandle"
            size={10}
            className={container.dragHandle}
            onMouseOver={() => handleSetIsDragHovered(true)}
            onMouseOut={() => handleSetIsDragHovered(false)}
          />
          <MasterBadge exploration={exploration} pipeline={props.cell.pipeline} />
          <CellTitle
            exploration={exploration}
            value={cell.title ?? '(Untitled)'}
            onChange={(value) => setCell(setCellTitle(cell, value))}
          />
          <CellControls
            exploration={exploration}
            editButtonVisible={false}
            canDelete={deleteAllowed}
            options={(defaultOptions) => [
              ...defaultOptions,
              {
                label: 'Copy block',
                icon: <Icon name="Clipboard" size={16} />,
                onClick: () => copyCell(),
                sort: 31,
              },
            ]}
          />
          {isCollapsible && <CollapseButton />}
        </div>
      </div>
      <CollapsibleContent isDragHovered={isDragHovered}>
        <ErrorBoundary fallback={(errorData) => <GenericFallback {...errorData} />}>
          <PythonView code={code} setCode={handleSetCode} result={result} setResult={setResult} />
        </ErrorBoundary>
      </CollapsibleContent>
    </CollapsibleContainer>
  );
};

interface PythonViewProps {
  code: string;
  setCode: (code: string) => void;
  result: PythonBlockResult;
  setResult: (result: PythonBlockResult) => void;
}

const PythonView = (props: PythonViewProps) => {
  const code = props.code.trim();
  const [log, setLog] = useState<PythonLog[]>([]);

  const appendLog = (log: PythonLog) => setLog((logs) => [...logs, log]);
  const resetLog = () => setLog([]);

  return (
    <>
      <div className={container.cellSection}>
        <PythonInputView
          code={code}
          setCode={props.setCode}
          resetLog={resetLog}
          appendLog={appendLog}
          setResult={props.setResult}
        />
      </div>
      {log.length > 0 && (
        <div className={container.cellSection}>
          <PythonLogView log={log} />
        </div>
      )}
      {props.result?.type === 'primitive' && (
        <div className={container.cellSection}>
          <PythonPrimitiveResponseView value={props.result.value} />
        </div>
      )}
      {props.result?.type === 'image' && (
        <div className={classNames(container.cellSection, style.imageSection)}>
          <img src={props.result.base64ImageString} className={style.imageOutput} />
        </div>
      )}
      {props.result?.type === 'html' && (
        <div className={classNames(container.cellSection, style.htmlSection)}>
          <InnerHTML html={props.result.htmlString} className={style.htmlOutput} />
        </div>
      )}
      {props.result?.type === 'table' && (
        <div className={classNames(container.cellSection, container.tableSection)}>
          <PythonResponseView
            records={props.result.rows}
            fetchMore={async () => {}}
            properties={props.result.fields}
            sort={[]}
            setSort={() => {}}
            loading={false}
            loaded
          />
        </div>
      )}
      {props.result === undefined && (
        <div className={editorStyle.empty}>
          <div className={editorStyle.text}>
            End your code block with an object or expression to see the results.
          </div>
        </div>
      )}
    </>
  );
};

interface PythonInputViewProps {
  code: string;
  setCode: (code: string) => void;
  resetLog: () => void;
  appendLog: (log: PythonLog) => void;
  setResult: (result: PythonBlockResult) => void;
}

const PythonInputView = (props: PythonInputViewProps) => {
  const [code, setCode] = useState(props.code);
  const [executing, setExecuting] = useState(false);

  const { account } = useAccountContext();
  const { exploration } = useExplorationContext();
  const { models } = useMetadataContext();
  const { getVariables } = useExplorationContext();

  useEffect(() => setCode(props.code), [props.code]);

  const client = useApolloClient();
  const loadPyData = async (
    title: string,
    page?: boolean,
    limit?: number,
    page_size?: number,
    columns?: string[],
  ): Promise<TableResult | undefined> => {
    try {
      props.appendLog({ type: 'out', string: `Starting to load data from "${title}"` });
      const dataSource = findDataSource(title, exploration, models, getVariables);

      const data = await loadDataByDataSource(
        dataSource,
        client,
        account.accountId,
        page,
        limit,
        page_size,
        columns,
      );
      console.log('🚀 ~ PythonInputView ~ data:', data);
      props.appendLog({
        type: 'out',
        string: `Finished loading data: ${data?.rows?.length} rows, ${data?.fields?.length} columns`,
      });
      return data;
    } catch (error) {
      props.appendLog({ type: 'error', string: (error as Error).message });
    }
    return undefined;
  };

  const onKeyDown = (event: KeyboardEvent) => {
    if (event.key === 'Enter' && (event.ctrlKey || event.metaKey)) {
      event.preventDefault();
      handleSubmit();
    }
  };

  const handleRunPython = async () => {
    const pyodide = await loadPyodide({
      // url where to load packages from
      indexURL: `https://cdn.jsdelivr.net/pyodide/v${pyodide_version}/full/`,
    });
    pyodide.setStdout({
      batched: (str) =>
        props.appendLog({
          type: 'out',
          string: str,
        }),
    });
    pyodide.setStderr({
      batched: (str) =>
        props.appendLog({
          type: 'error',
          string: str,
        }),
    });

    const { pyCode, pypiPackages } = parseCustomPackages(code);
    await pyodide.loadPackagesFromImports(pyCode);
    await pyodide.loadPackage(['pandas']);

    if (pypiPackages.length > 0) {
      await pyodide.loadPackage(['micropip']);
      const micropip = pyodide.pyimport('micropip');
      for (const pypiPackage of pypiPackages) {
        props.appendLog({
          type: 'out',
          string: `Installing package: ${pypiPackage}`,
        });
        await micropip.install(pypiPackage);
      }
    }
    props.appendLog({
      type: 'out',
      string: 'Packages loaded',
    });

    try {
      pyodide.registerJsModule('supersimple', {
        getBlock: (title: string) => {
          return {
            fetchAll: (
              keyword_args: { limit?: number; page_size?: number; columns?: PyProxy } | undefined,
            ) => {
              const { limit, page_size, columns } = keyword_args ?? {};
              const load = loadPyData(title, false, limit, page_size ?? 2000, columns?.toJs());

              return {
                as_json: async () => {
                  return pyodide.toPy((await load)?.rows ?? []);
                },
                as_df: async () => {
                  const data = await load;

                  const columnTypes: { [key: string]: string } = (data?.fields ?? []).reduce(
                    (acc, field) => ({ ...acc, [field.key]: convertToPythonType(field.type) }),
                    {},
                  );

                  const columnKeyNames: { [key: string]: string } = (data?.fields ?? []).reduce(
                    (acc, field) => ({ ...acc, [field.key]: field.name }),
                    {},
                  );

                  return pyodide
                    .pyimport('pandas')
                    .DataFrame.callKwargs({ data: pyodide.toPy(data?.rows ?? []) })
                    .astype.callKwargs({ dtype: pyodide.toPy(columnTypes) })
                    .rename.callKwargs({ columns: pyodide.toPy(columnKeyNames) });
                },
              };
            },
          };
        },
      });

      let result: PyProxy | undefined;
      try {
        result = await pyodide.runPythonAsync(pyCode);
        const convertedPyResult = convertPythonResult(pyodide, result);
        if (convertedPyResult === undefined) {
          props.appendLog({
            type: 'out',
            string: 'Code did not end with an object or expression. No output to display.',
          });
          return;
        }
        props.setResult(convertedPyResult);
      } finally {
        if (isPyProxy(result)) {
          result?.destroy();
        }
      }
    } catch (error) {
      props.appendLog({
        type: 'error',
        string: `${error}`,
      });
    } finally {
      pyodide.unregisterJsModule('supersimple');
    }
  };

  const handleSubmit = async () => {
    props.setCode(code);
    props.resetLog();
    props.setResult(undefined);

    if (code === '') {
      return;
    }

    try {
      props.setCode(code);
      setExecuting(true);
      await handleRunPython();
    } catch (error) {
      props.appendLog({
        type: 'error',
        string: `\\n${(error as Error).message}\n${(error as Error).stack}\n`,
      });
    } finally {
      setExecuting(false);
    }
  };

  return (
    <div className={editorStyle.inputContainer}>
      <CodeMirror
        value={code}
        onChange={setCode}
        onKeyDown={onKeyDown}
        extensions={[pythonLanguage(), lineNumbers(), tabIndentExtension]}
        className={classNames(editorStyle.codeInput)}
      />

      <div className={editorStyle.buttons}>
        <Button variant="primary" size="small" loading={executing} onClick={handleSubmit}>
          Execute
        </Button>
      </div>
    </div>
  );
};

interface PythonLogViewProps {
  log: PythonLog[];
}

const PythonLogView = (props: PythonLogViewProps) => {
  return (
    <div className={style.logContainer}>
      {props.log.map((log, index) => (
        <div key={index} className={log.type === 'error' ? style.errorLog : style.regularLog}>
          {log.string}
        </div>
      ))}
    </div>
  );
};

interface PythonPrimitiveResponseViewProps {
  value: any;
}

const PythonPrimitiveResponseView = (props: PythonPrimitiveResponseViewProps) => {
  return <div className={style.logContainer}>{props.value}</div>;
};

interface PythonResponseViewProps {
  records: DataTableRow[];
  fetchMore: () => Promise<void>;
  properties: DataTableProperty[];
  sort: Sort;
  setSort: (sort: SortItem | null) => void;
  loaded: boolean;
  loading: boolean;
}

const createModelFromProperties = (properties: DataTableProperty[]): Model => ({
  modelId: 'python-results',
  name: 'Python',
  primaryKey: [],
  properties: properties.map((property) => ({
    ...property,
    type: property.type ?? 'String',
  })),
  relations: [],
  labels: {},
});

const PythonResponseView = (props: PythonResponseViewProps) => {
  const { cell } = useExplorationCellContext();
  const { isFirstLoad, isSubsequentLoad } = useLoadingStatus(props.loading, props.loaded);
  return (
    <PaginatedRecords
      properties={props.properties}
      sort={props.sort}
      setSort={props.setSort}
      records={props.records}
      model={createModelFromProperties(props.properties)}
      fetchMore={props.fetchMore}
      footerContent={isSubsequentLoad && <Loader />}
      cellHeight={cell.viewOptions?.height}
      canEditPipeline={false}
      loading={isFirstLoad}
      component={HorizontalScrollTable}
    />
  );
};
