import { first } from 'lodash';

import {
  AggregatedVisualisation,
  ChartType,
  DereferencedPipeline,
  Metric,
  Model,
  QueryVariables,
  SimpleVisualisation,
  Visualisation,
  isAggregatedVisualisation,
} from '../types';
import { Toggle } from '../../components/form/toggle';
import {
  disableShowValues,
  enableShowValues,
  getBigNumberFields,
  getChartType,
  getQueryForVisualisation,
  isBigNumberToggleable,
  isNumericField,
  setBigNumberOptions,
  setSecondaryAxisKey,
  setSeriesChartType,
  setSeriesColor,
  toggleVisualisationAggregation,
} from '../components/visualisation/utils';
import { getDereferencedPipelineFields } from '../pipeline/state';
import { EditAggregatedVisualisation } from './edit-aggregated-visualisation';
import { EditSimpleVisualisation } from './edit-simple-visualisation';
import { EditBigNumberVisualisation } from './edit-big-number-visualisation';

import { buildSeriesMenuItems } from './utils';

import form from '../../components/form/form.module.scss';

interface EditVisualisationProps {
  visualisation: Visualisation;
  pipeline: DereferencedPipeline;
  models: Model[];
  metrics: Metric[];
  variables: QueryVariables;
  onChange: (visualisation: Visualisation) => void;
}

export const EditVisualisation = (props: EditVisualisationProps) => {
  const { visualisation, pipeline, models, metrics, variables, onChange } = props;

  const isAggregated = isAggregatedVisualisation(visualisation);
  const stateContext = { models, variables, metrics };
  const fields = getDereferencedPipelineFields(pipeline, stateContext);
  const options = visualisation.viewOptions ?? {};
  const bigNumberOptions = options.bigNumber;
  const bigNumberFieldKey = bigNumberOptions?.key;
  const { pipeline: visualisationPipeline, groups } = getQueryForVisualisation(
    pipeline,
    fields,
    visualisation,
  );
  const isBigNumberVisible = bigNumberFieldKey !== undefined;
  const visualisationFields = getDereferencedPipelineFields(visualisationPipeline, stateContext);
  const chartType = getChartType(fields, visualisation);

  const handleAggregationToggleChange = (checked: boolean) => {
    onChange(toggleVisualisationAggregation(checked, visualisation, fields));
  };

  const handleBigNumberToggleChange = (checked: boolean) => {
    const key = checked ? first(getBigNumberFields(visualisationFields))?.key : undefined;

    onChange({
      ...visualisation,
      viewOptions: setBigNumberOptions(
        { bigNumber: key !== undefined ? { key } : undefined },
        visualisation.viewOptions,
        {
          mainAxisKey: visualisation.mainAxisKey,
        },
      ),
    });
  };

  const handleSecondaryAxisChange = (key: string, checked: boolean) => {
    onChange({
      ...visualisation,
      viewOptions: setSecondaryAxisKey(key, checked, visualisation.viewOptions),
    });
  };

  const handleSeriesChartTypeChange = (key: string, chartType: ChartType) => {
    onChange({
      ...visualisation,
      viewOptions: setSeriesChartType(key, chartType, visualisation.viewOptions),
    });
  };

  const handleShowValuesChange = (key: string, checked: boolean) => {
    onChange({
      ...visualisation,
      viewOptions: checked
        ? enableShowValues(key, visualisation.viewOptions)
        : disableShowValues(key, visualisation.viewOptions),
    });
  };

  const handleSeriesColorChange = (key: string, color: string) => {
    onChange({
      ...visualisation,
      viewOptions: setSeriesColor(key, color, visualisation.viewOptions),
    });
  };

  const handleSeriesRemove = (key: string) => {
    onChange({
      ...visualisation,
      valueKeys: visualisation.valueKeys.filter((k) => k !== key),
    });
  };

  const getSeriesMenuOptions = (seriesKey: string) => {
    return buildSeriesMenuItems({
      seriesKey,
      visualisation,
      isChartTypeDisabled: chartType !== 'grouped-timeseries',
      isSecondaryAxisDisabled: chartType !== 'grouped-timeseries',
      isSeriesRemovable: !isAggregated && visualisation.valueKeys.length > 1, //Aggregation editor handles removing
      onShowValuesChange: handleShowValuesChange,
      onSeriesColorChange: handleSeriesColorChange,
      onSeriesChartTypeChange: handleSeriesChartTypeChange,
      onSecondaryAxisChange: handleSecondaryAxisChange,
      onSeriesRemove: handleSeriesRemove,
    });
  };

  return (
    <div className={form.formHorizontal}>
      <Toggle
        checked={isAggregated}
        disabled={!fields.some(isNumericField)}
        onChange={handleAggregationToggleChange}
        size="small">
        Aggregate
      </Toggle>
      {isAggregated ? (
        <EditAggregatedVisualisation
          visualisation={visualisation as AggregatedVisualisation}
          pipeline={pipeline}
          models={models}
          metrics={metrics}
          variables={variables}
          getSeriesMenuOptions={getSeriesMenuOptions}
          onChange={onChange}
        />
      ) : (
        <EditSimpleVisualisation
          visualisation={visualisation as SimpleVisualisation}
          pipeline={pipeline}
          models={models}
          metrics={metrics}
          variables={variables}
          getSeriesMenuOptions={getSeriesMenuOptions}
          groups={groups}
          onChange={onChange}
        />
      )}
      <hr className={form.dashed} />
      <Toggle
        checked={isBigNumberVisible}
        disabled={!isBigNumberToggleable(visualisation, visualisationFields, groups)}
        onChange={handleBigNumberToggleChange}
        size="small">
        Show first row as big number
      </Toggle>
      {isBigNumberVisible ? (
        <EditBigNumberVisualisation
          visualisation={visualisation}
          fields={fields}
          visualisationFields={visualisationFields}
          onChange={onChange}
        />
      ) : null}
    </div>
  );
};
