import styles from './master-badge.module.scss';

const colors = [
  styles.masterBadgeColor1,
  styles.masterBadgeColor2,
  styles.masterBadgeColor3,
  styles.masterBadgeColor4,
  styles.masterBadgeColor5,
  styles.masterBadgeColor6,
];

export const masterBadgeColor = (index: number | undefined) =>
  index !== undefined ? colors[index % colors.length] : undefined;
