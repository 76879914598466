import { scaleLinear, scaleOrdinal } from '@visx/scale';
import { ParentSize } from '@visx/responsive';
import { AxisBottom } from '@visx/axis';
import { useRef } from 'react';
import classNames from 'classnames';
import { isArray, uniq } from 'lodash';

import { notNil } from '@/lib/utils';
import { NoMatchBanner } from '@/components/banner';
import { CheckboxWithLabel } from '@/components/form/checkbox';
import { formatPropertyValue } from '@/explore/utils/format';

import { Grouping, ValueClickHandler } from '@/explore/types';

import { CategoryData, GroupedChartData } from '../grouped-chart/types';
import { Legend } from '../legend';
import { GroupedChart } from '../grouped-chart';
import { chartColors } from '../utils';
import {
  flattenGroupedChartData,
  getAxisFormat,
  getAxisType,
  getMaxStackedValue,
  getMaxValue,
  getMinStackedValue,
  getMinValue,
  getSeries,
  getValueKeys,
} from '../grouped-chart/utils';

import { HorizontalBarChart } from './horizontal-bar-chart';
import { StackedHorizontalBarChart } from './stacked-horizontal-bar-chart';

import { getLegendData } from '../legend/utils';

import commonStyles, {
  barColor,
  minChartHeight,
  multiChartChartHeight,
} from '../charts.module.scss';
import styles from './grouped-horizontal-chart.module.scss';

const ValuePaddingCoef = 0.1;
const ChartHeight = parseInt(multiChartChartHeight);
const MinChartHeight = parseInt(minChartHeight);
const WidthPerGroup = parseInt(commonStyles.widthPerGroup);
const MinChartWidth = 50;

type GroupedHorizontalBarChartProps = {
  data: GroupedChartData<CategoryData>;
  grouping: Grouping[];
  stacked?: boolean;
  roundTickValues?: boolean;
  isResized?: boolean;
  isTableVisible?: boolean;
  isNotFullData?: boolean;
  setStacked?: (stacked: boolean) => void;
  onValueClick?: ValueClickHandler;
};

export const GroupedHorizontalBarChart = ({
  data,
  grouping,
  stacked = false,
  roundTickValues = false,
  isResized = false,
  isTableVisible = true,
  isNotFullData = false,
  setStacked,
  onValueClick,
}: GroupedHorizontalBarChartProps) => {
  const seriesDomain = getValueKeys(data);
  const footerRef = useRef<HTMLDivElement>(null);

  const userDefinedColors = getSeries(data)
    .map(({ color }) => color)
    .filter(notNil);
  const colorScale = scaleOrdinal<string, string>({
    domain: seriesDomain,
    range:
      seriesDomain.length === 1 && userDefinedColors.length === 0
        ? [barColor]
        : [...userDefinedColors, ...chartColors],
  });

  const legend = getSeries(data).map(({ key, label }) => ({
    label,
    color: colorScale(key),
  }));
  const axisType = getAxisType(getSeries(data));
  const axisFormat = getAxisFormat(getSeries(data));

  const isStackable = legend.length > 1;
  const groupCount = grouping.length;
  const isMultiChart = groupCount >= 3; // 1st = x axis, 2nd = multiple lines, 3rd, ... = multiple charts
  const customHeightEnabled = !isTableVisible && isResized;
  const chartCount = flattenGroupedChartData(data).length;

  return (
    <ParentSize
      debounceTime={0}
      className={classNames(commonStyles.chartContainer, {
        [commonStyles.customHeight]: customHeightEnabled,
      })}
      style={{
        minHeight:
          isMultiChart && !customHeightEnabled ? `${ChartHeight * chartCount}px` : undefined,
      }}>
      {(parent) => {
        const groupsWidth = WidthPerGroup * Math.max(1, grouping.length - 1);
        const chartWidth = Math.max(MinChartWidth, parent.width - groupsWidth);

        const maxVal = stacked ? getMaxStackedValue(data) : getMaxValue(data);
        const minVal = Math.min(0, stacked ? getMinStackedValue(data) : getMinValue(data));

        const valueScale = scaleLinear<number>({
          range: [0, chartWidth],
          round: true,
          domain: [minVal + minVal * ValuePaddingCoef, maxVal + maxVal * ValuePaddingCoef],
        });

        const footerHeight = footerRef?.current?.clientHeight ?? 75;

        const chartHeight = Math.max(
          MinChartHeight,
          Math.floor((parent.height - footerHeight) / chartCount),
        );

        const tickValues = roundTickValues
          ? uniq(valueScale.ticks().map(Math.floor))
          : valueScale.ticks();

        const isPartialWarningShown = isNotFullData && !isArray(data);

        return (
          <div>
            <GroupedChart
              width={parent.width}
              widthPerGroup={WidthPerGroup}
              data={data}
              renderChart={(chartData, width) => {
                if (chartData.items.length === 0) {
                  return <NoMatchBanner />;
                }

                const chartProps = {
                  data: chartData,
                  width,
                  categoryFormat: { precision: 'month' },
                  colorScale,
                  valueScale,
                  onValueClick,
                  height: chartHeight,
                };
                const isStacked = stacked && chartData.series.length > 1;

                return isStacked ? (
                  <StackedHorizontalBarChart {...chartProps} />
                ) : (
                  <HorizontalBarChart {...chartProps} />
                );
              }}
            />
            <div ref={footerRef}>
              <div
                style={{
                  marginLeft: groupsWidth,
                }}>
                <svg width={chartWidth} height={30}>
                  <AxisBottom
                    scale={valueScale}
                    tickClassName={commonStyles.tickLabel}
                    tickLabelProps={() => ({ textAnchor: 'start' })}
                    tickFormat={(n) =>
                      formatPropertyValue(n.valueOf(), {
                        type: axisType,
                        format: axisFormat,
                        style: 'compact',
                      })
                    }
                    tickValues={tickValues}
                  />
                </svg>
              </div>
              <div className={styles.footer}>
                <div className={styles.partialDataWarning}>
                  {isPartialWarningShown ? `Showing first ${data.chartData.items.length}` : null}
                </div>
                <Legend data={getLegendData(data)} />
                {isStackable ? (
                  <CheckboxWithLabel
                    className={[commonStyles.stackingCheckbox]}
                    checked={stacked}
                    onChange={() => setStacked?.(!stacked)}>
                    Stacked
                  </CheckboxWithLabel>
                ) : null}
              </div>
            </div>
          </div>
        );
      }}
    </ParentSize>
  );
};
