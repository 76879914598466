import { generateUUID } from '@/lib/utils';
import {
  Cell,
  CellViewOptions,
  Pipeline,
  RecordsCell,
  RecordsLikeCell,
  Sort,
  SqlCell,
  Visualisation,
} from '@/explore/types';

export const isRecordsCell = (cell: Cell): cell is RecordsCell => cell.kind === 'records';

export const isSqlCell = (cell: Cell): cell is SqlCell => cell.kind === 'sql';

// Returns true for any cell that results with table like data in its query.
export const isRecordsLikeCell = (cell: Cell): cell is RecordsLikeCell =>
  isRecordsCell(cell) || isSqlCell(cell);

export type CellWithTitle = Extract<Cell, { title?: string | null }>;
export type CellWithPipeline = Extract<Cell, { pipeline?: Pipeline }>;
export function isCellWithPipeline(cell: Cell): cell is CellWithPipeline {
  return 'pipeline' in cell;
}

export type CellWithVisualisations = Extract<Cell, { visualisations?: Visualisation[] }>;
export function isCellWithVisualisations(cell: Cell): cell is CellWithVisualisations {
  return 'visualisations' in cell;
}

export type CellWithSort = Extract<Cell, { sort?: Sort }>;

export type ReplicableCell = RecordsCell;

export const isReplicableCell = (cell: Cell): cell is ReplicableCell => cell.kind === 'records';

export const generateCellId = generateUUID;

export function setCellTitle(cell: CellWithTitle, title: string): CellWithTitle {
  return { ...cell, title };
}

export function setCellViewOptions<T extends Cell>(cell: T, viewOptions?: CellViewOptions): T {
  return { ...cell, viewOptions };
}

export function setCellPipeline(cell: CellWithPipeline, pipeline: Pipeline): CellWithPipeline {
  return { ...cell, pipeline };
}

export function setCellVisualisations(
  cell: CellWithVisualisations,
  visualisations: Visualisation[],
): CellWithVisualisations {
  return { ...cell, visualisations };
}

export function setCellSort(cell: CellWithSort, sort?: Sort): CellWithSort {
  return { ...cell, sort };
}

export function insertCellsAtIdx(cells: Cell[], insertedCells: Cell[], idx: number): Cell[] {
  return [...cells.slice(0, idx), ...insertedCells, ...cells.slice(idx)];
}

export function insertCellAtIdx(cells: Cell[], insertedCell: Cell, idx: number): Cell[] {
  return insertCellsAtIdx(cells, [insertedCell], idx);
}

export function replaceCellAtIdx(cells: Cell[], replaceCell: Cell, idx: number): Cell[] {
  return [...cells.slice(0, idx), replaceCell, ...cells.slice(idx + 1)];
}
