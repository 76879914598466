import { useState } from 'react';

import { Exploration } from '@/explore/types';
import { getCellByPipelineId } from '@/explore/exploration/utils';
import { generateCellId } from '@/core/cell';

export const createMissingCells = (exploration: Exploration, pipelineIds: string[]) => {
  return pipelineIds
    .filter((pipelineId) => getCellByPipelineId(pipelineId, exploration) === undefined)
    .map((pipelineId) => ({
      id: generateCellId(),
      kind: 'records' as const,
      title: `❗ Missing block`,
      pipeline: { baseModelId: '', pipelineId, operations: [] },
    }));
};

/**
 * A hook to 'pad' the exploration with any missing cells and to explicitly
 * store them in react state so the missing icells  stay present until the component
 * is destroyed to prevent them from disappearing immediately when they are made unnecessary.
 */
export const useEnsurePipelinesExist = (exploration: Exploration, pipelineIds: string[]) => {
  const [missingCells] = useState(createMissingCells(exploration, pipelineIds));
  return {
    ...exploration,
    view: {
      ...exploration.view,
      cells: [...missingCells, ...exploration.view.cells],
    },
  };
};
