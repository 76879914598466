interface IntercomOptions {
  email?: string;
  hide_default_launcher?: boolean;
}

const isLoaded = () => 'Intercom' in window;

export const setupChatWidget = (options: IntercomOptions) =>
  isLoaded() &&
  Intercom('boot', {
    api_base: 'https://api-iam.intercom.io',
    ...options,
  });

export const showChatWidget = () => isLoaded() && Intercom('show');

export const updateChatWidgetOptions = (options: IntercomOptions) =>
  isLoaded() && Intercom('update', options);
