import { omit } from 'lodash';

import { generatePipelineId, isPipelineWithParent } from '@/core/pipeline';

import {
  Exploration,
  DereferencedPipeline,
  DereferencedPipelineOperation,
  Pipeline,
  PipelineOperation,
  FunnelOperation,
  DereferencedFunnelOperation,
  Fields,
  BasePipeline,
  Cell,
  Field,
} from '../types';
import { getCellByPipelineId, getPipelineById } from '../exploration/utils';
import { getDereferencedStepPipeline, isEmptyStep } from '../edit-funnel/utils';
import { InvalidPipelineReferenceError } from '../utils';

export { generatePipelineId } from '@/core/pipeline';
export * from './format';

export interface FieldGroup {
  name?: string;
  key?: string;
  fields: Fields;
}

export const isField = (fieldOrGroup: Field | FieldGroup): fieldOrGroup is Field =>
  !isFieldGroup(fieldOrGroup) && 'type' in fieldOrGroup;

export const isFieldGroup = (fieldOrGroup: Field | FieldGroup): fieldOrGroup is FieldGroup =>
  'fields' in fieldOrGroup && 'name' in fieldOrGroup;

export const getPipelineId = (pipeline: Pipeline) => {
  if (pipeline.pipelineId === undefined) {
    throw new Error(`Pipeline does not have pipelineId`);
  }
  return pipeline.pipelineId;
};

export const getOrGeneratePipelineId = (pipeline: Pipeline) =>
  pipeline.pipelineId !== undefined ? pipeline.pipelineId : generatePipelineId();

export const getBaseModelId = (pipeline: Pipeline, exploration: Exploration) => {
  const flattenedPipeline = flattenPipeline(pipeline, exploration);
  return flattenedPipeline.baseModelId;
};

export function flattenPipeline(pipeline: Pipeline, exploration: Exploration): BasePipeline {
  if (isPipelineWithParent(pipeline)) {
    const parentPipeline = getPipelineById(pipeline.parentId, exploration);

    return flattenPipeline(
      {
        pipelineId: pipeline.pipelineId,
        operations: [...parentPipeline.operations, ...pipeline.operations],
        ...('parentId' in parentPipeline
          ? {
              parentId: parentPipeline.parentId,
            }
          : {
              baseModelId: parentPipeline.baseModelId,
            }),
      },
      exploration,
    );
  }

  return pipeline;
}

export const ensureOperationOrder = (operations: PipelineOperation[]): PipelineOperation[] => {
  // A hack to avoid "unflattenable pipelines" on a type-level
  const joinPipelineOperationIndex = operations.findIndex(
    (operation) => operation.operation === 'joinPipeline',
  );
  return operations.slice(joinPipelineOperationIndex === -1 ? 0 : joinPipelineOperationIndex);
};

export const canFlattenChildPipelines = (pipeline: Pipeline, exploration: Exploration) => {
  return (
    pipeline.operations.length === 0 ||
    !exploration.view.cells.some(
      (cell) =>
        'pipeline' in cell &&
        'parentId' in cell.pipeline &&
        cell.pipeline.parentId === pipeline.pipelineId &&
        cell.pipeline.operations.some((operation) => operation.operation === 'joinPipeline'),
    )
  );
};

/**
 * Flatten nested parents and convert references to nested pipelines
 */
export function dereferencePipeline(
  pipeline: Pipeline,
  exploration: Exploration,
): DereferencedPipeline {
  const flattenedPipeline = flattenPipeline(pipeline, exploration);

  return {
    ...flattenedPipeline,
    operations: dereferenceOperations(flattenedPipeline.operations, exploration),
  };
}

export const dereferenceFunnelOperation = (
  operation: FunnelOperation,
  exploration: Exploration,
): DereferencedFunnelOperation => ({
  ...operation,
  parameters: {
    ...operation.parameters,
    steps: operation.parameters.steps
      .filter((step) => !isEmptyStep(step))
      .map((step) => {
        const pipeline = getDereferencedStepPipeline(step, exploration);
        return {
          sortKey: step.sortKey ?? null,
          fields: step.fields ?? [],
          modelId: pipeline.baseModelId,
          operations: pipeline.operations,
        };
      }),
  },
});

export const dereferenceOperation = (
  operation: PipelineOperation,
  exploration: Exploration,
): DereferencedPipelineOperation => {
  switch (operation.operation) {
    case 'addRelatedColumn': {
      if (operation.parameters.pipelineId === undefined) {
        return operation;
      }
      const cell = getCellByPipelineId(operation.parameters.pipelineId, exploration);
      if (cell === undefined || !('pipeline' in cell)) {
        throw new InvalidPipelineReferenceError(
          `Could not find pipeline with id ${operation.parameters.pipelineId}`,
        );
      }
      return {
        ...operation,
        parameters: {
          ...omit(operation.parameters, ['pipelineId']),
          pipeline: dereferencePipeline(cell.pipeline, exploration),
        },
      } as DereferencedPipelineOperation;
    }
    case 'relationAggregate': {
      if (operation.parameters.pipelineId !== undefined) {
        const { pipelineId, ...restParameters } = operation.parameters;
        return {
          ...operation,
          parameters: {
            ...restParameters,
            pipeline: dereferencePipeline(getPipelineById(pipelineId, exploration), exploration),
          },
        };
      }

      if ('joinStrategy' in operation.parameters) {
        const { pipelineId, ...restParameters } = operation.parameters;
        return {
          ...operation,
          parameters: {
            ...restParameters,
            pipeline: dereferencePipeline(getPipelineById(pipelineId, exploration), exploration),
          },
        };
      }

      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { pipelineId, ...restParameters } = operation.parameters;
      return {
        ...operation,
        parameters: {
          ...restParameters,
        },
      };
    }
    case 'funnel':
      return dereferenceFunnelOperation(operation, exploration);
    case 'cohort':
      return {
        ...operation,
        parameters: {
          ...operation.parameters,
          pipeline: dereferencePipeline(operation.parameters.pipeline, exploration),
        },
      };
    case 'joinPipeline':
      return {
        ...operation,
        parameters: {
          ...operation.parameters,
          pipeline: dereferencePipeline(operation.parameters.pipeline, exploration),
        },
      };
    default:
      return operation;
  }
};

export const dereferenceOperations = (
  operations: PipelineOperation[],
  exploration: Exploration,
): DereferencedPipelineOperation[] => {
  return operations.map((operation) => dereferenceOperation(operation, exploration));
};

export const getParentPipelineCellIndex = (exploration: Exploration, pipeline: Pipeline) => {
  if (!isPipelineWithParent(pipeline)) {
    return -1;
  }

  return exploration.view.cells.findIndex(
    (cell) =>
      'pipeline' in cell &&
      'pipelineId' in cell.pipeline &&
      cell.pipeline.pipelineId === pipeline.parentId,
  );
};

export const getParentPipelineTitle = (exploration: Exploration, pipeline: Pipeline) => {
  if (!isPipelineWithParent(pipeline)) {
    return;
  }

  const cell = exploration.view.cells.find(
    (cell) =>
      'pipeline' in cell &&
      'pipelineId' in cell.pipeline &&
      cell.pipeline.pipelineId === pipeline.parentId &&
      'title' in cell,
  );

  return cell && 'title' in cell ? cell.title : undefined;
};

const isChildPipeline = (pipeline: Pipeline, parentId: string | undefined) =>
  isPipelineWithParent(pipeline) && pipeline.parentId === parentId;

const isJoinedPipeline = (pipeline: Pipeline, parentId: string | undefined) =>
  pipeline.operations.some(
    (operation) =>
      operation.operation === 'joinPipeline' &&
      'parentId' in operation.parameters.pipeline &&
      operation.parameters.pipeline.parentId === parentId,
  );

const isRelationAggregateModel = (pipeline: Pipeline, parentId: string | undefined) =>
  pipeline.operations.some(
    (operation) =>
      operation.operation === 'relationAggregate' &&
      'pipelineId' in operation.parameters &&
      operation.parameters.pipelineId === parentId,
  );

const isAddRelatedColumnModel = (pipeline: Pipeline, parentId: string | undefined) =>
  pipeline.operations.some(
    (operation) =>
      operation.operation === 'addRelatedColumn' &&
      'pipelineId' in operation.parameters &&
      operation.parameters.pipelineId === parentId,
  );

const isFunnelStep = (pipeline: Pipeline, parentId: string | undefined) =>
  pipeline.operations.some(
    (operation) =>
      operation.operation === 'funnel' &&
      operation.parameters.steps.some(
        (step) => 'parentId' in step.pipeline && step.pipeline.parentId === parentId,
      ),
  );

const isRetentionModel = (pipeline: Pipeline, parentId: string | undefined) =>
  pipeline.operations.some(
    (operation) =>
      operation.operation === 'cohort' &&
      'parentId' in operation.parameters.pipeline &&
      operation.parameters.pipeline.parentId === parentId,
  );

export const getChildPipelineCells = (exploration: Exploration, pipeline: Pipeline): Cell[] => {
  if (pipeline.pipelineId === undefined) {
    return [];
  }

  return exploration.view.cells.filter(
    (cell) =>
      'pipeline' in cell &&
      (isChildPipeline(cell.pipeline, pipeline.pipelineId) ||
        isJoinedPipeline(cell.pipeline, pipeline.pipelineId) ||
        isRelationAggregateModel(cell.pipeline, pipeline.pipelineId) ||
        isAddRelatedColumnModel(cell.pipeline, pipeline.pipelineId) ||
        isFunnelStep(cell.pipeline, pipeline.pipelineId) ||
        isRetentionModel(cell.pipeline, pipeline.pipelineId)),
  );
};

export const getNumberOfChildPipelines = (exploration: Exploration, pipeline: Pipeline): number => {
  return getChildPipelineCells(exploration, pipeline).length;
};

export const countOperations = (exploration: Exploration) => {
  return exploration.view.cells.reduce((count, cell) => {
    if ('pipeline' in cell) {
      return count + cell.pipeline.operations.length;
    }
    return count;
  }, 0);
};

const generateUniqueFieldName = (name: string, fields: Fields, suffix: string, i = 0): string => {
  const newName = `${name} (${suffix})${i > 0 ? ` (${i})` : ''}`;
  if (fields.some((f) => f.name === newName)) {
    return generateUniqueFieldName(name, fields, suffix, i + 1);
  }
  return newName;
};

export const ensureUniqueFieldNames = (
  constantFields: Fields,
  renamableFields: Fields,
  suffix: string,
): Fields => {
  const allFields = constantFields.concat(renamableFields);
  const collisionIndex = renamableFields.findIndex((field, i) => {
    return (
      constantFields.some((f) => f.name.toLocaleLowerCase() === field.name.toLocaleLowerCase()) ||
      renamableFields.some(
        (f, j) => j < i && f.name.toLocaleLowerCase() === field.name.toLocaleLowerCase(),
      )
    );
  });
  if (collisionIndex === -1) {
    return renamableFields;
  }
  const field = renamableFields[collisionIndex];
  const name = generateUniqueFieldName(field.name, allFields, suffix);
  return ensureUniqueFieldNames(
    constantFields,
    [
      ...renamableFields.slice(0, collisionIndex),
      { ...field, name },
      ...renamableFields.slice(collisionIndex + 1),
    ],
    suffix,
  );
};
