import { ScaleLinear, ScaleTime } from 'd3-scale';
import classNames from 'classnames';

import { formatPropertyValue } from '@/explore/utils/format';

import { SeriesMetaData } from '../grouped-chart/types';
import { increaseBrightness } from '../utils';

import styles from './series-values.module.scss';

const ChartEdgePadding = 4;

type SeriesValuesProps = {
  series: SeriesMetaData;
  data: { date: Date; value: number; cumulativeValue?: number }[];
  valueScale: ScaleLinear<number, number>;
  dateScale: ScaleTime<number, number>;
  x?: number;
  y?: number;
  avoidOverflow?: boolean;
  white?: boolean;
};

export const SeriesValues = (props: SeriesValuesProps) => {
  const { data, series, valueScale, dateScale, avoidOverflow = false, white = false } = props;

  return data.map((d, i) => {
    const isFirst = i === 0;
    const isLast = i === data.length - 1;
    const textAnchor =
      avoidOverflow && isFirst ? 'start' : avoidOverflow && isLast ? 'end' : 'middle';
    const dx =
      avoidOverflow && isFirst ? ChartEdgePadding : avoidOverflow && isLast ? -ChartEdgePadding : 0;
    const showValue = d.value !== 0;
    return showValue ? (
      <text
        key={i}
        className={classNames(styles.valueLabel, { [styles.white]: white })}
        x={dateScale(d.date) ?? 0}
        y={valueScale(d.cumulativeValue ?? d.value) ?? 0}
        dx={dx + (props.x ?? 0)}
        dy={-8 + (props.y ?? 0)}
        fill={increaseBrightness(series.color, 20)}
        style={{ textAnchor }}>
        {formatPropertyValue(d.value, {
          type: series.type,
          format: series.format,
          style: 'compact',
        })}
      </text>
    ) : null;
  });
};
