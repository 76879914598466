import { throttle } from 'lodash';
import { useCallback, useEffect, useRef } from 'react';

/**
 * A hook to handle dragging of a handle.
 * The hook will return the delta of the mouse position from the start of the drag.
 */
export const useDragHandle = <T extends HTMLElement>(
  handleRef: React.RefObject<T>,
  onDragStart?: (x: number, y: number) => void,
  onDrag?: (dx: number, dy: number) => void,
  onDragEnd?: (dx: number, dy: number) => void,
) => {
  const dragStartPos = useRef<{ x: number; y: number } | null>(null);

  const getPosDelta = useCallback((e: MouseEvent): [number, number] => {
    return dragStartPos.current === null
      ? [0, 0]
      : [
          dragStartPos.current.x === null ? 0 : e.clientX - dragStartPos.current.x,
          dragStartPos.current.y === null ? 0 : e.clientY - dragStartPos.current.y,
        ];
  }, []);

  const handleMouseMove = throttle(
    useCallback(
      (e: MouseEvent) => {
        if (dragStartPos.current === null) {
          return;
        }
        e.preventDefault();
        onDrag?.(...getPosDelta(e));
      },
      [getPosDelta, onDrag],
    ),
    20,
  );

  const handleMouseUp = useCallback(
    (e: MouseEvent) => {
      if (dragStartPos.current === null) {
        return;
      }
      onDragEnd?.(...getPosDelta(e));
      dragStartPos.current = null;
    },
    [getPosDelta, onDragEnd],
  );

  const handleMouseDown = useCallback(
    (e: MouseEvent) => {
      e.preventDefault();
      dragStartPos.current = { x: e.clientX, y: e.clientY };
      onDragStart?.(e.clientX, e.clientY);
    },
    [onDragStart],
  );

  useEffect(() => {
    const handle = handleRef.current;
    handle?.addEventListener('mousedown', handleMouseDown);
    document.addEventListener('mousemove', handleMouseMove);
    document.addEventListener('mouseup', handleMouseUp);
    return () => {
      handle?.removeEventListener('mousedown', handleMouseDown);
      document.removeEventListener('mousemove', handleMouseMove);
      document.removeEventListener('mouseup', handleMouseUp);
    };
  }, [handleMouseDown, handleMouseMove, handleMouseUp, handleRef]);
};
