import { CompletionSource, snippetCompletion, startCompletion } from '@codemirror/autocomplete';
import { syntaxTree } from '@codemirror/language';
import { functions } from '@gosupersimple/penguino';
import { ViewPlugin, ViewUpdate } from '@codemirror/view';

import { renderDocumentation, renderDescription } from './documentation';
import { getPenguinoState } from './state';
import { PenguinoTokenTree } from './parser';
import {
  getFunctionParameterBounds,
  getOuterFunctionToken,
  getTokenAtPos,
  isStringToken,
} from './traversal';

export const penguinoCompletions: CompletionSource = (context) => {
  const { fields, variables } = getPenguinoState(context.state);
  const tokens = syntaxTree(context.state)
    .children.filter(PenguinoTokenTree.isPenguinoTokenTree)
    .map((item) => item.token);

  const currentToken = getTokenAtPos(tokens, context.pos);
  const outerToken = getOuterFunctionToken(tokens, context.pos);
  const word = context.matchBefore(/\w*/);

  if (outerToken?.lexeme === 'prop') {
    const { from, to } = getFunctionParameterBounds(tokens, context.pos);

    return {
      filter: !context.explicit,
      from,
      to,
      options: fields.map((field) =>
        snippetCompletion(`"${field.name}"#{1}`, {
          label: `"${field.name}"`,
          displayLabel: field.name,
          type: `property.${field.type}`,
        }),
      ),
    };
  }

  if (outerToken?.lexeme === 'variable') {
    const { from, to } = getFunctionParameterBounds(tokens, context.pos);

    return {
      filter: !context.explicit,
      from,
      to,
      options: variables.map((variable) =>
        snippetCompletion(`"${variable.key}"#{1}`, {
          label: `"${variable.key}"`,
          displayLabel: variable.key,
          type: `variable.${variable.type}`,
        }),
      ),
    };
  }

  if (currentToken !== null && isStringToken(currentToken)) {
    return null;
  }

  // For exact function or variable name matches, we want to rank them higher than properties.
  const hasExactFunction = Object.entries(functions).some(([name]) => name === word?.text);
  const hasExactVariable = variables.some(({ key }) => key === word?.text);

  return {
    from: word?.from ?? 0,
    to: word?.to ?? 0,
    options: Object.entries(functions)
      .map(([, f]) =>
        snippetCompletion(
          f.name === 'prop' || f.name === 'variable' ? `${f.name}("#{1}")` : `${f.name}(#{1})`,
          {
            label: f.name,
            type: 'function',
            info: () => renderDocumentation(f),
            section: { name: 'Functions', rank: hasExactFunction ? -1 : 1 },
          },
        ),
      )
      .concat(
        fields.map((field) =>
          snippetCompletion(`prop("${field.name}")#{1}`, {
            label: field.name,
            type: `property.${field.type}`,
            info: () => renderDescription(field),
            section: { name: 'Properties', rank: 0 },
          }),
        ),
      )
      .concat(
        variables.map((variable) =>
          snippetCompletion(`variable("${variable.key}")#{1}`, {
            label: variable.key,
            type: `variable.${variable.type}`,
            section: { name: 'Variables', rank: hasExactVariable ? -1 : 2 },
          }),
        ),
      ),
  };
};

// Trigger completion in addition to the default behavior in the following cases:
//
// - When the cursor is after "(" or before ")".
// - When the cursor is at the end of the document.
//
// Feel free to introduce additional scenarios where autocomplete would be helpful.
export const penguinoCompletionTrigger = ViewPlugin.define((view) => ({
  update({ state, docChanged, selectionSet }: ViewUpdate) {
    if (!view.hasFocus) {
      return;
    }
    if (docChanged || selectionSet) {
      const selection = state.selection.main;
      const line = state.doc.lineAt(selection.from);
      const prefix = line.text.slice(0, selection.from - line.from);
      const suffix = line.text.slice(selection.from - line.from);

      if (prefix.endsWith('(') || suffix.startsWith(')') || selection.from === state.doc.length) {
        setTimeout(() => {
          startCompletion(view);
        }, 10);
      }
    }
  },
}));
