import { ReactNode, useState } from 'react';

import { Modal } from '../modal';
import { Panel } from '../panel';
import { Button } from '../button';

import styles from './confirmation-modal.module.scss';

interface ConfirmationModalProps {
  title: string;
  description?: ReactNode;
  submitLabel?: string;
  cancelLabel?: string;
  onSubmit: () => Promise<void>;
  onClose: () => void;
  loading?: boolean;
}

export const ConfirmationModal = ({
  title,
  description,
  submitLabel = 'Confirm',
  cancelLabel = 'Cancel',
  onSubmit,
  onClose,
}: ConfirmationModalProps) => {
  const [loading, setLoading] = useState(false);
  const handleSubmit = async () => {
    setLoading(true);
    await onSubmit();
    setLoading(false);
  };

  return (
    <Modal onClose={onClose}>
      <Panel className={styles.panel}>
        <div className={styles.header}>
          <h1>{title}</h1>
        </div>

        {description !== undefined && <div className={styles.body}>{description}</div>}
        <div>
          <Button size="large" onClick={handleSubmit} loading={loading}>
            {submitLabel}
          </Button>
          <Button variant="secondary" size="large" onClick={onClose}>
            {cancelLabel}
          </Button>
        </div>
      </Panel>
    </Modal>
  );
};
