import { Fragment, useEffect, useRef, useState } from 'react';
import classNames from 'classnames';
import { Link } from 'react-router-dom';

import { useExplorationGenerationQuery } from '@/graphql';
import { useBuildAccountUrl, useSelectedAccount } from '@/lib/accounts/context';
import { useTrackEvent } from '@/lib/analytics';
import { notNil } from '@/lib/utils';

import { isBasePipeline } from '@/core/pipeline';

import { Icon } from '../../components/icon';
import { Loader } from '../../components/loader';
import { Penguin } from '../../components/penguin';
import { PipelinePreview } from '../exploration/pipeline-preview';
import { Exploration, Model } from '../types';
import { buildExplorationHashUrl, setExplorationType } from '../utils';
import { convertExplorationTypes } from '../input';
import { exportExploration } from '../output';
import { getModelOrThrow } from '../model/utils';

import styles from './exploration-search.module.scss';

interface AISuggestionsProps {
  searchTerm: string;
  models: Model[];
  exploration?: Exploration;
  onClickSuggestion?: (exploration: Exploration) => void;
}

export const AISuggestions = (props: AISuggestionsProps) => {
  const { searchTerm, models, exploration: currentExploration, onClickSuggestion } = props;
  const account = useSelectedAccount();
  const buildAccountUrl = useBuildAccountUrl();
  const trackEvent = useTrackEvent();

  const searchStartTime = useRef<number>(Date.now());
  const correlationId = useRef<string | null>(null);
  const lastSearchTerm = useRef<string>('');

  const [exploration, setExploration] = useState<Exploration | null>(null);
  const [explorationUrl, setExplorationUrl] = useState<string | null>(null);

  if (searchTerm !== lastSearchTerm.current) {
    correlationId.current = crypto.randomUUID();
    lastSearchTerm.current = searchTerm;
    searchStartTime.current = Date.now();
  }

  useEffect(() => {
    if (searchTerm && correlationId.current !== null) {
      trackEvent('Exploration Search Generation Started', {
        searchTerm,
        correlationId: correlationId.current,
        hasCurrentExploration: notNil(currentExploration),
      });
    }
  }, [searchTerm, currentExploration, trackEvent]);

  const { loading } = useExplorationGenerationQuery({
    variables: {
      accountId: account.accountId,
      search: searchTerm,
      correlationId: correlationId.current,
      exploration: currentExploration ? exportExploration(currentExploration) : undefined,
    },
    skip: !searchTerm,
    onCompleted: (data) => {
      const generatedExploration =
        convertExplorationTypes(data?.account?.generateExploration?.exploration) ?? null;

      const newExploration = generatedExploration
        ? setExplorationType(generatedExploration, 'ai')
        : null;

      setExploration(newExploration);

      const resultUrl = newExploration
        ? buildAccountUrl(buildExplorationHashUrl(newExploration))
        : null;
      setExplorationUrl(resultUrl);

      trackEvent(
        newExploration
          ? 'Exploration Search Generated Exploration'
          : 'Exploration Search Generated Exploration Failed',
        {
          searchTerm,
          correlationId: correlationId.current,
          timeSpent: (Date.now() - searchStartTime.current) / 1000,
          hasCurrentExploration: notNil(currentExploration),
          resultUrl,
        },
      );
    },
    onError: (error) => {
      trackEvent('Exploration Search Generated Exploration Failed', {
        searchTerm,
        correlationId: correlationId.current,
        timeSpent: (Date.now() - searchStartTime.current) / 1000,
        error: error.message,
      });
    },
  });

  if (loading) {
    return (
      <div className={classNames(styles.aiSuggestions, styles.aiLoading)}>
        <Loader size="medium" type="spinner" />
        AI looking for more solutions
      </div>
    );
  }

  if (!exploration) {
    return <div className={classNames(styles.aiSuggestions, styles.aiHidden)} />;
  }

  // For displaying new ai-generated blocks in the context of the current exploration
  const mergedExploration =
    currentExploration === undefined
      ? exploration
      : {
          ...currentExploration,
          view: {
            ...currentExploration.view,
            cells: [...currentExploration.view.cells, ...exploration.view.cells],
          },
        };

  return (
    <div className={styles.aiSuggestions}>
      <Penguin className={styles.penguin} />
      <h2>AI-generated exploration</h2>
      <Link
        to={explorationUrl ?? ''}
        className={styles.exploration}
        onClick={(e) => {
          trackEvent('Exploration Search Clicked AI Suggestion', {
            correlationId: correlationId.current,
          });
          if (onClickSuggestion === undefined) {
            return;
          }
          e.preventDefault();
          return onClickSuggestion(exploration);
        }}>
        {exploration !== null ? (
          <div className={styles.blocks}>
            {exploration.view.cells.map((cell, i) => {
              const pipeline =
                'pipeline' in cell && isBasePipeline(cell.pipeline) ? cell.pipeline : null;
              const title =
                'title' in cell && cell.title !== null
                  ? cell.title
                  : pipeline !== null
                    ? getModelOrThrow(models, pipeline.baseModelId)?.name
                    : 'Unknown Pipeline';
              const iconType =
                cell.kind === 'text'
                  ? 'AlignLeft'
                  : cell.kind === 'funnel'
                    ? 'Funnel'
                    : cell.kind === 'cohort'
                      ? 'Cohort'
                      : 'Block';
              return (
                <Fragment key={i}>
                  <div className={styles.block}>
                    <Icon name={iconType} size={16} className={styles.icon} />
                    {title}
                    {cell.kind === 'text' && title === '' && cell.content}
                  </div>
                  {exploration.view.cells.length === 1 && pipeline !== null && (
                    <PipelinePreview
                      title={getModelOrThrow(models, pipeline.baseModelId)?.name}
                      pipeline={pipeline}
                      exploration={mergedExploration}
                      models={models}
                    />
                  )}
                </Fragment>
              );
            })}
          </div>
        ) : null}
        <Icon name="ChevronRight" size={24} />
      </Link>
    </div>
  );
};
