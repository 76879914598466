import { useEffect, useMemo, useState } from 'react';
import classNames from 'classnames';
import { first, isNil, last } from 'lodash';

import { useExplorationsSearchQuery } from '@/graphql';
import { useSelectedAccount } from '@/lib/accounts/context';
import { useTrackEvent } from '@/lib/analytics';
import { useIsScrolled } from '@/lib/hooks/use-is-scrolled';
import { ErrorBoundary } from '@/lib/error';
import { useNavigableList } from '@/lib/hooks/use-navigable-list';
import { isReplicableCell } from '@/core/cell';

import { Icon } from '../../components/icon';
import { Loader } from '../../components/loader';
import { Cell, Exploration, Metric, Model } from '../types';
import { AISuggestions } from './ai-suggestions';
import { ExplorationList } from './exploration-list';
import { convertExplorationsArrayTypes } from '../input';
import { isModel } from './utils';
import { ListItemData } from '.';
import {
  ensureValidExploration,
  getCellBaseModel,
  getExplorationType,
  getExplorationVariables,
  getUnparameterizedExplorations,
} from '../utils';

import { buildExplorationFromCells, extractCellFromExploration } from '../exploration/utils';

import styles from './exploration-search.module.scss';

interface SearchResultProps {
  explorations: Exploration[];
  models: Model[];
  metrics: Metric[];
  searchTerm: string;
  exploration?: Exploration;
  /**
   * When set to true, the search results will allow selecting individual cells, full exploration otherwise.
   */
  selectIndividualCells: boolean;
  onClickExploration?: (exploration: Exploration) => void;
  setFocusIndexRef?: React.MutableRefObject<((index: number) => void) | undefined>;
  onBlur?: () => void;
}

export const SearchResults = (props: SearchResultProps) => {
  const { explorations, models, metrics, searchTerm, exploration, onClickExploration } = props;

  const trackEvent = useTrackEvent();
  const [isScrolled, scrollContainerRef] = useIsScrolled<HTMLDivElement>();
  const account = useSelectedAccount();

  // Avoid fetching on first letter by using an empty initial value
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState('');

  const {
    data,
    loading: queryLoading,
    error,
  } = useExplorationsSearchQuery({
    variables: { accountId: account.accountId, search: debouncedSearchTerm },
    skip: debouncedSearchTerm.length === 0,
  });

  const loading = queryLoading || debouncedSearchTerm !== searchTerm;

  const instantResults = useMemo(() => {
    const filteredExplorations = explorations.filter((exploration) =>
      exploration.name.toLocaleLowerCase().includes(searchTerm.toLocaleLowerCase()),
    );

    return filteredExplorations;
  }, [explorations, searchTerm]);

  const semanticResults = getUnparameterizedExplorations(
    convertExplorationsArrayTypes(data?.account?.explorations ?? []),
  )
    .map((exploration) => {
      const variables = getExplorationVariables(exploration);
      return ensureValidExploration(exploration, models, metrics, variables);
    })
    .filter(
      (e) =>
        !instantResults.some(
          (e2) =>
            e.explorationId === e2.explorationId ||
            (getExplorationType(e) === 'model' &&
              getExplorationType(e2) === 'model' &&
              getCellBaseModel(first(e.view.cells), e) ===
                getCellBaseModel(first(e2.view.cells), e2)),
        ),
    );

  const onClickCell = (event: React.MouseEvent, { id }: Cell, exploration: Exploration) => {
    if (onClickExploration !== undefined) {
      event.preventDefault();
      const { cell, dependencies } = extractCellFromExploration(id, exploration);
      onClickExploration(buildExplorationFromCells([cell, ...dependencies]));
    }
  };

  const buildItem = (exploration: Exploration) => ({
    isFocusable: true,
    data: { exploration },
    collapseOnClick: props.selectIndividualCells,
    children: exploration.view.cells
      .filter(() => props.selectIndividualCells)
      .filter(isReplicableCell)
      .map((cell) => ({
        isFocusable: true,
        data: { cell },
        onClick: (event: React.MouseEvent) => onClickCell(event, cell, exploration),
      })),
    onClick: (event: React.MouseEvent) => {
      if (onClickExploration !== undefined) {
        event.preventDefault();
        onClickExploration(exploration);
      }
    },
  });

  const { list: resultList, setFocusIndex } = useNavigableList<ListItemData>({
    items: [
      instantResults.length === 0 ? null : { children: instantResults.map(buildItem) },
      semanticResults.length === 0 ? null : { children: semanticResults.map(buildItem) },
    ],
    listContainerRef: scrollContainerRef,
    onFocusMovedOutside: props.onBlur,
  });

  useEffect(() => {
    // Allow parent component to interact with list focus
    if (props.setFocusIndexRef === undefined) {
      return;
    }
    props.setFocusIndexRef.current = setFocusIndex;
    return () => {
      props.setFocusIndexRef!.current = undefined;
    };
  }, [props.setFocusIndexRef, setFocusIndex]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setDebouncedSearchTerm(searchTerm);
      const modelCount = instantResults.filter((e) => isModel(e.labels)).length;
      trackEvent('Exploration Search', {
        searchTerm: searchTerm,
        instantCount: instantResults.length,
        modelCount,
        explorationCount: instantResults.length - modelCount,
      });
    }, 500);
    return () => clearTimeout(timeout);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchTerm]);

  useEffect(() => {
    if (loading || data === undefined) {
      return;
    }
    const modelCount = semanticResults.filter((e) => isModel(e.labels)).length;
    trackEvent('Exploration Search Results', {
      searchTerm: debouncedSearchTerm,
      instantCount: instantResults.length,
      resultCount: semanticResults.length,
      modelCount,
      explorationCount: semanticResults.length - modelCount,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data?.account?.explorations]);

  const instantResultsListItem = first(resultList);
  const semanticResultsListItem = last(resultList);

  return (
    <>
      <div
        className={classNames(styles.searchResults, { [styles.scrolled]: isScrolled })}
        ref={scrollContainerRef}>
        {!isNil(instantResultsListItem) && (
          <ExplorationList listItems={instantResultsListItem.getChildren()} />
        )}
        {loading && (
          <div className={styles.loader}>
            <Loader />
          </div>
        )}
        {!loading && !isNil(semanticResultsListItem) && (
          <ExplorationList listItems={semanticResultsListItem.getChildren()} />
        )}
        {!loading && error && (
          <div className={styles.error}>
            <Icon name="CloudDrizzle" size={16} />
            Semantic search failed. Only exact matches are displayed.
          </div>
        )}
      </div>
      <ErrorBoundary>
        <AISuggestions
          models={models}
          searchTerm={debouncedSearchTerm}
          exploration={exploration}
          onClickSuggestion={onClickExploration}
        />
      </ErrorBoundary>
    </>
  );
};
