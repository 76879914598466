import { MouseEventHandler } from 'react';
import classNames from 'classnames';

import { Icon, IconProps } from '../../components/icon';

import styles from './pipeline.module.scss';

interface PipelineHeaderProps {
  icon?: IconProps['name'];
  title: string;
  htmlTitle?: string;
  description: React.ReactNode;
  className?: classNames.Argument;
  onClick?: MouseEventHandler;
  children?: React.ReactNode;
}

export const PipelineHeader = (props: PipelineHeaderProps) => (
  <div
    className={classNames(
      styles.pipelineHeader,
      { [styles.clickable]: Boolean(props.onClick) },
      props.className,
    )}
    title={props.htmlTitle}
    onClick={props.onClick}>
    <div className={styles.operationIcon}>
      <Icon name={props.icon ?? 'Model'} size={16} />
    </div>

    <div className={styles.operationTitle}>{props.title}</div>

    <div className={styles.operationDescription}>{props.description}</div>

    <div className={styles.operationAccessory}>{props.children}</div>
  </div>
);
