import classNames from 'classnames';

import styles from './loader.module.scss';

interface LoaderSharedProps {
  size?: 'small' | 'medium' | 'large';
  fullSize?: boolean;
  className?: string;
}

interface LoaderProps extends LoaderSharedProps {
  type?: 'default' | 'spinner' | 'spinner-dark';
}

export const Loader = ({ type, ...props }: LoaderProps) => {
  switch (type) {
    case 'spinner':
      return <SpinnerLoader {...props} />;
    case 'spinner-dark':
      return (
        <SpinnerLoader {...props} className={classNames(props.className, styles.spinnerDark)} />
      );
    default:
      return <BarsLoader {...props} />;
  }
};

const BarsLoader = (props: LoaderSharedProps) => (
  <div
    className={classNames(
      {
        [styles.fullSize]: props.fullSize,
      },
      props.className,
    )}>
    <div
      className={classNames(styles.barsLoader, {
        [styles.large]: props.size === 'large',
        [styles.medium]: props.size === 'medium',
        [styles.small]: props.size === undefined || props.size === 'small',
      })}>
      <span />
      <span />
      <span />
    </div>
  </div>
);

const SpinnerLoader = (props: LoaderSharedProps) => (
  <div
    className={classNames(styles.spinnerLoader, props.className, {
      [styles.large]: props.size === 'large',
      [styles.medium]: props.size === 'medium',
      [styles.small]: props.size === undefined || props.size === 'small',
    })}
  />
);

export const FullPageLoader = ({ size, ...props }: LoaderProps) => (
  <div className={styles.fullPage}>
    <Loader {...props} size={size ?? 'large'} />
  </div>
);
