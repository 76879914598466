import { first } from 'lodash';

import { Field } from '@/explore/types';
import { flattenNestedList } from '@/explore/grouping';
import { formatNumber } from '@/explore/utils/format';

/**
 * Convert records to CSV
 * @param records Records to convert
 * @param fields Fields of the records
 * @param isTruncated Whether the records are truncated
 * @returns CSV string
 */
export const convertRecordsToCsv = (
  records: Record<string, any>[],
  fields: Field[],
  isTruncated = false,
) => {
  records = flattenNestedList(records);

  const firstRecord = first(records);
  if (firstRecord === undefined) {
    return '';
  }

  const headerLine = Object.keys(firstRecord)
    .map((key) => {
      const field = fields.find((field) => field.key === key);
      return `"${field?.name}"`;
    })
    .join(',');

  const valueLines = records.map((record) =>
    Object.entries(record)
      .map(([key, value]) => {
        const field = fields.find((field) => field.key === key);
        switch (field?.type) {
          case 'Object':
          case 'Array':
            return `"${JSON.stringify(value).replace(/"/g, '""')}"`;
          case 'Date':
          case 'Enum':
          case 'String':
            return `"${value}"`;
          default:
            return value;
        }
      })
      .join(','),
  );

  return [
    ...(isTruncated
      ? [
          `"⚠️ Warning: This result set contains the first ${formatNumber(records.length)} records."`,
        ]
      : []),
    headerLine,
    ...valueLines,
  ].join('\n');
};
