import { createRoot } from 'react-dom/client';
import { flushSync } from 'react-dom';
import { Completion } from '@codemirror/autocomplete';
import {
  FunctionDefinition,
  FunctionSections,
  getFunctionDefinitionByName,
} from '@gosupersimple/penguino';

import { Icon } from '@/components/icon';
import { getIconForFieldType } from '@/explore/model/utils';

export const completionIcon = (completion: Completion) => {
  const { type, label } = completion;

  const element = document.createElement('div');

  if (type === undefined) {
    return element;
  }

  flushSync(() => createRoot(element).render(<CompletionIcon type={type} name={label} />));

  return element;
};

const getIconForFunction = (definition: FunctionDefinition) => {
  switch (definition.documentation.section) {
    case FunctionSections.LogicFunctions:
      return 'CheckSquare';
    case FunctionSections.ControlFlow:
      return 'Code';
    case FunctionSections.MathematicalFunctions:
      return 'Summarise';
    case FunctionSections.StringFunctions:
      return 'AlignLeft';
    case FunctionSections.DateFunctions:
      return 'Calendar';
    case FunctionSections.DateUtilityFunctions:
      return 'Clock';
    case FunctionSections.JSONFunctions:
      return 'Box';
    case FunctionSections.TypeConversions:
      return 'Hash';
    case FunctionSections.WindowFunctions:
      return 'Zap';
    case FunctionSections.UtilityFunctions:
      return 'Tool';
    default:
      return 'Box';
  }
};

interface CompletionIconProps {
  type: string;
  name: string;
}

export const CompletionIcon = (props: CompletionIconProps) => {
  if (props.type.startsWith('property.')) {
    return <Icon name={getIconForFieldType(props.type.replace('property.', ''))} size={16} />;
  }

  if (props.type.startsWith('variable.')) {
    return <Icon name={getIconForFieldType(props.type.replace('variable.', ''))} size={16} />;
  }

  if (props.type === 'function') {
    const functionDefinition = getFunctionDefinitionByName(props.name);
    if (functionDefinition !== undefined) {
      return <Icon name={getIconForFunction(functionDefinition)} size={16} />;
    }
  }
  return <Icon name="Box" size={16} />;
};
