import { useState } from 'react';
import { BarStack as VisxBarStack, BarRounded } from '@visx/shape';
import { ScaleLinear, ScaleTime } from 'd3-scale';

import { getRandomString } from '@/lib/utils/string';

import { TimeSeriesData } from '../grouped-chart/types';
import { generateStackedChartDataByKeys, mergeStackedSeries } from './utils';

type BarStackProps = {
  data: TimeSeriesData;
  keys: string[];
  valueScale: ScaleLinear<number, number>;
  dateScale: ScaleTime<number, number>;
  offset: number;
  barWidth: number;
};

export const BarStack = (props: BarStackProps) => {
  const [clipPathId] = useState(`clip-path-${getRandomString(6)}`);
  return (
    <>
      <defs>
        <clipPath id={clipPathId}>
          <BarStackInner
            {...props}
            data={mergeStackedSeries(props.data, props.keys, 'total')}
            keys={['total']}
          />
        </clipPath>
      </defs>
      <BarStackInner {...props} clipPathId={clipPathId} />
    </>
  );
};

type BarStackInnerProps = BarStackProps & {
  clipPathId?: string;
};

export const BarStackInner = (props: BarStackInnerProps) => {
  const { data, keys, dateScale, valueScale, clipPathId, barWidth } = props;
  const stackedData = generateStackedChartDataByKeys(props.data, props.keys);
  return (
    <VisxBarStack
      keys={keys}
      data={stackedData.items}
      x={(d) => d.date}
      xScale={dateScale}
      yScale={valueScale}
      color={(bar) => data.series.find(({ key }) => key === bar)?.color ?? ''}>
      {(barStacks) =>
        barStacks.map((barStack, barStackIdx) =>
          barStack.bars.map((bar) => (
            <BarRounded
              radius={5}
              key={`barstack-${barStack.index}-${bar.index}`}
              x={dateScale(bar.bar.data.date) - barWidth / 2}
              y={bar.y}
              width={barWidth}
              height={bar.height}
              fill={bar.color}
              top={barStackIdx === barStacks.length - 1}
              clipPath={`url(#${clipPathId})`}
            />
          )),
        )
      }
    </VisxBarStack>
  );
};
