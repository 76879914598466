import { common } from '@gosupersimple/types';

import {
  setVisualisationAggregations,
  visualisationKeysFromAggregation,
} from '@/explore/components/visualisation/utils';
import { AggregationsEditor } from '@/explore/edit-pipeline/aggregations-editor';
import { GroupingsEditor } from '@/explore/edit-pipeline/groupings-editor';
import { getFinalStateOrThrow } from '@/explore/pipeline/state';
import {
  AggregatedVisualisation,
  Aggregation,
  DereferencedPipeline,
  Metric,
  Model,
  QueryVariables,
} from '@/explore/types';
import { metricsByModelId } from '@/explore/utils/metrics';
import { DropdownMenuItem } from '@/components/dropdown';

import formStyles from '../../../components/form/form.module.scss';

interface EditAggregatedVisualisationProps {
  visualisation: AggregatedVisualisation;
  pipeline: DereferencedPipeline;
  models: Model[];
  metrics: Metric[];
  variables: QueryVariables;
  getSeriesMenuOptions: (seriesKey: string) => DropdownMenuItem[];
  onChange: (visualisation: AggregatedVisualisation) => void;
}

export const EditAggregatedVisualisation = (props: EditAggregatedVisualisationProps) => {
  const { visualisation, pipeline, models, metrics, variables, onChange } = props;

  const pipelineState = getFinalStateOrThrow(pipeline.baseModelId, pipeline.operations, {
    models,
    variables,
    metrics,
  });
  const availableMetrics = metricsByModelId(metrics, pipelineState.model.modelId);

  const handleAggregationsChange = (aggregations: Aggregation[]) => {
    const existingKeys = pipelineState.fields.map((f) => f.key);
    onChange(setVisualisationAggregations(visualisation, aggregations, existingKeys));
  };

  const handleGroupingsChange = (groups: common.Grouping[]) => {
    const aggregation = {
      ...visualisation.aggregation,
      groups: groups,
    };
    onChange({
      ...visualisation,
      aggregation,
      ...visualisationKeysFromAggregation(aggregation),
    });
  };

  return (
    <div className={formStyles.formHorizontal}>
      <>
        <AggregationsEditor
          aggregations={visualisation.aggregation.aggregations}
          setAggregations={handleAggregationsChange}
          fields={pipelineState.fields}
          metrics={availableMetrics}
          autoFocus
          excludeAggregationTypes={['first', 'last', 'earliest', 'latest']}
          getMenuOptions={(aggregation) => props.getSeriesMenuOptions(aggregation.property.key)}
        />
        <hr className={formStyles.dashed} />
        <GroupingsEditor
          fields={pipelineState.fields}
          autoFocus
          groups={visualisation.aggregation.groups}
          setGroups={handleGroupingsChange}
          preferDateGrouping={visualisation.aggregation.groups.length === 0}
        />
      </>
    </div>
  );
};
