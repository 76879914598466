import { model } from '@gosupersimple/types';
import { version as pyodide_version } from 'pyodide';

export const starterCode = `
# This code runs in your browser through Pyodide ${pyodide_version}.
# The last line of the cell is displayed as the output of the cell.
# Read more about included packages here: https://pyodide.org/en/stable/usage/packages-in-pyodide.html


# Couple of examples:
# 1. Load data from Supersimple
from supersimple import getBlock

df = (
    await getBlock("User") # data model name or exploration title
    .fetchAll(page_size=100, limit=250, columns=['User ID', 'Account ID', 'Role', 'Email', 'Created At'])  # all arguments are optional
    # .as_json()
    .as_df()  # to fetch as pandas dataframe with correct column types
)
df


# 2. Create custom plots through pandas
import matplotlib.pyplot as plt

df.Role.hist(figsize=(8, 3), xrot=90)


# 3. Create custom plots with matplotlib
import matplotlib.pyplot as plt

plt.figure(figsize=(8, 3))
plt.title('Lines')
plt.plot([1, 2, 3], label="line 1")
plt.plot([3, 2, 1], label="line 2")
plt.legend()
plt


# 4. Install new packages from pypi (only non-native packages are supported)
!pip install folium


# 5. Display interactive maps
!pip install folium

import folium
import numpy as np

lat = 58.76315484968047
lng = 25.28096507099963

map = folium.Map(
    location=[lat, lng], zoom_start=8, control_scale=True
)

n = 100
for lat, lng in zip(
    np.random.normal(lat, 0.4, n),
    np.random.normal(lng, 0.8, n),
):
    folium.CircleMarker(
        location=[lat, lng], radius=2, weight=5, tooltip=f"Point lat: {lat} lng: {lng}"
    ).add_to(map)
map

# 6. Display interactive plots with Plotly
!pip install plotly

import plotly.graph_objects as go

fig = go.Figure(data=[go.Sankey(
    node = dict(
      pad = 15,
      thickness = 20,
      line = dict(color = "black", width = 0.5),
      label = ["A1", "A2", "B1", "B2", "C1", "C2"],
      color = "blue"
    ),
    link = dict(
      source = [0, 1, 0, 2, 3, 3], # indices correspond to labels, eg A1, A2, A1, B1, ...
      target = [2, 3, 3, 4, 4, 5],
      value = [8, 4, 2, 8, 4, 2]
  ))])

fig.update_layout(title_text="Basic Sankey Diagram", font_size=10)
fig
`;

// in python pandas everything is an object by default
export const defaultPythonType = 'object' as const;
export const tsToPytonTypeMap: { [key in model.PropertyType]: string } = {
  Integer: 'Int64',
  Float: 'Float64',
  Number: 'Float64',
  String: 'object',
  Enum: 'object',
  Array: 'object',
  Object: 'object',
  Boolean: 'bool',
  Date: 'datetime64[ns]',
  Interval: 'timedelta64[ns]',
};

// TODO: investigate how jupyter and jupyter-lite does this
export const pythonTypeConverter = `
from js import Object
from pyodide.ffi import to_js

def classname(obj):
    cls = type(obj)
    module = cls.__module__
    name = cls.__qualname__
    if module is not None and module != "__builtin__":
        name = module + "." + name
    return name

def pandas_converter(result):
    type_map = {
        "Int64": "Integer",
        "Float64": "Float",
        "object": "String",
        "bool": "Boolean",
        "datetime64[ns]": "Date",
        "timedelta64[ns]": "Interval",
    }
    sup_types = set(type_map.values())

    dtypes_dict = (
        result.dtypes.reset_index()
        .rename(columns={0: "dtype", "index": "key"})
        .assign(
            **{
                "name": lambda df: df["key"],
                "type": lambda df: df["dtype"]
                .apply(lambda x: str(x))
                .map(type_map)
                .apply(lambda x: x if x in sup_types else "String"),
            }
            )
            .drop(columns=["dtype"])
            .to_dict(orient="records")
    )

    return dict(
        type="table",
        raw_py_class=classname(result),
        fields=dtypes_dict,
        rows=result.to_json(orient="records", date_format="iso"),
    )

def html_converter(result):
    return dict(
        type='html',
        raw_py_class=classname(result),
        htmlString=result._repr_html_(),
    )

def plotly_html_converter(result):
    return dict(
        type='html',
        raw_py_class=classname(result),
        htmlString=result.to_html(include_plotlyjs=True, full_html=False),
    )

def plot_converter(result):
    import io, base64

    buf = io.BytesIO()
    plt.savefig(buf, format='png', bbox_inches='tight')
    buf.seek(0)
    plt_base64_string = 'data:image/png;base64,' + base64.b64encode(buf.read()).decode('UTF-8')

    return dict(
        type='image',
        raw_py_class=classname(result),
        base64ImageString=plt_base64_string,
    )

def iterable_converter(result):
    return dict(
        type='primitive',
        raw_py_class=classname(result),
        value=str(result),
    )

def primitive_converter(result):
    return dict(
        type='primitive',
        raw_py_class=classname(result),
        value=str(result),
    )

def convert(obj):
    if obj.__class__.__name__ == 'DataFrame':
        return to_js(pandas_converter(obj), dict_converter=Object.fromEntries)
    elif hasattr(obj, 'to_html') and 'plotly.' in obj.__class__.__module__:
        return to_js(plotly_html_converter(obj), dict_converter=Object.fromEntries)
    elif hasattr(obj, '_repr_html_'):
        return to_js(html_converter(obj), dict_converter=Object.fromEntries)
    elif hasattr(obj, "savefig") or "matplotlib." in str(obj.__class__) or (hasattr(obj, "__name__") and "matplotlib." in obj.__name__) or (isinstance(obj, list) and len(obj) > 0 and "matplotlib." in str(type(obj[0]))):
        return to_js(plot_converter(obj), dict_converter=Object.fromEntries)
    elif hasattr(obj, "__len__"):
        return to_js(iterable_converter(obj), dict_converter=Object.fromEntries)
    else:
        return to_js(primitive_converter(obj), dict_converter=Object.fromEntries)

convert
`;
