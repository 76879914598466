import { useEffect } from 'react';

import { buildExplorationHashUrl, filterVariablesForPipeline } from '@/explore/utils';
import { useToastContext } from '@/components/toast';
import { useExplorationContext } from '@/explore/exploration/exploration-context';

import { useMetadataContext } from '@/explore/metadata-context';
import { dereferencePipeline } from '@/explore/pipeline/utils';
import { getPipelineStateAtIndex, getPipelineStateAtIndexOrThrow } from '@/explore/pipeline/state';

import { useBuildAccountUrl } from '../accounts/context';
import { useKeyPress } from './use-key-press';

/**
 * Get the link to an exploration that is possible to copy
 * from one environment to another.
 */
const useExplorationLink = () => {
  const { exploration } = useExplorationContext();
  const buildAccountUrl = useBuildAccountUrl();
  const toast = useToastContext();

  const cb = (devLink = false) => {
    return (
      (devLink ? 'http://localhost:3000' : 'https://app.supersimple.io') +
      buildAccountUrl(buildExplorationHashUrl(exploration))
    );
  };

  useKeyPress(['c', ['ctrl', 'meta'], ['ctrl', 'alt']], (event) => {
    event.preventDefault();
    const devLink = event.shiftKey;
    const url = cb(devLink);

    navigator.clipboard.writeText(url);

    toast({
      kind: 'success',
      title: devLink ? 'Developer link copied' : 'Exploration copied',
      content: () => 'Exploration copied as link to clipboard',
    });
  });

  return cb;
};

/**
 * Return current exploration
 */
const useGetExploration = () => {
  const { exploration } = useExplorationContext();
  return () => exploration;
};

/**
 * Return current exploration
 */
const useGetParameters = () => {
  const { parameters } = useExplorationContext();
  return () => parameters;
};

/**
 * Dump exploration & parameters to console.
 */
const useLogExploration = () => {
  const { exploration, parameters } = useExplorationContext();

  const cb = () => {
    console.log(`%c exploration:`, 'color: #0b0', exploration);
    if (Object.keys(parameters).length > 0) {
      console.log(`%c parameters:`, 'color: #ca0', parameters);
    }
  };

  useKeyPress(['l', ['ctrl', 'meta'], ['ctrl', 'alt']], (event) => {
    event.preventDefault();
    cb();
  });

  return cb;
};

/**
 * Override the current exploration with anything.
 * Copy & pasting JSON with newlines works.
 */
const useSetExploration = () => {
  const { setExploration } = useExplorationContext();

  const cb = (value?: any) => {
    if (value === undefined) {
      const json = prompt('Enter exploration json');
      if (json === null) {
        return;
      }
      value = JSON.parse(json);
    }
    setExploration(value);
  };

  useKeyPress(['s', ['ctrl', 'meta'], ['ctrl', 'alt']], () => cb());

  return cb;
};

/**
 * Override the parameters.
 * Takes a full json object of all parameters to set.
 */
const useSetParameters = () => {
  const { setParameter } = useExplorationContext();

  const cb = (value?: Record<string, any>) => {
    if (value === undefined) {
      const json = prompt('Enter parameters json');
      if (json === null) {
        return;
      }
      value = JSON.parse(json) as Record<string, any>;
    }
    Object.entries(value).forEach(([k, v]) => {
      setParameter(k, v);
    });
  };

  useKeyPress(['p', ['ctrl', 'meta'], ['ctrl', 'alt']], () => cb());

  return cb;
};

/**
 * Get the pipeline of the currently active cell.
 */
const useGetPipeline = () => {
  const { selectedCell } = useExplorationContext();

  const cb = () => {
    if (selectedCell === null) {
      console.log('No cell selected');
      return;
    }

    if (!('pipeline' in selectedCell)) {
      console.log('Selected cell has no pipeline');
      return;
    }
    return selectedCell.pipeline;
  };

  useKeyPress(['e', ['ctrl', 'meta'], ['ctrl', 'alt']], (event) => {
    event.preventDefault();
    console.log(cb());
  });

  return cb;
};

/**
 * Get the pipeline state of the currently active cell at the given index or final state if no index passed.
 * Pass false as the second argument to suppress errors.
 */
const useGetPipelineState = () => {
  const { models, metrics: metrics } = useMetadataContext();
  const { exploration, selectedCell, getVariables } = useExplorationContext();

  const cb = (index = -1, throwOnErrors = true) => {
    if (selectedCell === null) {
      console.log('No cell selected');
      return;
    }

    if (!('pipeline' in selectedCell)) {
      console.log('Selected cell has no pipeline');
      return;
    }

    const dereferencedPipeline = dereferencePipeline(selectedCell.pipeline, exploration);
    const variables = filterVariablesForPipeline(dereferencedPipeline, getVariables());
    return (throwOnErrors ? getPipelineStateAtIndexOrThrow : getPipelineStateAtIndex)(
      dereferencedPipeline.baseModelId,
      dereferencedPipeline.operations,
      index !== -1 ? index : dereferencedPipeline.operations.length,
      {
        models,
        variables,
        metrics,
      },
    );
  };

  return cb;
};

/**
 * Like inspektor but not really.
 */
export const useKonstaabel = () => {
  const konstaabel = {
    getLink: useExplorationLink(),
    getExploration: useGetExploration(),
    getParameters: useGetParameters(),
    getPipeline: useGetPipeline(),
    getState: useGetPipelineState(),
    setExploration: useSetExploration(),
    setParameters: useSetParameters(),
    logExploration: useLogExploration(),
  };

  const noop = () => console.log('No exploration context');
  const noKonstaabel = {
    getLink: noop,
    getExploration: noop,
    getParameters: noop,
    getPipeline: noop,
    getState: noop,
    setExploration: noop,
    setParameters: noop,
    logExploration: noop,
  };

  useEffect(() => {
    window.sup = konstaabel;
    return () => {
      window.sup = noKonstaabel;
    };
  });
};
