import { useState } from 'react';
import classNames from 'classnames';

import { AccountSettingsDocument, useAddUserToAccountMutation, UserRole } from '@/graphql';
import { sanitizeEmail } from '@/lib/utils/email';

import { useToastContext } from '../../components/toast';
import { Button } from '../../components/button';
import { Input } from '../../components/form/input';
import { Modal } from '../../components/modal';
import { Panel } from '../../components/panel';
import { RoleSelect } from './role-select';
import { mapUserRole } from './utils';

import formStyles from '../../components/form/form.module.scss';
import styles from './user-form.module.scss';

interface CreateFormProps {
  accountId: string;
  onClose: () => void;
}

export const CreateForm = (props: CreateFormProps) => {
  const toast = useToastContext();
  const [email, setEmail] = useState('');
  const [role, setRole] = useState<UserRole>(UserRole.Explorer);

  const [addUser, { loading }] = useAddUserToAccountMutation({
    refetchQueries: [AccountSettingsDocument],
  });

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();

    try {
      await addUser({ variables: { accountId: props.accountId, email, role } });

      toast({
        kind: 'success',
        title: 'User added!',
        content: () => (
          <div>
            User <b>{email}</b> added
          </div>
        ),
      });

      props.onClose();
    } catch {
      toast({
        kind: 'error',
        title: 'Error adding user',
        content: () => (
          <div>
            Please check if the user with email <b>{email}</b> already exists.
          </div>
        ),
      });
    }
  };

  return (
    <Modal onClose={() => props.onClose()}>
      <Panel className={styles.userFormPanel}>
        <h1>Add user</h1>

        <form
          className={classNames(formStyles.formHorizontal, formStyles.sparse)}
          onSubmit={handleSubmit}>
          <div className={classNames(formStyles.formRow, formStyles.rowVertical)}>
            <label className={formStyles.formLabel}>Email</label>
            <Input
              required
              autoFocus
              type="email"
              placeholder="joan.smith@example.com"
              value={email}
              onChange={(e) => setEmail(sanitizeEmail(e.target.value))}
              size="regular"
              aria-required
            />
          </div>

          <div className={classNames(formStyles.formRow, formStyles.rowVertical)}>
            <label className={formStyles.formLabel}>Role</label>
            <RoleSelect value={role} onChange={(value) => setRole(mapUserRole(value))} />
          </div>

          <div className={formStyles.formControls}>
            <Button variant="primary" size="large" type="submit" loading={loading}>
              Add
            </Button>
            <Button variant="secondary" size="large" onClick={props.onClose} disabled={loading}>
              Cancel
            </Button>
          </div>
        </form>
      </Panel>
    </Modal>
  );
};
