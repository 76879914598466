import classNames from 'classnames';

import { Icon } from '@/components/icon';

import { getNumberOfChildPipelines, getPipelineColor } from '../../pipeline/utils';
import { Exploration, Pipeline } from '../../types';
import { masterBadgeColor } from './utils';

import style from './master-badge.module.scss';

export const MasterBadge = (props: { exploration: Exploration; pipeline: Pipeline }) => {
  const instanceCount = getNumberOfChildPipelines(props.exploration, props.pipeline);
  if (instanceCount === 0) {
    return null;
  }

  const pipelineColor = getPipelineColor(props.exploration, props.pipeline);

  return (
    <div
      className={classNames(style.masterBadge, masterBadgeColor(pipelineColor))}
      title={`Number of child pipelines: ${instanceCount}`}>
      <Icon name="Parent" size={16} />
      <div className={style.masterBadgeLabel}>({instanceCount})</div>{' '}
    </div>
  );
};
