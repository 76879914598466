import { IconButton, InlineButton } from '@/components/button';
import { Dropdown, DropdownMenuItem } from '@/components/dropdown';
import { SearchInput } from '@/components/form/search-input';
import { Icon } from '@/components/icon';
import {
  isDateField,
  isNumericField,
  setVisualisationValueKeys,
} from '@/explore/components/visualisation/utils';
import { fieldToOption } from '@/explore/edit-pipeline/utils';
import { getDereferencedPipelineFields } from '@/explore/pipeline/state';
import {
  DereferencedPipeline,
  Grouping,
  Metric,
  Model,
  QueryVariables,
  SimpleVisualisation,
} from '@/explore/types';

import formStyles from '../../../components/form/form.module.scss';

interface EditSimpleVisualisationProps {
  visualisation: SimpleVisualisation;
  pipeline: DereferencedPipeline;
  models: Model[];
  metrics: Metric[];
  variables: QueryVariables;
  getSeriesMenuOptions: (seriesKey: string) => DropdownMenuItem[];
  groups: Grouping[];
  onChange: (visualisation: SimpleVisualisation) => void;
}

export const EditSimpleVisualisation = (props: EditSimpleVisualisationProps) => {
  const {
    visualisation,
    pipeline,
    models,
    metrics,
    variables,
    getSeriesMenuOptions,
    groups,
    onChange,
  } = props;

  const stateContext = { models, variables, metrics };
  const fields = getDereferencedPipelineFields(pipeline, stateContext);
  const options = visualisation.viewOptions ?? {};
  const bigNumberOptions = options.bigNumber;
  bigNumberOptions && 'comparisonKey' in bigNumberOptions
    ? bigNumberOptions?.comparisonKey
    : undefined;

  const handleValueKeysChange = (keys: string[]) => {
    onChange(setVisualisationValueKeys(visualisation, keys));
  };

  const handleMainAxisKeyChange = (key: string) => {
    onChange({
      ...visualisation,
      mainAxisKey: key,
    });
  };

  const handleMainAxisRemove = () => {
    onChange({
      ...visualisation,
      valueKeys: [],
      mainAxisKey: undefined,
    });
  };

  return (
    <div className={formStyles.formHorizontal}>
      {
        <>
          {visualisation.valueKeys.length > 0 &&
            visualisation.valueKeys.map((valueKey, idx) => {
              return (
                <div key={idx} className={formStyles.formRow}>
                  <div className={formStyles.formLabel}>Field</div>
                  <SearchInput
                    options={fields.filter(isNumericField).map(fieldToOption)}
                    value={valueKey}
                    onChange={(key) => {
                      const valueKeys = visualisation.valueKeys.map((valueKey, i) =>
                        i === idx ? key : valueKey,
                      );
                      handleValueKeysChange(valueKeys);
                    }}
                  />
                  <Dropdown
                    align="right"
                    trigger={(isOpen, setIsOpen) => (
                      <IconButton
                        icon="MoreHorizontal"
                        size="small"
                        title="More..."
                        type="gray"
                        onClick={() => setIsOpen(!isOpen)}
                      />
                    )}
                    items={getSeriesMenuOptions(valueKey)}
                  />
                </div>
              );
            })}

          {visualisation.mainAxisKey !== undefined &&
          fields.some(
            (field) => !visualisation.valueKeys.includes(field.key) && isNumericField(field),
          ) ? (
            <div>
              <InlineButton
                size="small"
                onClick={() => {
                  const field = fields
                    .filter((field) =>
                      visualisation.valueKeys.every((valueKey) => valueKey !== field.key),
                    )
                    .find(isNumericField);

                  if (field === undefined) {
                    return;
                  }

                  handleValueKeysChange([...visualisation.valueKeys, field.key]);
                }}>
                <Icon name="Plus" size={15} /> Add field
              </InlineButton>
            </div>
          ) : null}

          {visualisation.mainAxisKey !== undefined ? (
            <div className={formStyles.formRow}>
              <div className={formStyles.formLabel}>Main axis</div>
              <SearchInput
                options={fields.map(fieldToOption)}
                value={visualisation.mainAxisKey}
                onChange={handleMainAxisKeyChange}
                disabled={groups.length > 0}
              />
              {groups.length === 0 ? (
                <IconButton
                  icon="Trash2"
                  title="Remove main axis"
                  size="small"
                  onClick={handleMainAxisRemove}
                />
              ) : null}
            </div>
          ) : null}
          {groups
            .filter((group) => group.key !== visualisation.mainAxisKey)
            .map((group, idx) => {
              return (
                <div key={idx} className={formStyles.formRow}>
                  <div className={formStyles.formLabel}>Grouped by</div>
                  <SearchInput
                    options={fields.map(fieldToOption)}
                    value={group.key}
                    onChange={() => {}}
                    disabled
                  />
                </div>
              );
            })}
          <div className={formStyles.formRow}>
            {visualisation.mainAxisKey === undefined ? (
              <InlineButton
                size="small"
                onClick={() => {
                  handleMainAxisKeyChange((fields.find(isDateField) ?? fields[0])?.key);
                }}>
                <Icon name="Plus" size={15} /> Add main axis
              </InlineButton>
            ) : null}
          </div>
        </>
      }
    </div>
  );
};
