import classNames from 'classnames';
import { isValid } from 'date-fns';
import { first, isEqual } from 'lodash';
import { Fragment, useMemo, useState } from 'react';

import { Button, IconButton, InlineButton } from '@/components/button';
import { Form } from '@/components/form';
import { Icon } from '@/components/icon';
import { useDeepCompareEffect } from '@/lib/hooks/use-deep-compare-hook';
import { useDirtyContext } from '@/explore/dirty-context';
import {
  getCompositeConditionKeys,
  isSingleFilterCondition,
  isValueExpression,
} from '@/explore/pipeline/operation';
import type {
  CompositeFilterCondition,
  Field,
  Fields,
  FilterOperation,
  VariableDefinition,
} from '@/explore/types';
import { isNumberType } from '@/explore/utils';

import { useEnsureFieldsExist } from '../hooks/use-ensure-fields-exist';
import { createEmptyFilterCondition, requiresArrayValue } from '../utils/filter';

import { FilterForm, isBinaryOperation } from './filter-form';

import form from '@/components/form/form.module.scss';
import styles from '../pipeline.module.scss';

/**
 * If there is an and-filter on top level, nest it in an empty or-filter.
 * This is purely to display all and-filters as if they are 2nd level filters even if
 * there is no parent filter. This is removed upon submit.
 */
const ensureTopLevelOrFilter = (condition: CompositeFilterCondition): CompositeFilterCondition => {
  if (condition.operator === 'and') {
    return {
      operator: 'or',
      operands: [condition],
    };
  }
  return condition;
};

/**
 * Recursively unwrap filters with a single operand so no unnecessary nesting is created.
 */
const unwrapEmptyFilters = (condition: CompositeFilterCondition): CompositeFilterCondition => {
  if (condition.operator === 'and' || condition.operator === 'or') {
    const operands = condition.operands.map(unwrapEmptyFilters);
    if (operands.length === 1) {
      return operands[0];
    }
    return {
      ...condition,
      operands,
    };
  }
  return condition;
};

const isValidCondition = (condition: CompositeFilterCondition, fields: Field[] = []): boolean => {
  if (isSingleFilterCondition(condition)) {
    const field = fields.find(({ key }) => key === condition.key);

    if (field === undefined || condition.value === null) {
      return false;
    }

    if (isValueExpression(condition.value)) {
      return condition.value.expression !== undefined && condition.value.expression !== '';
    }

    if (isNumberType(field.type) && isBinaryOperation(condition.operator)) {
      return requiresArrayValue(condition.operator) && Array.isArray(condition.value)
        ? condition.value.length > 0 && condition.value.every((v) => !isNaN(Number(v)))
        : !isNaN(Number(condition.value));
    }

    if (field.type === 'Date' && condition.value !== undefined) {
      return isValid(new Date(condition.value as string));
    }

    if (Array.isArray(condition.value) && condition.value.length === 0) {
      return false;
    }

    return true;
  }
  return condition.operands.every((c) => isValidCondition(c, fields));
};

interface CompositeFilterFormProps {
  fields: Fields;
  operation?: FilterOperation;
  setOperation: (operation: FilterOperation) => void;
  onClose: () => void;
  onDuplicate?: (operation: FilterOperation) => void;
  variables: VariableDefinition[];
}

export const CompositeFilterForm = (props: CompositeFilterFormProps) => {
  const defaultCondition = useMemo(
    () => createEmptyFilterCondition(first(props.fields)),
    [props.fields],
  );
  const initialCondition = props.operation?.parameters ?? defaultCondition;
  const [condition, setCondition] = useState<CompositeFilterCondition>(initialCondition);
  const { setDirty } = useDirtyContext();

  const fields = useEnsureFieldsExist(props.fields, getCompositeConditionKeys(condition));

  const handleChange = (condition: CompositeFilterCondition) => {
    setCondition(condition);
    const isDirty = !isEqual(initialCondition, condition);
    setDirty(isDirty);
  };

  const handleSubmit = () => {
    setDirty(false);
    props.setOperation({
      operation: 'filter',
      parameters: condition,
    });
  };

  const handleCancel = () => {
    setDirty(false);
    props.onClose();
  };

  useDeepCompareEffect(() => {
    if (props.operation?.parameters !== undefined) {
      setCondition(props.operation?.parameters);
    }
  }, [props.operation?.parameters]);

  return (
    <Form className={form.formHorizontal} onSubmit={handleSubmit}>
      <CompositeFilterFormInner
        fields={fields}
        condition={ensureTopLevelOrFilter(condition)}
        setCondition={(updatedCondition) => handleChange(unwrapEmptyFilters(updatedCondition))}
        depth={0}
        canRemove={!isSingleFilterCondition(condition)}
        canAddAnd={isSingleFilterCondition(condition)}
        canAddOr
        variables={props.variables}
      />
      <div className={form.formControls}>
        <Button size="small" type="submit" disabled={!isValidCondition(condition, props.fields)}>
          {props.operation ? 'Save' : 'Filter'}
        </Button>
        <Button size="small" variant="outlined" onClick={handleCancel}>
          {props.operation ? 'Cancel' : 'Back'}
        </Button>
        {props.onDuplicate !== undefined ? (
          <>
            <div className={form.filler} />
            <IconButton
              icon="Copy"
              onClick={() =>
                props.onDuplicate?.({
                  operation: 'filter',
                  parameters: condition,
                })
              }
            />
          </>
        ) : null}
      </div>
    </Form>
  );
};

interface CompositeFilterFormInnerProps {
  fields: Fields;
  condition: CompositeFilterCondition;
  depth: number;
  canAddOr?: boolean;
  canAddAnd?: boolean;
  canRemove?: boolean;
  setCondition: (condition: CompositeFilterCondition) => void;
  onRemove?: () => void;
  variables: VariableDefinition[];
}

const CompositeFilterFormInner = (props: CompositeFilterFormInnerProps) => {
  const {
    fields,
    condition,
    setCondition,
    onRemove,
    depth,
    canAddAnd,
    canAddOr,
    canRemove,
    variables,
  } = props;

  const handleAddOperand = (operator: 'and' | 'or') => {
    if (operator === condition.operator) {
      // Extend current condition operands by one
      setCondition({
        ...condition,
        operands: [...condition.operands, createEmptyFilterCondition(first(fields))],
      });
    } else {
      // Nest current condition in new one
      setCondition({
        operator,
        operands: [condition, createEmptyFilterCondition(first(fields))],
      });
    }
  };

  return (
    <div className={classNames(form.formHorizontal, styles.compositeFilter)} data-depth={depth}>
      {isSingleFilterCondition(condition) ? (
        <FilterForm
          fields={fields}
          fieldsForExpression={fields}
          condition={condition}
          setCondition={setCondition}
          onRemove={(canRemove ?? false) ? onRemove : undefined}
          variables={variables}
        />
      ) : (
        condition.operands.map((operand, index) => (
          <Fragment key={index}>
            <CompositeFilterFormInner
              fields={fields}
              condition={operand}
              depth={depth + 1}
              canAddAnd={depth < 1}
              canAddOr={false}
              canRemove={canRemove}
              setCondition={(newCondition) => {
                const newOperands = [
                  ...condition.operands.slice(0, index),
                  newCondition,
                  ...condition.operands.slice(index + 1),
                ];
                setCondition({ ...condition, operands: newOperands });
              }}
              onRemove={() => {
                if (condition.operands.length === 1 && onRemove) {
                  return onRemove();
                }
                setCondition({
                  ...condition,
                  operands: [
                    ...condition.operands.slice(0, index),
                    ...condition.operands.slice(index + 1),
                  ],
                });
              }}
              variables={variables}
            />
            {index < condition.operands.length - 1 && (
              <div className={styles.operandSeparator}>
                <span>{condition.operator.toUpperCase()}</span>
              </div>
            )}
          </Fragment>
        ))
      )}
      <div className={styles.addOperand}>
        {(canAddAnd ?? false) && (
          <InlineButton
            onClick={() => {
              handleAddOperand('and');
            }}
            className={styles.addOperandButton}>
            <Icon name="Plus" size={15} /> And
          </InlineButton>
        )}
        {(canAddOr ?? false) && (
          <InlineButton
            onClick={() => {
              handleAddOperand('or');
            }}
            className={styles.addOperandButton}>
            <Icon name="Plus" size={15} /> Or
          </InlineButton>
        )}
      </div>
    </div>
  );
};
