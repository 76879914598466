import { Icon } from '@/components/icon';
import { Tooltip } from '@/components/tooltip';

import styles from './visualisation.module.scss';

export const DataWarning = () => (
  <div className={styles.dataWarning}>
    <Tooltip
      placement="bottom"
      content={
        <span>
          Unable to display full chart data due to too many data points.
          <br /> Consider adding a filter or adjusting grouping options.
        </span>
      }>
      <div className={styles.warningHeader}>
        <Icon name="AlertTriangle" size={24} />
        Unable to display full data
      </div>
    </Tooltip>
  </div>
);
