import { MouseEventHandler } from 'react';
import classNames from 'classnames';

import { IconButton } from '@/components/button';
import { Icon, IconProps } from '@/components/icon';

import { pipelineColor } from './utils';

import styles from './pipeline-model.module.scss';

interface PipelineModelProps {
  title: string;
  children: string;
  icon?: IconProps['name'];
  color?: number;
  htmlTitle?: string;
  empty?: boolean;
  onClick?: MouseEventHandler;
  accessories: { icon: IconProps['name']; title: string; onClick: () => void }[];
}

export const PipelineModel = (props: PipelineModelProps) => (
  <div
    className={classNames(styles.model, pipelineColor(props.color), {
      [styles.empty]: props.empty,
      [styles.clickable]: props.onClick !== undefined,
    })}
    title={props.htmlTitle}
    onClick={props.onClick}>
    <div className={styles.icon}>
      <Icon name={props.icon ?? (props.color === undefined ? 'Model' : 'Instance')} size={16} />
    </div>
    <div className={styles.title}>{props.title}</div>
    <div className={styles.name}>{props.children}</div>
    <div className={styles.accessories} onClick={(event) => event.stopPropagation()}>
      {props.accessories.map(({ icon, title, onClick }, index) => (
        <IconButton
          key={index}
          icon={icon}
          title={title}
          onClick={onClick}
          className={styles.accessory}
        />
      ))}
    </div>
  </div>
);
