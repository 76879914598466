import { Fragment } from 'react';
import { createRoot } from 'react-dom/client';
import { flushSync } from 'react-dom';
import { FunctionDefinition } from '@gosupersimple/penguino';
import {
  generateFnHeader,
  generateFnSignature,
} from '@gosupersimple/penguino/src/utils/documentation';

import styles from './documentation.module.scss';

export const renderDocumentation = (
  functionDefinition: FunctionDefinition,
  { renderSignatures }: { renderSignatures?: boolean } = {},
) => {
  const dom = document.createElement('div');

  flushSync(() =>
    createRoot(dom).render(
      <FunctionDocumentation definition={functionDefinition} renderSignatures={renderSignatures} />,
    ),
  );

  return { dom };
};

export const renderDescription = (property: {
  name?: string | null;
  description?: string;
  type?: string | null;
}) => {
  const dom = document.createElement('div');

  flushSync(() =>
    createRoot(dom).render(
      <div className={styles.documentation}>
        <div className={styles.header}>
          <span>{property.name}</span> <span className={styles.type}>{property.type}</span>
        </div>
        {property.description !== undefined && <div>{property.description}</div>}
      </div>,
    ),
  );

  return { dom };
};

interface FunctionDocumentationProps {
  definition: FunctionDefinition;
  renderSignatures?: boolean;
}

export const FunctionDocumentation = (props: FunctionDocumentationProps) => {
  const { definition, renderSignatures = true } = props;
  const args = definition.arguments?.map((arg) => ('variadic' in arg ? arg.variadic : arg)).flat();

  return (
    <div className={styles.documentation}>
      <div className={styles.header}>
        {generateFnHeader(definition.name, definition.arguments, definition.signatures ?? [])}
      </div>

      <p>{definition.description}</p>

      {renderSignatures && (
        <>
          {args !== undefined && (
            <dl title="Arguments">
              {args.map((arg) => (
                <Fragment key={arg.name}>
                  <dt>
                    {arg.name}
                    {arg.optional === true && <span className={styles.optional}>(optional)</span>}
                  </dt>
                  <dd>
                    {arg.description}
                    {arg.values !== undefined && (
                      <>
                        <br />
                        Allowed values: {arg.values.join(', ')}
                      </>
                    )}
                  </dd>
                </Fragment>
              ))}
            </dl>
          )}

          <div className={styles.signaturesHeader}>Supported signatures</div>
          {definition.signatures !== undefined && (
            <ul>
              {definition.signatures.map((signature, i) => (
                <li key={i}>
                  <code>{generateFnSignature(definition.name, signature)}</code>
                </li>
              ))}
            </ul>
          )}
        </>
      )}
    </div>
  );
};
