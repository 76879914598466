import {
  variableColor1,
  variableColor2,
  variableColor3,
  variableColor4,
  variableColor5,
  variableColor6,
  variableAlternateColor1,
  variableAlternateColor2,
  variableAlternateColor3,
  variableAlternateColor4,
  variableAlternateColor5,
  variableAlternateColor6,
} from '../exploration.module.scss';

export const variableColors = [
  variableColor1,
  variableColor2,
  variableColor3,
  variableColor4,
  variableColor5,
  variableColor6,
];

export const variableAlternateColors = [
  variableAlternateColor1,
  variableAlternateColor2,
  variableAlternateColor3,
  variableAlternateColor4,
  variableAlternateColor5,
  variableAlternateColor6,
];
