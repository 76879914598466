import { FormEvent, useMemo, useState, useEffect } from 'react';
import classNames from 'classnames';

import {
  useDeleteAlertConfigurationMutation,
  useUpsertAlertConfigurationMutation,
} from '@/graphql';
import { getMessage } from '@/lib/error';
import { useSelectedAccount } from '@/lib/accounts/context';
import { getFinalStateOrThrow, PipelineStateContext } from '@/explore/pipeline/state';
import { fieldToOption } from '@/explore/edit-pipeline/utils';
import { getModelOrThrow } from '@/explore/model/utils';
import { Icon } from '@/components/icon';
import { Modal } from '@/components/modal';
import { Panel } from '@/components/panel';
import { useToastContext } from '@/components/toast';
import { TextArea } from '@/components/form/text-area';
import { TagSelect } from '@/components/form/tag-select';
import { Button, IconButton, InlineButton } from '@/components/button';
import { RadioButton } from '@/components/form/radio-button';
import { Input } from '@/components/form/input';

import { AlertConfiguration } from '../types';

import formStyles from '../../components/form/form.module.scss';
import styles from './edit-alert.module.scss';

const getWebhookUrls = (alertConfiguration: AlertConfiguration) => {
  switch (alertConfiguration.notificationConfig.type) {
    case 'webhook':
      return alertConfiguration.notificationConfig.urls;
    case 'slack':
      return alertConfiguration.notificationConfig.webhook_urls;
  }
};

const isValid = (alertConfiguration: AlertConfiguration) => {
  return (
    alertConfiguration.name.length > 0 &&
    alertConfiguration.keyFields.length > 0 &&
    getWebhookUrls(alertConfiguration).length > 0
  );
};

interface EditAlertFormProps {
  alertConfiguration: AlertConfiguration;
  ctx: PipelineStateContext;
  onSubmit: (alertConfiguration: AlertConfiguration) => void;
  onCancel: () => void;
  onDelete?: () => void;
  upsertLoading: boolean;
  deleteLoading: boolean;
}

const EditAlertForm = (props: EditAlertFormProps) => {
  const { alertConfiguration, ctx, onSubmit, onCancel, onDelete, upsertLoading, deleteLoading } =
    props;
  const [name, setName] = useState<string>(alertConfiguration.name);
  const [message, setMessage] = useState<string>(alertConfiguration.message ?? '');
  const [keyFields, setKeyFields] = useState<string[]>(alertConfiguration.keyFields);
  const [displayColumnsFilter, setDisplayColumnsFilter] = useState(false);
  const [notificationType, setNotificationType] = useState<'slack' | 'webhook'>(
    alertConfiguration.notificationConfig.type,
  );
  const [webhookUrls, setWebhookUrls] = useState<string[]>(getWebhookUrls(alertConfiguration));
  const [searchTerm, setSearchTerm] = useState('');

  const { pipeline } = alertConfiguration;
  const model = getModelOrThrow(ctx.models, pipeline.baseModelId);

  const fields = useMemo(
    () =>
      getFinalStateOrThrow(pipeline.baseModelId, pipeline.operations, ctx).fields.sort((a, b) => {
        const aisPk = model.primaryKey.includes(a.key);
        const bisPk = model.primaryKey.includes(b.key);
        if (aisPk && !bisPk) {
          return -1;
        }
        if (!aisPk && bisPk) {
          return 1;
        }
        return a.name.localeCompare(b.name);
      }),
    [ctx, model.primaryKey, pipeline.baseModelId, pipeline.operations],
  );

  const columnOptions = useMemo(() => fields.map(fieldToOption), [fields]);

  const filteredColumnOptions = useMemo(
    () =>
      columnOptions.filter(({ label }) =>
        label.toLocaleLowerCase().includes(searchTerm.toLocaleLowerCase()),
      ),
    [columnOptions, searchTerm],
  );

  const result = {
    alertConfigurationId: alertConfiguration.alertConfigurationId,
    name,
    message,
    keyFields,
    pipeline: alertConfiguration.pipeline,
    notificationConfig:
      notificationType === 'webhook'
        ? {
            ...alertConfiguration.notificationConfig,
            type: notificationType,
            urls: webhookUrls,
          }
        : {
            ...alertConfiguration.notificationConfig,
            type: notificationType,
            webhook_urls: webhookUrls,
          },
  };

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault();
    if (isValid(result)) {
      onSubmit(result);
    }
  };

  const handleFieldsChange = (values: string[]) => {
    const hiddenSelectedFields = keyFields.filter(
      (field) => !filteredColumnOptions.some((option) => option.value === field),
    );
    setKeyFields([...new Set([...hiddenSelectedFields, ...values])]);
  };

  useEffect(() => {
    if (!displayColumnsFilter && searchTerm.length > 0) {
      setSearchTerm('');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [displayColumnsFilter]);

  return (
    <form
      className={classNames(formStyles.formHorizontal, formStyles.sparse, styles.form)}
      onSubmit={handleSubmit}>
      <div className={formStyles.formRow}>
        <label className={formStyles.formLabel} style={{ minWidth: '16%' }}>
          Name
        </label>
        <Input
          required
          autoFocus
          type="text"
          placeholder="Enter value..."
          value={name}
          onChange={(e) => setName(e.target.value)}
        />
      </div>
      <div className={classNames(formStyles.formRow, formStyles.alignTop)}>
        <label className={formStyles.formLabel} style={{ minWidth: '16%' }}>
          Message
        </label>
        <TextArea
          placeholder="Give the alert a descriptive message..."
          value={message}
          rows={3}
          onChange={(e) => setMessage(e.target.value)}
        />
      </div>

      <hr />

      <h2 className={styles.label}>
        Select Columns
        <IconButton
          icon="Search"
          title="Filter columns"
          onClick={() => setDisplayColumnsFilter(!displayColumnsFilter)}
        />
      </h2>
      <p className={formStyles.helpText}>
        We will alert you about every new combination of the selected columns.
      </p>
      {displayColumnsFilter && (
        <Input
          type="text"
          autoFocus
          value={searchTerm}
          placeholder="Filter columns"
          onChange={(event) => setSearchTerm(event.target.value)}
        />
      )}
      <TagSelect
        options={filteredColumnOptions}
        className={formStyles.spaced}
        value={keyFields}
        onChange={handleFieldsChange}
        isMultiSelect
      />

      <hr />
      <h2>Alert Delivery</h2>

      <div className={formStyles.formRow}>
        <RadioButton
          name="notificationType"
          value="slack"
          label="Slack"
          isChecked={notificationType === 'slack'}
          onChange={() => setNotificationType('slack')}
        />{' '}
        <RadioButton
          name="notificationType"
          value="webhook"
          label="Webhook"
          isChecked={notificationType === 'webhook'}
          onChange={() => setNotificationType('webhook')}
        />
      </div>

      {notificationType === 'slack' && (
        <p>
          You can create a webhook URL using Slack&apos;s{' '}
          <a
            href="https://slack.com/apps/A0F7XDUAZ-incoming-webhooks"
            target="_blank"
            rel="noopener noreferrer">
            Incoming Webhooks app
          </a>
          .
        </p>
      )}

      {webhookUrls.map((url, i) => (
        <div key={i} className={formStyles.formRow}>
          <WebhookRow
            url={url}
            onChange={(url) =>
              setWebhookUrls([...webhookUrls.slice(0, i), url, ...webhookUrls.slice(i + 1)])
            }
          />
          {i > 0 && (
            <IconButton
              icon="Trash2"
              size="small"
              title="Delete"
              type="gray"
              onClick={() =>
                setWebhookUrls([...webhookUrls.slice(0, i), ...webhookUrls.slice(i + 1)])
              }
            />
          )}
        </div>
      ))}

      <div className={formStyles.formRow}>
        <InlineButton
          size="small"
          onClick={() => {
            setWebhookUrls([...webhookUrls, '']);
          }}>
          + Add Webhook
        </InlineButton>
      </div>
      <div className={formStyles.formControls}>
        <Button
          type="submit"
          size="large"
          disabled={!isValid(result) || deleteLoading}
          loading={upsertLoading}>
          {alertConfiguration !== undefined ? 'Save Alert' : 'Create Alert'}
        </Button>
        <Button
          variant="secondary"
          size="large"
          onClick={onCancel}
          disabled={upsertLoading || deleteLoading}>
          Cancel
        </Button>
        {onDelete && alertConfiguration.alertConfigurationId !== undefined && (
          <Button
            icon={<Icon name="Trash2" size={16} />}
            variant="outlined"
            size="large"
            color="warning"
            className={formStyles.floatRight}
            onClick={onDelete}
            disabled={upsertLoading}
            loading={deleteLoading}>
            Delete
          </Button>
        )}
      </div>
    </form>
  );
};

const WebhookRow = ({ url, onChange }: { url: string; onChange: (value: string) => void }) => (
  <div className={formStyles.formRow}>
    <label className={formStyles.formLabel} style={{ width: '100px' }}>
      Webhook URL
    </label>
    <Input
      required
      type="url"
      placeholder="https://..."
      value={url}
      onChange={(e) => onChange(e.target.value)}
    />
  </div>
);

const createEmptyAlertConfiguration = (
  values?: Partial<AlertConfiguration>,
): AlertConfiguration => ({
  name: '',
  keyFields: [],
  pipeline: { baseModelId: '', operations: [] },
  notificationConfig: {
    type: 'slack',
    webhook_urls: [''],
  },
  ...values,
});

interface AlertFormModalProps {
  alertConfiguration?: Partial<AlertConfiguration>;
  ctx: PipelineStateContext;
  onClose: () => void;
  onAlertSaved?: () => void;
  onAlertDeleted?: () => void;
}

export const AlertFormModal = (props: AlertFormModalProps) => {
  const { ctx, alertConfiguration, onClose, onAlertSaved, onAlertDeleted } = props;
  const addToast = useToastContext();
  const account = useSelectedAccount();

  const [upsertMutation, { loading: upsertLoading }] = useUpsertAlertConfigurationMutation();
  const [deleteMutation, { loading: deleteLoading }] = useDeleteAlertConfigurationMutation();

  const isEditing = alertConfiguration?.alertConfigurationId !== undefined;

  const handleDelete = async () => {
    if (alertConfiguration === undefined || alertConfiguration.alertConfigurationId === undefined) {
      throw new Error('Attempting to delete non-existend Alert Configuration.');
    }
    const { alertConfigurationId, name } = alertConfiguration;
    if (confirm(`Are you sure you want to delete Alert "${name}"?`)) {
      try {
        await deleteMutation({
          variables: {
            accountId: account.accountId,
            alertConfigurationId,
          },
        });
      } catch (e) {
        addToast({
          title: 'Error',
          content: () => `Deleting Alert failed: ${getMessage(e)}`,
          kind: 'error',
        });
        return;
      }

      onAlertDeleted && onAlertDeleted();
    }
  };

  const handleSubmit = async (input: AlertConfiguration) => {
    try {
      await upsertMutation({
        variables: { accountId: account.accountId, input },
      });
    } catch (e) {
      addToast({
        title: 'Error',
        content: () => `Saving Alert failed: ${getMessage(e)}`,
        kind: 'error',
      });
      return;
    }

    addToast({
      title: 'Success',
      content: () => `Alert ${isEditing ? 'updated' : 'created'} successfully.`,
      kind: 'success',
    });
    onAlertSaved && onAlertSaved();
  };

  return (
    <Modal onClose={onClose} closeOnClickAway>
      <Panel className={styles.panel}>
        <h1>{isEditing ? 'Edit Alert' : 'Create Alert'}</h1>
        <EditAlertForm
          alertConfiguration={createEmptyAlertConfiguration(alertConfiguration)}
          ctx={ctx}
          onSubmit={handleSubmit}
          onCancel={onClose}
          onDelete={isEditing ? handleDelete : undefined}
          upsertLoading={upsertLoading}
          deleteLoading={deleteLoading}
        />
      </Panel>
    </Modal>
  );
};
