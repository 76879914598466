import { Fragment } from 'react';
import classNames from 'classnames';
import { first } from 'lodash';

import { common } from '@gosupersimple/types';

import { isPipelineWithParent } from '@/core/pipeline';
import { Icon } from '@/components/icon';
import { useMetadataContext } from '@/explore/metadata-context';

import { Exploration, Model, Pipeline } from '../../types';
import { getOperationIcon, getOperationOverview } from '../../pipeline/format-operation';
import { getPipelineStateAtIndex } from '../../pipeline/state';
import {
  getParentPipelineTitle,
  getParentPipelineColor,
  getNumberOfChildPipelines,
  dereferencePipeline,
} from '../../pipeline/utils';
import { SavedPipelineHeader } from './saved-pipeline-header';
import { CombineHeader } from './combine-header';
import { Operation } from './operation';
import { isValidOperation } from '../../pipeline/operation';
import { getExplorationVariables } from '../../utils';

import styles from './pipeline-preview.module.scss';

interface PipelinePreviewProps {
  title: string;
  pipeline: Pipeline;
  models: Model[];
  exploration: Exploration;
  variables?: common.QueryVariables;
  onClickParent?: () => void;
  onClickOperation?: (index: number) => void;
}

export const PipelinePreview = (props: PipelinePreviewProps) => {
  const { pipeline, exploration, onClickOperation } = props;
  const { models, metrics: metrics } = useMetadataContext();
  const variables = props.variables ?? getExplorationVariables(exploration);

  const fullPipeline = dereferencePipeline(pipeline, exploration);
  const parentOperationCount = fullPipeline.operations.length - pipeline.operations.length;
  const parentColor = getParentPipelineColor(exploration, pipeline);
  const parentTitle = getParentPipelineTitle(exploration, pipeline);
  const parentChildCount = getNumberOfChildPipelines(exploration, pipeline);

  if (!('baseModelId' in fullPipeline)) {
    throw new Error('PipelinePreview: no baseModelid found in pipeline ancestry chain');
  }

  const baseModelId = fullPipeline.baseModelId;
  const hasParent = isPipelineWithParent(pipeline);

  return (
    <div className={classNames(styles.pipeline)}>
      {first(pipeline.operations)?.operation !== 'joinPipeline' && (
        <SavedPipelineHeader
          title={parentTitle ?? props.title}
          icon={hasParent ? 'Instance' : 'Model'}
          onClick={hasParent ? props.onClickParent : undefined}
          color={parentColor}
          childCount={parentChildCount}
        />
      )}

      {pipeline.operations.map((operation, i) => {
        if (operation.operation === 'joinPipeline') {
          return (
            <CombineHeader
              key={i}
              pipeline={pipeline}
              operation={operation}
              exploration={exploration}
              models={models}
            />
          );
        }

        const fullPipelineIdx = parentOperationCount + i;
        const pipelineState = getPipelineStateAtIndex(
          baseModelId,
          fullPipeline.operations,
          fullPipelineIdx,
          { models, variables, metrics },
        );

        return (
          <Fragment key={i}>
            <Icon name="ChevronRight" size={14} className={styles.separator} />
            <Operation
              disabled={!isValidOperation(operation) || operation.disabled}
              icon={getOperationIcon(operation)}
              description={getOperationOverview(operation, {
                fields: pipelineState.fields,
                model: pipelineState.model,
                variables,
              })}
              onClick={onClickOperation ? () => onClickOperation(fullPipelineIdx) : undefined}
            />
          </Fragment>
        );
      })}
    </div>
  );
};
