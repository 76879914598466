import { AreaStack as VisxAreaStack } from '@visx/shape';
import { ScaleLinear, ScaleTime } from 'd3-scale';

import { formatPropertyValue } from '@/explore/utils/format';

import { TimeSeriesData } from '../grouped-chart/types';
import { generateStackedChartDataByKeys } from './utils';
import { SeriesValues } from './series-values';

import { getSeriesTotalsFormat } from '../grouped-chart/utils';

import styles from './stack-values.module.scss';

type StackValuesProps = {
  data: TimeSeriesData;
  keys: string[];
  valueScale: ScaleLinear<number, number>;
  dateScale: ScaleTime<number, number>;
  reverse?: boolean;
  avoidOverflow?: boolean;
  bar?: boolean;
};

export const StackValues = (props: StackValuesProps) => {
  const { data, keys, dateScale, valueScale, reverse = false, avoidOverflow, bar = false } = props;

  // No need to render anything if there are no value keys
  if (keys.length === 0) {
    return null;
  }

  const stackedData = generateStackedChartDataByKeys(data, keys);

  const renderTotals = () => {
    const showTotals = bar && data.series.some((series) => series.showValues);

    if (!showTotals) {
      return null;
    }

    const { type, format } = getSeriesTotalsFormat(data.series);

    return stackedData.items.map((item) => {
      return (
        <text
          key={`total-${item.date.toISOString()}`}
          className={styles.totalLabel}
          x={dateScale(item.date) ?? 0}
          y={valueScale(item.stackTotal) ?? 0}
          dy={-8}
          style={{ textAnchor: 'middle' }}>
          {formatPropertyValue(item.stackTotal, {
            type,
            format,
            style: 'compact',
          })}
        </text>
      );
    });
  };

  return (
    <>
      <VisxAreaStack
        keys={keys}
        data={stackedData.items}
        order={reverse ? 'reverse' : undefined}
        x={(d) => dateScale(d.data.date)}
        y0={(d) => valueScale(d[0])}
        y1={(d) => valueScale(d[1]) ?? valueScale.range()[0]}>
        {({ stacks }) =>
          stacks.map((stack) => {
            const series = data.series.find(({ key }) => key === stack.key);
            if (series === undefined) {
              throw new Error('Cannot find series');
            }
            return series.showValues ? (
              <SeriesValues
                key={series.key}
                series={series}
                data={stack.map((item) => ({
                  date: item.data.date,
                  cumulativeValue: item.at(bar ? 0 : 1) ?? 0,
                  value: (item.at(1) ?? 0) - (item.at(0) ?? 0),
                }))}
                y={bar ? 2 : 0}
                dateScale={dateScale}
                valueScale={valueScale}
                avoidOverflow={avoidOverflow}
                white={bar}
              />
            ) : null;
          })
        }
      </VisxAreaStack>
      {renderTotals()}
    </>
  );
};
