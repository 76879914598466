import { isNil, last, nth, omit } from 'lodash';

import { Cell, CellViewOptions, Exploration } from '@/explore/types';

import { PersistedNode } from '@/explore/components/canvas/types';

import { setViewOptions } from '../utils/view-options';

const MAX_CELLS_PER_ROW = 3;

const generateRowId = () => Math.random().toString(36).substring(2, 10);

const getCellRowId = <T extends { viewOptions?: CellViewOptions }>(cell: T) =>
  cell.viewOptions?.rowId;

const CellIndexKey = `application/x-sup-drag-cell`;
const RowIdKeyPrefix = `application/x-sup-drag-cell-row`;

export const getRowIdKey = (cell: Cell) =>
  `${RowIdKeyPrefix}-${cell.viewOptions?.rowId ?? ''}`.toLowerCase();

export const isCellDragEvent = (event: React.DragEvent) =>
  event.dataTransfer.types.indexOf(CellIndexKey) !== -1;

export const isDraggedCellOnSameRow = (event: React.DragEvent, cell: Cell) =>
  event.dataTransfer.types.indexOf(getRowIdKey(cell)) !== -1;

export const canDropOnRow = (
  event: React.DragEvent,
  cells: Cell[],
  cellIndex: number,
  cell: Cell,
) => isDraggedCellOnSameRow(event, cell) || getCellRow(getCellRows(cells), cellIndex).length < 3;

export const trackDropEvent = (
  exploration: Exploration,
  droppedCellIndex: number,
  targetIndex: number,
  droppedCell: Cell | undefined,
  afterTarget: boolean,
  mergeIntoRow: boolean,
  trackEvent: (eventName: string, properties?: { [key: string]: unknown }) => void,
) => {
  const isFromMultiCellRow =
    getCellRow(getCellRows(exploration.view.cells), droppedCellIndex).length > 1;
  const eventName =
    mergeIntoRow && !isFromMultiCellRow
      ? 'Exploration Cell Merged Into Row'
      : !mergeIntoRow && isFromMultiCellRow
        ? 'Exploration Cell Detached From Row'
        : 'Exploration Cell Moved';
  trackEvent(eventName, {
    explorationId: exploration.explorationId,
    name: exploration.name,
    cell: droppedCell,
    cellIndex: droppedCellIndex,
    targetIndex,
    afterTarget,
  });
};
/**
 * Returns a 2d array of cells, where each row is a group of cells with the same rowId.
 */
export const getCellRows = <T extends { viewOptions?: CellViewOptions }>(cells: T[]) =>
  cells.reduce((acc, cell) => {
    const lastRow = last(acc);
    if (
      lastRow === undefined ||
      getCellRowId(cell) === undefined ||
      last(lastRow)?.viewOptions?.rowId !== getCellRowId(cell)
    ) {
      return [...acc, [cell]];
    }
    return [...acc.slice(0, -1), [...lastRow, cell]];
  }, [] as T[][]);

export const getCellRow = <T>(rows: T[][], absoluteIndex: number): T[] => {
  let count = 0;
  for (const row of rows) {
    if (absoluteIndex < count + row.length) {
      return row;
    }
    count += row.length;
  }
  return [];
};

export const isCellInMultiRow = <T extends { viewOptions?: CellViewOptions }>(
  cells: T[],
  cellIndex: number,
) => {
  const rowId = cells[cellIndex].viewOptions?.rowId;
  return (
    rowId !== undefined &&
    (nth(cells, cellIndex - 1)?.viewOptions?.rowId === rowId ||
      nth(cells, cellIndex + 1)?.viewOptions?.rowId === rowId)
  );
};

export const isCellFirstInRow = (cells: Cell[], cellIndex: number) => {
  const rowId = cells[cellIndex].viewOptions?.rowId;
  return isNil(rowId) || nth(cells, cellIndex - 1)?.viewOptions?.rowId !== rowId;
};

export const isCellLastInRow = (cells: Cell[], cellIndex: number) => {
  const rowId = cells[cellIndex].viewOptions?.rowId;
  return isNil(rowId) || nth(cells, cellIndex + 1)?.viewOptions?.rowId !== rowId;
};

export const getRowStartIndex = <T extends { viewOptions?: CellViewOptions }>(
  cells: T[],
  cellIndex: number,
) => {
  const rowId = cells[cellIndex].viewOptions?.rowId;
  if (rowId === undefined) {
    return cellIndex;
  }
  const index = cells.findLastIndex(
    (cell, index) => index < cellIndex && getCellRowId(cell) !== rowId,
  );
  return index === -1 ? 0 : index + 1;
};

export const getRowEndIndex = <T extends { viewOptions?: CellViewOptions }>(
  cells: T[],
  cellIndex: number,
) => {
  const rowId = cells[cellIndex].viewOptions?.rowId;
  if (rowId === undefined) {
    return cellIndex;
  }
  const index = cells.findIndex((cell, index) => index > cellIndex && getCellRowId(cell) !== rowId);
  return index === -1 ? cells.length - 1 : index - 1;
};

export const swapCells = <T extends { viewOptions?: CellViewOptions }>(
  cells: T[],
  index1: number,
  index2: number,
) => {
  const newCells = [...cells];
  const cell1 = newCells[index1];
  const rowId1 = cell1.viewOptions?.rowId;
  const cell2 = newCells[index2];
  const rowId2 = cell2.viewOptions?.rowId;

  newCells[index1] = setViewOptions(cell2, { rowId: rowId1 });
  newCells[index2] = setViewOptions(cell1, { rowId: rowId2 });

  return newCells;
};

export const moveCell = <T extends { viewOptions?: CellViewOptions }>(
  cells: T[],
  cellIndex: number,
  targetIndex: number,
  afterTarget = false,
  mergeIntoRow = false,
) => {
  cells = [...cells];
  const targetCell = cells[targetIndex];
  const [cell] = cells.splice(cellIndex, 1);

  // Adjusted target index after to removing the cell
  const adjustedTargetIndex = targetIndex <= cellIndex ? targetIndex : targetIndex - 1;
  const cellIndexAfterMoving =
    adjustedTargetIndex + (afterTarget && cellIndex !== targetIndex ? 1 : 0);

  let rowId = getCellRowId(targetCell);

  if (mergeIntoRow && rowId === undefined && targetIndex !== cellIndex) {
    rowId = generateRowId();
    cells.splice(adjustedTargetIndex, 1, setViewOptions(targetCell, { rowId }));
  }

  cells.splice(
    cellIndexAfterMoving,
    0,
    setViewOptions(cell, { rowId: mergeIntoRow ? rowId : undefined }),
  );

  return ensureValidLayout(cells);
};

/**
 * Ensures that rows can only have up to MAX_CELLS_PER_ROW cells.
 * If a row has more than MAX_CELLS_PER_ROW cells, it will be split into multiple rows
 * of max MAX_CELLS_PER_ROW each.
 */
export const ensureValidLayout = <T extends { viewOptions?: CellViewOptions }>(cells: T[]) => {
  let count = 0;
  let lastRowId: string | undefined = undefined;
  cells = cells.slice();

  for (let i = 0; i < cells.length; i++) {
    const cell = cells[i];
    const rowId = getCellRowId(cell);

    if (rowId === undefined || rowId !== lastRowId) {
      count = 0;
      lastRowId = rowId;
    }

    if (count >= MAX_CELLS_PER_ROW) {
      const newRowId = generateRowId();
      let j = i;
      while (j < cells.length && nth(cells, j)?.viewOptions?.rowId === lastRowId) {
        cells.splice(j, 1, {
          ...cells[j],
          viewOptions: { ...nth(cells, j)?.viewOptions, rowId: newRowId },
        });
        j++;
      }
      count = 0;
      lastRowId = newRowId;
    }

    count++;
  }

  return cells.map((cell, i) =>
    isCellInMultiRow(cells, i) ? cell : { ...cell, viewOptions: omit(cell.viewOptions, 'rowId') },
  );
};

export const updateCellRowHeight = (
  cells: Cell[],
  cellIndex: number,
  height: number | undefined,
) => {
  const startIndex = getRowStartIndex(cells, cellIndex);
  const endIndex = getRowEndIndex(cells, cellIndex);
  return [
    ...cells.slice(0, startIndex),
    ...cells.slice(startIndex, endIndex + 1).map((cell) => setViewOptions(cell, { height })),
    ...cells.slice(endIndex + 1),
  ];
};

export const measureCells = (wrapperElement: HTMLDivElement): PersistedNode[] => {
  const cellElements = [...(wrapperElement.querySelectorAll('[data-id]') ?? [])];
  return cellElements.map((element, idx) => {
    const cellId = element.getAttribute('data-id') as string;
    const { x, y, width, height } = element.getBoundingClientRect();
    return {
      id: cellId,
      position: { x, y },
      measured: { width, height },
      zIndex: idx,
    };
  });
};
