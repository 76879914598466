import classNames from 'classnames';

import { useExplorationContext } from '@/explore/exploration/exploration-context';
import { Loader } from '@/components/loader';
import { CloseButton } from '@/components/button';
import { metricsByModelId } from '@/explore/utils/metrics';

import {
  DereferencedPipeline,
  Metric,
  Model,
  Visualisation,
  ValueClickHandler,
  isAggregatedVisualisation,
  isSimpleVisualisation,
} from '../../types';
import { VisualisationGraph } from './visualisation-graph';
import { getDereferencedPipelineFields, getFinalStateOrThrow } from '../../pipeline/state';
import { AggregatedVisualisationOptionsForm } from './visualisation-options-form/aggregated-visualisation-options-form';
import { SimpleVisualisationOptionsForm } from './visualisation-options-form/simple-visualisation-options-form';

import { getQueryForVisualisation } from './utils';

import styles from './visualisation.module.scss';

interface VisualisationPanelProps {
  pipeline: DereferencedPipeline;
  models: Model[];
  metrics: Metric[];
  accountId: string;
  visualisation: Visualisation;
  onClose(): void;
  onValueClick?: ValueClickHandler;
  onVisualisationChange: (visualisation: Visualisation) => void;
  isInView: boolean;
  isResized: boolean;
}

export const VisualisationPanel = (props: VisualisationPanelProps) => {
  const {
    pipeline,
    models,
    metrics,
    accountId,
    visualisation,
    isResized,
    onClose,
    onValueClick,
    onVisualisationChange,
  } = props;

  const { getVariables } = useExplorationContext();

  const stateContext = { models, variables: getVariables(), metrics };
  const fields = getDereferencedPipelineFields(pipeline, stateContext);
  const { groups } = getQueryForVisualisation(pipeline, fields, visualisation);

  const showOptions =
    (isAggregatedVisualisation(visualisation) &&
      visualisation.aggregation.aggregations.length === 1 &&
      visualisation.aggregation.groups.length < 2) ||
    (isSimpleVisualisation(visualisation) &&
      groups.length === 0 &&
      visualisation.mainAxisKey !== undefined);

  const pipelineState = getFinalStateOrThrow(pipeline.baseModelId, pipeline.operations, {
    models,
    variables: getVariables(),
    metrics,
  });

  const availableMetrics = metricsByModelId(metrics, pipelineState.model.modelId);

  const chartOptions = showOptions ? (
    isAggregatedVisualisation(visualisation) ? (
      <AggregatedVisualisationOptionsForm
        visualisation={visualisation}
        onChange={onVisualisationChange}
        metrics={availableMetrics}
        fields={pipelineState.fields}
        excludeAggregationTypes={['earliest', 'latest', 'first', 'last']}
      />
    ) : (
      <SimpleVisualisationOptionsForm
        visualisation={visualisation}
        onChange={onVisualisationChange}
        fields={pipelineState.fields}
      />
    )
  ) : null;

  return (
    <div className={classNames(styles.visualisationCard)}>
      <Header onClose={onClose}>{chartOptions}</Header>
      <VisualisationGraph
        pipeline={pipeline}
        fields={pipelineState.fields}
        visualisation={visualisation}
        onValueClick={onValueClick}
        onStackingChange={(stacked) =>
          onVisualisationChange({
            ...visualisation,
            viewOptions: { ...visualisation.viewOptions, stacked },
          })
        }
        accountId={accountId}
        variables={getVariables()}
        isInView={props.isInView}
        isResized={isResized}
      />
    </div>
  );
};

interface VisualisationLoaderProps {
  isResized?: boolean;
}

export const VisualisationLoader = ({ isResized = false }: VisualisationLoaderProps) => (
  <div
    className={classNames(styles.visualisationLoader, {
      [styles.isResized]: isResized,
    })}>
    <div className={styles.message}>
      <Loader />
    </div>
  </div>
);

interface HeaderProps {
  children?: React.ReactNode;
  onClose: () => void;
}

const Header = ({ children, onClose }: HeaderProps) => (
  <div className={styles.header}>
    <div className={styles.toolbar}>{children}</div>
    <div className={styles.close}>
      <CloseButton onClick={onClose} iconSize="regular" />
    </div>
  </div>
);
