import gql from 'graphql-tag';
export const ExplorationFragment = gql`
  fragment ExplorationFragment on Exploration {
    explorationId
    name
    description
    labels
    parameters {
      key
      modelId
    }
    view {
      canvas
      cells {
        ... on RecordsViewCell {
          id
          kind
          title
          excludeProperties
          pipeline {
            baseModelId
            operations {
              operation
              parameters
              disabled
            }
            pipelineId
            parentId
          }
          sort {
            key
            direction
          }
          visualisations {
            aggregationType
            aggregationTarget
            groupByField
            timeGranularity
            viewOptions
            aggregations
            groups
            aggregation
            valueKeys
            mainAxisKey
          }
          viewOptions
        }
        ... on FunnelViewCell {
          id
          kind
          title
          pipeline {
            baseModelId
            operations {
              operation
              parameters
            }
          }
          viewOptions
        }
        ... on CohortViewCell {
          id
          kind
          title
          pipeline {
            baseModelId
            operations {
              operation
              parameters
            }
            pipelineId
            parentId
          }
          viewOptions
        }
        ... on VariableViewCell {
          id
          kind
          definition
          viewOptions
        }
        ... on SqlViewCell {
          id
          kind
          title
          pipeline {
            baseModelId
            operations {
              operation
              parameters
            }
            pipelineId
          }
          viewOptions
        }
        ... on PythonViewCell {
          id
          kind
          title
          pipeline {
            baseModelId
            operations {
              operation
              parameters
            }
            pipelineId
          }
          viewOptions
        }
        ... on TextViewCell {
          id
          kind
          title
          content
          viewOptions
        }
      }
    }
  }
`;
export const MetricFragment = gql`
  fragment MetricFragment on Metric {
    metricId
    name
    definition
  }
`;
export const ModelFragment = gql`
  fragment ModelFragment on Model {
    modelId
    name
    description
    primaryKey
    labels
    semantics
    properties {
      name
      type
      key
      format
      description
    }
    relations {
      key
      modelId
      type
      name
      joinStrategy {
        joinKeyOnBase
        joinKeyOnRelated
        through {
          modelId
          joinKeyToBase
          joinKeyToRelated
        }
        steps {
          relationKey
        }
      }
    }
  }
`;
export const RecordTypeFragment = gql`
  fragment RecordTypeFragment on QueryResponseRecordType {
    key
    type
    name
    pk
    precision
    model {
      modelId
      name
      propertyKey
    }
    relation {
      modelId
      key
      name
    }
  }
`;
export const AlertConfiguration = gql`
  query AlertConfiguration($accountId: ID!, $alertConfigurationId: ID!) {
    account(accountId: $accountId) {
      alertConfiguration(alertConfigurationId: $alertConfigurationId) {
        alertConfigurationId
        accountId
        name
        message
        pipeline
        keyFields
        notificationConfig
        createdAt
      }
    }
  }
`;
export const Navigation = gql`
  query Navigation($accountId: ID!) {
    account(accountId: $accountId) {
      accountId
      navigation {
        ... on ExplorationNavigationItem {
          kind
          label
          explorationId
        }
      }
    }
  }
`;
export const ExplorationRecordType = gql`
  query ExplorationRecordType(
    $accountId: ID!
    $baseModelId: ID!
    $pipeline: [OperationInput!]!
    $variables: JSON
  ) {
    account(accountId: $accountId) {
      accountId
      query(baseModelId: $baseModelId, pipeline: $pipeline, first: 1, variables: $variables) {
        recordType {
          key
          type
          name
          relation {
            modelId
            key
            name
          }
        }
        grouping {
          key
        }
      }
    }
  }
`;
export const ExplorationGeneration = gql`
  query ExplorationGeneration(
    $accountId: ID!
    $search: String!
    $correlationId: String
    $exploration: ExplorationInput
  ) {
    account(accountId: $accountId) {
      generateExploration(
        search: $search
        correlationId: $correlationId
        exploration: $exploration
      ) {
        exploration {
          ...ExplorationFragment
        }
      }
    }
  }
  ${ExplorationFragment}
`;
export const ExplorationsSearch = gql`
  query ExplorationsSearch($accountId: ID!, $search: String) {
    account(accountId: $accountId) {
      explorations(search: $search) {
        ...ExplorationFragment
      }
    }
  }
  ${ExplorationFragment}
`;
export const DeleteExploration = gql`
  mutation DeleteExploration($accountId: ID!, $explorationId: ID!) {
    deleteExploration(accountId: $accountId, explorationId: $explorationId) {
      ... on DeleteExplorationSuccess {
        result
      }
      ... on NotAllowedError {
        message
      }
    }
  }
`;
export const EnumOptions = gql`
  query EnumOptions($accountId: ID!, $modelId: ID!, $propertyKey: String!) {
    account(accountId: $accountId) {
      model(modelId: $modelId) {
        modelId
        property(key: $propertyKey) {
          key
          enumOptions {
            label
            value
          }
        }
      }
    }
  }
`;
export const ExplorationData = gql`
  query ExplorationData(
    $accountId: ID!
    $baseModelId: ID!
    $pipeline: [OperationInput!]!
    $first: Int
    $after: String
    $sort: [QuerySortBy!]
    $variables: JSON
  ) {
    account(accountId: $accountId) {
      accountId
      name
      query(
        baseModelId: $baseModelId
        pipeline: $pipeline
        first: $first
        after: $after
        sort: $sort
        variables: $variables
      ) {
        edges {
          node
        }
        pageInfo {
          hasNextPage
          endCursor
        }
        recordType {
          ...RecordTypeFragment
        }
        grouping {
          key
        }
      }
    }
  }
  ${RecordTypeFragment}
`;
export const ExplorationDescribeQuery = gql`
  query ExplorationDescribeQuery(
    $accountId: ID!
    $baseModelId: ID!
    $pipeline: [OperationInput!]!
    $first: Int
    $after: String
    $sort: [QuerySortBy!]
    $variables: JSON
  ) {
    account(accountId: $accountId) {
      accountId
      name
      describeQuery(
        baseModelId: $baseModelId
        pipeline: $pipeline
        first: $first
        after: $after
        sort: $sort
        variables: $variables
      ) {
        sql
      }
    }
  }
`;
export const UpsertExploration = gql`
  mutation UpsertExploration($accountId: ID!, $input: ExplorationInput!) {
    upsertExploration(accountId: $accountId, input: $input) {
      ...ExplorationFragment
    }
  }
  ${ExplorationFragment}
`;
export const Explorations = gql`
  query Explorations($accountId: ID!) {
    account(accountId: $accountId) {
      accountId
      explorations {
        ...ExplorationFragment
      }
      models {
        ...ModelFragment
      }
      metrics {
        ...MetricFragment
      }
      parameterisedExplorations {
        ...ExplorationFragment
      }
    }
  }
  ${ExplorationFragment}
  ${ModelFragment}
  ${MetricFragment}
`;
export const GetExplorationState = gql`
  query GetExplorationState($accountId: ID!, $explorationId: ID!) {
    account(accountId: $accountId) {
      explorationState(explorationId: $explorationId) {
        explorationId
        previousStateId
        explorationSourceId
        state
      }
    }
  }
`;
export const SetExplorationState = gql`
  mutation SetExplorationState($accountId: ID!, $input: ExplorationStateInput!) {
    upsertExplorationState(accountId: $accountId, input: $input) {
      explorationId
      previousStateId
      explorationSourceId
      state
    }
  }
`;
export const AccountsList = gql`
  query AccountsList {
    me {
      userId
      email
    }
    accounts {
      accountId
      slug
      name
      timezone
      isOnboarded
      features
      me {
        role {
          permissions {
            permissionId
          }
        }
      }
    }
  }
`;
export const CreateAnalyticsEvent = gql`
  mutation CreateAnalyticsEvent($input: CreateAnalyticsEventInput!, $accountId: ID) {
    createAnalyticsEvent(input: $input, accountId: $accountId) {
      ... on AnalyticsEvent {
        analyticsEventId
      }
      ... on NotAllowedError {
        message
      }
      ... on CreateAnalyticsEventError {
        error
      }
    }
  }
`;
export const OnboardingState = gql`
  query OnboardingState($accountId: ID!) {
    account(accountId: $accountId) {
      onboardingState {
        hasConnection
        hasConsented
        hasOnboarded
        hasPassedSetupCallStep
        hasBookedSetupCall
        welcomeSurvey
      }
    }
  }
`;
export const CreateConnection = gql`
  mutation CreateConnection($accountId: ID!, $type: String!, $name: String!, $properties: JSON!) {
    createConnection(accountId: $accountId, type: $type, name: $name, properties: $properties) {
      connectionId
    }
  }
`;
export const TestConnection = gql`
  query TestConnection($accountId: ID!, $type: String!, $properties: JSON!) {
    account(accountId: $accountId) {
      testConnection(type: $type, properties: $properties) {
        ... on TestConnectionSuccess {
          success
        }
        ... on TestConnectionFailed {
          error
        }
      }
    }
  }
`;
export const GiveConsent = gql`
  mutation GiveConsent($accountId: ID!, $input: ConsentInput!) {
    giveConsent(accountId: $accountId, input: $input)
  }
`;
export const UpdateOnboardingState = gql`
  mutation UpdateOnboardingState($accountId: ID!, $key: OnboardingStateKey!, $value: JSON!) {
    updateOnboardingState(accountId: $accountId, key: $key, value: $value)
  }
`;
export const Me = gql`
  query Me {
    me {
      userId
      email
    }
  }
`;
export const SignUp = gql`
  mutation SignUp($email: String!, $consent: ConsentInput!) {
    signUp(email: $email, consent: $consent) {
      userId
    }
  }
`;
export const CreateAccessToken = gql`
  mutation CreateAccessToken($input: CreateAccessTokenInput!) {
    createAccessToken(input: $input) {
      ... on AccessTokenPreview {
        accessToken {
          name
          preview
          lastUsedAt
          expiresAt
          createdAt
          accessTokenId
        }
        token
      }
      ... on NotAllowedError {
        message
      }
      ... on CreateAccessTokenError {
        error
      }
    }
  }
`;
export const DeleteAccessToken = gql`
  mutation DeleteAccessToken($accessTokenId: ID!) {
    deleteAccessToken(accessTokenId: $accessTokenId) {
      ... on DeleteAccessTokenSuccess {
        result
      }
      ... on NotAllowedError {
        message
      }
      ... on DeleteAccessTokenError {
        error
      }
    }
  }
`;
export const DeleteAlertConfiguration = gql`
  mutation DeleteAlertConfiguration($accountId: ID!, $alertConfigurationId: ID!) {
    deleteAlertConfiguration(accountId: $accountId, alertConfigurationId: $alertConfigurationId) {
      ... on DeleteAlertConfigurationSuccess {
        result
      }
    }
  }
`;
export const UpsertAlertConfiguration = gql`
  mutation UpsertAlertConfiguration($accountId: ID!, $input: AlertConfigurationInput!) {
    upsertAlertConfiguration(accountId: $accountId, input: $input) {
      alertConfigurationId
      name
      message
      keyFields
      pipeline
      notificationConfig
      createdAt
    }
  }
`;
export const AccountSettings = gql`
  query AccountSettings($accountId: ID!) {
    accessTokens {
      name
      preview
      expiresAt
      lastUsedAt
      createdAt
      accessTokenId
    }
    account(accountId: $accountId) {
      me {
        userId
        role {
          roleId
          permissions {
            permissionId
          }
        }
      }
      users {
        userId
        email
        role {
          roleId
          name
        }
        addedAt
      }
      connections {
        name
        type
        createdAt
      }
      integrations {
        integrationId
        service
        description
      }
      alertConfigurations {
        alertConfigurationId
        accountId
        name
        message
        keyFields
        pipeline
        notificationConfig
        createdAt
      }
      models {
        ...ModelFragment
      }
      metrics {
        ...MetricFragment
      }
      navigation {
        ... on ExplorationNavigationItem {
          kind
          label
          explorationId
        }
      }
      explorations {
        explorationId
        name
      }
    }
  }
  ${ModelFragment}
  ${MetricFragment}
`;
export const UpdateNavigation = gql`
  mutation UpdateNavigation($accountId: ID!, $input: JSON!) {
    updateNavigation(accountId: $accountId, input: $input)
  }
`;
export const AddUserToAccount = gql`
  mutation AddUserToAccount($accountId: ID!, $email: String!, $role: UserRole!) {
    addUserToAccount(accountId: $accountId, email: $email, role: $role) {
      accountUser {
        userId
        email
        role {
          roleId
        }
      }
    }
  }
`;
export const DeleteUserFromAccount = gql`
  mutation DeleteUserFromAccount($accountId: ID!, $userId: ID!) {
    deleteUserFromAccount(accountId: $accountId, userId: $userId) {
      account {
        accountId
        users {
          userId
          email
          role {
            roleId
          }
          addedAt
        }
      }
      accountUser {
        userId
        email
      }
      success
    }
  }
`;
export const UpdateUserInAccount = gql`
  mutation UpdateUserInAccount($accountId: ID!, $userId: ID!, $role: UserRole!) {
    updateUserInAccount(accountId: $accountId, userId: $userId, role: $role) {
      accountUser {
        userId
        email
      }
    }
  }
`;
