import { useRef, useState } from 'react';
import classNames from 'classnames';
import { uniq } from 'lodash';

import { Icon } from '@/components/icon';

import styles from './tag-input.module.scss';

export interface TagInputProps {
  value: string[];
  placeholder?: string;
  highlighted?: boolean;
  autoFocus?: boolean;
  required?: boolean;
  onChange?: (value: string[]) => void;
}

export const TagInput = (props: TagInputProps) => {
  const { value, placeholder = 'Value', autoFocus = false, required = false, highlighted } = props;

  const [inputValue, setInputValue] = useState('');
  const ref = useRef<HTMLInputElement>(null);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.value.includes(',')) {
      const tags = e.target.value
        .split(',')
        .map((tag) => tag.trim())
        .filter((tag) => tag !== '');
      props.onChange?.(uniq([...value, ...tags]));
      setInputValue('');
    } else {
      setInputValue(e.target.value);
    }
  };

  const handleInputBlur = () => {
    if (inputValue.length > 0) {
      props.onChange?.(uniq([...value, inputValue]));
      setInputValue('');
    }
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter' && inputValue.length > 0) {
      props.onChange?.(uniq([...value, inputValue]));
      setInputValue('');
    } else if (e.key === 'Backspace' && inputValue.length === 0) {
      props.onChange?.(value.slice(0, -1));
    }
  };

  return (
    <ol
      aria-required={required}
      className={classNames(styles.tagInput, {
        [styles.highlighted]: highlighted,
      })}
      onClick={() => ref.current?.focus()}>
      {value.map((tag, index) => (
        <li key={index} className={styles.tag}>
          <span className={styles.label}>{tag}</span>
          <button
            type="button"
            title="Remove"
            className={styles.removeTag}
            onClick={() => {
              props.onChange?.(value.filter((_, i) => i !== index));
            }}>
            <Icon name="X" size={12} />
          </button>
        </li>
      ))}
      <li className={styles.newTag}>
        <input
          autoFocus={autoFocus}
          draggable={false}
          ref={ref}
          className={styles.input}
          placeholder={placeholder}
          value={inputValue}
          onChange={handleInputChange}
          onBlur={handleInputBlur}
          onKeyDown={handleKeyDown}
        />
      </li>
    </ol>
  );
};
