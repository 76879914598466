import { compact, isNil, omitBy } from 'lodash';

import { common, model } from '@gosupersimple/types';

import {
  ExplorationFragmentFragment,
  ModelFragmentFragment,
  PipelineOperator,
  QueryPipeline,
  RecordTypeFragmentFragment,
  AlertConfiguration as GraphQLAlertConfiguration,
} from '../graphql';

import {
  AggregatedVisualisation,
  DereferencedPipeline,
  Exploration,
  Fields,
  Model,
  Pipeline,
  PipelineOperation,
  RecordsCell,
  Visualisation,
  cellViewOptions,
  timeAggregationPeriodZod,
  visualisationZod,
} from './types';
import { AlertConfiguration } from '../settings/types';

import {
  visualisationKeysFromAggregation,
  ensureValidSeries,
} from './components/visualisation/utils';

export const convertRecordTypeTypes = (
  input?: RecordTypeFragmentFragment[],
): Fields | undefined => {
  return input?.map((i) => ({
    key: i.key,
    type: convertPropertyValueType(i.type),
    name: i.name,
    pk: i.pk ?? undefined,
    relation: !isNil(i.relation)
      ? {
          modelId: i.relation.modelId,
          key: i.relation.key,
          name: i.relation.name,
        }
      : undefined,
    model: !isNil(i.model)
      ? {
          modelId: i.model.modelId,
          name: i.model.name,
          propertyKey: i.model.propertyKey,
        }
      : undefined,
    precision: timeAggregationPeriodZod
      .optional()
      .catch(() => undefined)
      .parse(i.precision),
  }));
};

export const convertModelTypes = (input?: ModelFragmentFragment[]): Model[] | undefined => {
  return input?.map((i) => ({
    modelId: i.modelId,
    name: i.name,
    description: i.description ?? undefined,
    primaryKey: i.primaryKey,
    labels: i.labels,
    properties: i.properties.map((p) => ({
      name: p.name,
      type: convertPropertyValueType(p.type) ?? ('String' as const),
      format: !isNil(p.format) ? common.propertyValueFormat.parse(p.format) : undefined,
      key: p.key,
      description: p.description ?? undefined,
    })),
    relations: i.relations.map((r) => {
      return {
        key: r.key,
        modelId: r.modelId,
        type: r.type,
        name: r.name,
        joinStrategy:
          !isNil(r.joinStrategy.joinKeyOnBase) && !isNil(r.joinStrategy.joinKeyOnRelated)
            ? {
                joinKeyOnBase: r.joinStrategy.joinKeyOnBase,
                joinKeyOnRelated: r.joinStrategy.joinKeyOnRelated,
                through: !isNil(r.joinStrategy.through)
                  ? {
                      modelId: r.joinStrategy.through.modelId,
                      joinKeyToBase: !isNil(r.joinStrategy.through.joinKeyToBase)
                        ? r.joinStrategy.through.joinKeyToBase
                        : undefined,
                      joinKeyToRelated: !isNil(r.joinStrategy.through.joinKeyToRelated)
                        ? r.joinStrategy.through.joinKeyToRelated
                        : undefined,
                    }
                  : undefined,
              }
            : !isNil(r.joinStrategy.steps)
              ? {
                  steps: r.joinStrategy.steps.map((step) => ({
                    relationKey: step.relationKey,
                  })),
                }
              : undefined,
      };
    }),
    semantics: i.semantics,
  }));
};

const convertPipelineOperationTypes = (operation: PipelineOperator): PipelineOperation =>
  omitBy(
    {
      operation: operation.operation,
      parameters: operation.parameters,
      disabled: operation.disabled,
    },
    isNil,
  ) as PipelineOperation;

const convertPipelineTypes = (input?: QueryPipeline): Pipeline =>
  omitBy(
    {
      baseModelId: input?.baseModelId,
      operations: (input?.operations ?? []).map(convertPipelineOperationTypes),
      pipelineId: input?.pipelineId,
      parentId: input?.parentId,
    },
    isNil,
  ) as Pipeline;

export const convertVisualisation = (input: any): Visualisation => {
  const sanitizedInput = {
    ...input,
    aggregation: input.aggregation ?? undefined,
    mainAxisKey: input.mainAxisKey ?? undefined,
  };

  const {
    aggregationType,
    aggregationTarget,
    groupByField,
    timeGranularity,
    viewOptions,
    aggregations,
    groups,
    valueKeys,
  } = sanitizedInput;

  if (!isNil(valueKeys)) {
    return visualisationZod.parse({
      ...sanitizedInput,
      viewOptions: {
        ...viewOptions,
        ...{
          series: ensureValidSeries({ valueKeys }, viewOptions?.series),
        },
      },
    });
  }

  // If aggregations are provided, we assume that the visualisation is already using the new schema
  if (!isNil(aggregations)) {
    const { valueKeys: generatedValueKeys } = visualisationKeysFromAggregation({
      aggregations,
      groups,
    });
    const newViewOptions = {
      ...viewOptions,
      ...(generatedValueKeys.length > 0
        ? {
            series: ensureValidSeries({ valueKeys: generatedValueKeys }, viewOptions?.series),
          }
        : {}),
    };
    const aggregation = {
      aggregations,
      groups,
    };
    return aggregations.length > 0
      ? {
          aggregation,
          ...visualisationKeysFromAggregation(aggregation),
          viewOptions: newViewOptions,
        }
      : {
          valueKeys: [],
          viewOptions: newViewOptions,
        };
  }

  // TODO: Check if this can be removed
  // Migration logic for old visualisations
  const fallbackVisualisation: AggregatedVisualisation = {
    aggregation: {
      aggregations: [
        {
          type: 'count',
          property: {
            key: 'count',
            name: 'count',
          },
        },
      ],
      groups: [{ key: 'tmp' }], // tmp is replaced with default grouping in ensure valid groupings
    },
    mainAxisKey: 'tmp',
    valueKeys: ['count'],
    viewOptions: {
      ...viewOptions,
      series: ensureValidSeries({ valueKeys: ['count'] }, viewOptions?.series),
    },
  };

  if (aggregationType === null) {
    return fallbackVisualisation;
  }

  const aggregation = {
    aggregations: [
      {
        type: aggregationType,
        ...(isNil(aggregationTarget) ? {} : { key: aggregationTarget }),
        property: {
          key: aggregationType,
          name: aggregationType,
        },
      },
    ],
    groups: [
      {
        key: groupByField ?? 'tmp', // tmp is replaced with default grouping in ensure valid groupings
        ...(isNil(timeGranularity) ? {} : { fill: true, precision: timeGranularity }),
      },
    ],
  };

  const generatedValueKeys = visualisationKeysFromAggregation(aggregation).valueKeys;
  const visualisation = visualisationZod.catch(fallbackVisualisation).parse({
    aggregation,
    ...visualisationKeysFromAggregation(aggregation),
    viewOptions: {
      ...viewOptions,
      series: ensureValidSeries({ valueKeys: generatedValueKeys }, viewOptions?.series),
    },
  });

  return visualisation;
};

export const convertExplorationTypes = (
  input?: ExplorationFragmentFragment | null,
): Exploration | undefined =>
  isNil(input)
    ? undefined
    : {
        explorationId: input.explorationId,
        name: input.name,
        description: input.description ?? undefined,
        labels: input.labels,
        parameters: input.parameters.map(({ key, modelId }) => ({ key, modelId })),
        view: {
          canvas: input.view.canvas ?? undefined,
          cells: input.view.cells.map((c) => {
            switch (c.__typename) {
              case 'RecordsViewCell':
                return {
                  id: c.id,
                  kind: c.kind,
                  title: c.title,
                  excludeProperties: c.excludeProperties,
                  pipeline: convertPipelineTypes(c.pipeline),
                  visualisations: (c.visualisations ?? []).map(convertVisualisation),
                  sort: c.sort ?? [],
                  viewOptions:
                    c.viewOptions !== null ? cellViewOptions.parse(c.viewOptions) : undefined,
                } as RecordsCell;
              case 'FunnelViewCell':
                return {
                  id: c.id,
                  kind: 'funnel',
                  title: c.title,
                  pipeline: convertPipelineTypes(c.pipeline),
                  viewOptions:
                    c.viewOptions !== null ? cellViewOptions.parse(c.viewOptions) : undefined,
                };
              case 'CohortViewCell':
                return {
                  id: c.id,
                  kind: 'cohort',
                  title: c.title,
                  pipeline: convertPipelineTypes(c.pipeline),
                  viewOptions:
                    c.viewOptions !== null ? cellViewOptions.parse(c.viewOptions) : undefined,
                };
              case 'VariableViewCell':
                return {
                  id: c.id,
                  kind: 'variable',
                  definition: c.definition,
                  viewOptions:
                    c.viewOptions !== null ? cellViewOptions.parse(c.viewOptions) : undefined,
                };
              case 'SqlViewCell':
                return {
                  id: c.id,
                  kind: 'sql',
                  title: c.title ?? '',
                  pipeline: convertPipelineTypes(c.pipeline),
                  viewOptions:
                    c.viewOptions !== null ? cellViewOptions.parse(c.viewOptions) : undefined,
                };
              case 'PythonViewCell':
                return {
                  id: c.id,
                  kind: 'python',
                  title: c.title ?? '',
                  pipeline: convertPipelineTypes(c.pipeline),
                  viewOptions:
                    c.viewOptions !== null ? cellViewOptions.parse(c.viewOptions) : undefined,
                };
              case 'TextViewCell':
                return {
                  id: c.id,
                  kind: 'text',
                  title: c.title ?? '',
                  content: c.content ?? '',
                  viewOptions:
                    c.viewOptions !== null ? cellViewOptions.parse(c.viewOptions) : undefined,
                };
              default:
                throw new Error(`Unknown cell type: ${c.__typename}`);
            }
          }),
        },
      };

export const convertExplorationsArrayTypes = (
  input: ExplorationFragmentFragment[],
): Exploration[] => compact(input.map((e) => convertExplorationTypes(e)));

export const convertAlertConfigurationTypes = (
  input?: GraphQLAlertConfiguration | null,
): AlertConfiguration | undefined =>
  isNil(input)
    ? undefined
    : {
        alertConfigurationId: input.alertConfigurationId,
        name: input.name,
        message: input.message,
        pipeline: convertPipelineTypes(input.pipeline) as DereferencedPipeline,
        keyFields: input.keyFields,
        notificationConfig: input.notificationConfig,
        createdAt: input.createdAt,
      };

export const convertPropertyValueType = (
  type: string | undefined | null,
): model.PropertyType | null => {
  const res = model.propertyType.safeParse(type);
  if (res.success) {
    return res.data;
  }
  return null;
};
