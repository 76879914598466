interface LinkableProperty {
  key: string;
  relation?: { key: string; modelId?: string } | null;
  pk?: boolean;
}

export interface ExplorationLike {
  parameters: { key: string; modelId: string }[];
}

export interface LinkingProperty extends LinkableProperty {
  buildLink?: (record: Record<string, unknown>) => string;
}

export const isExplorationReferencedByProperty = <
  T extends LinkableProperty,
  E extends ExplorationLike,
>(
  exploration: E,
  property: T,
) =>
  exploration.parameters.some(
    ({ key, modelId }) => key === property.relation?.key && modelId === property.relation?.modelId,
  );

export const getLinkableExplorations = <T extends LinkableProperty, E extends ExplorationLike>(
  properties: T[],
  explorations: E[],
) => {
  const isParameterReferencedByProperty = (parameter: ExplorationLike['parameters'][number]) =>
    properties.some(
      ({ relation }) => relation?.key === parameter.key && relation?.modelId === parameter.modelId,
    );

  const allParametersReferencedByProperties = <E extends ExplorationLike>(exploration: E) =>
    exploration.parameters.length > 0 &&
    exploration.parameters.every(isParameterReferencedByProperty);

  return explorations
    .filter(allParametersReferencedByProperties)
    .sort((a, b) => a.parameters.length - b.parameters.length);
};
