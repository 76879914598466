import { useEffect, useState } from 'react';
import { isNil } from 'lodash';

import { Breakpoint, useScreenSize } from '@/lib/hooks/use-screen-size';
import { ErrorBoundary, GenericFallback } from '@/lib/error';
import { getNodes, useExplorationDataQuery } from '@/graphql';
import { useIsInView, useQueryLoadCondition } from '@/lib/hooks';
import { isTimeoutError } from '@/lib/error/utils/timeout';

import { useMetadataContext } from '@/explore/metadata-context';

import { setCellTitle } from '@/core/cell';

import { CohortCell, Exploration } from '../../types';
import { useExplorationCellContext } from '../exploration-cell-context';
import { CellTitle } from '../cell-title';
import { CollapsibleContainer, CollapseButton, CollapsibleContent } from '../collapsible-cell';
import { CellControls } from '../cell-controls';
import { ErrorBanner, IconBanner } from '../../../components/banner';
import { useExplorationContext } from '../exploration-context';
import { convertData, pipelineHasCohortOperation, validateCohortOperation } from './utils';
import { getCohortOperation } from '../../edit-cohort/utils';
import { CohortChart } from '../../components/charts/cohort-chart';
import { filterVariablesForPipeline } from '../../utils';
import { dereferencePipeline } from '../../pipeline/utils';
import { TableSkeleton } from '../../../components/skeleton-loader';
import { Icon } from '../../../components/icon';
import { SqlPreview } from '../sql-preview';

import { YamlPreview } from '../yaml-preview';

import styles from '../exploration.module.scss';

const MinHeight = 285;

interface EmptyViewProps {
  requiresAction: boolean;
}

const EmptyView = ({ requiresAction }: EmptyViewProps) => (
  <IconBanner
    requiresAction={requiresAction}
    iconName="Cohort"
    minHeight={MinHeight}
    title="Select chart settings"
    description="Use sections from this page or anything else from your data catalog"
  />
);

type InvalidViewProps = {
  message: string;
};

const InvalidView = (props: InvalidViewProps) => (
  <IconBanner
    iconName="Cohort"
    minHeight={MinHeight}
    title="Invalid cohort"
    description={props.message}
  />
);

type CohortCellViewProps = {
  cell: CohortCell;
  exploration: Exploration;
  accountId: string;
  onSelectCell?: () => void;
  onSetDraggable: (value: boolean) => void;
};

export const CohortCellView = (props: CohortCellViewProps) => {
  const { cell, exploration } = props;
  const { setCell, isSelectedCell, isCollapsible, getYaml, copyCell } = useExplorationCellContext();
  const screenSize = useScreenSize();
  const [isShowingSql, setIsShowingSql] = useState(false);
  const [isShowingYaml, setIsShowingYaml] = useState(false);
  const [isDragHovered, setIsDragHovered] = useState(false);

  const [containerRef, isInView] = useIsInView();

  const handleSetIsDragHovered = (value: boolean) => {
    props.onSetDraggable(value);
    setIsDragHovered(value);
  };

  const editButtonVisible = screenSize.breakpoint <= Breakpoint.md;

  return (
    <CollapsibleContainer
      className={styles.cohortViewCell}
      onClick={props.onSelectCell}
      ref={containerRef}>
      <div className={styles.cellHeader}>
        <div className={styles.cellControlsContainer}>
          <Icon
            name="DragHandle"
            size={10}
            className={styles.dragHandle}
            onMouseOver={() => handleSetIsDragHovered(true)}
            onMouseOut={() => handleSetIsDragHovered(false)}
          />
          <CellTitle
            exploration={exploration}
            value={cell.title ?? '(Untitled)'}
            onChange={(value) => setCell(setCellTitle(cell, value))}
          />
          <CellControls
            exploration={exploration}
            editButtonVisible={editButtonVisible}
            options={(defaultOptions) => [
              ...defaultOptions,
              {
                type: 'divider',
                sort: 30,
              },
              {
                label: 'Copy block',
                icon: <Icon name="Clipboard" size={16} />,
                onClick: () => copyCell(),
                sort: 31,
              },
              {
                label: 'Show YAML',
                icon: <Icon name="Code" size={16} />,
                onClick: () => setIsShowingYaml(true),
                sort: 32,
                disabled: !pipelineHasCohortOperation(cell.pipeline),
              },
              {
                label: 'Show SQL',
                icon: <Icon name="Database" size={16} />,
                onClick: () => setIsShowingSql(true),
                sort: 32,
                disabled: !pipelineHasCohortOperation(cell.pipeline),
              },
            ]}
          />
          {isCollapsible && <CollapseButton />}
        </div>
      </div>
      <CollapsibleContent isDragHovered={isDragHovered}>
        <ErrorBoundary fallback={(errorData) => <GenericFallback {...errorData} />}>
          {isShowingSql && (
            <SqlPreview
              pipeline={props.cell.pipeline}
              exploration={props.exploration}
              onClose={() => setIsShowingSql(false)}
            />
          )}
          {isShowingYaml && (
            <YamlPreview yaml={getYaml()} onClose={() => setIsShowingYaml(false)} />
          )}
          <CohortCellViewInner selected={isSelectedCell} isInView={isInView} {...props} />
        </ErrorBoundary>
      </CollapsibleContent>
    </CollapsibleContainer>
  );
};

type CohortCellViewInnerProps = CohortCellViewProps & { selected: boolean; isInView: boolean };

const CohortCellViewInner = (props: CohortCellViewInnerProps) => {
  const { cell, exploration, accountId, selected } = props;
  const { models, metrics: metrics } = useMetadataContext();
  const { getVariables } = useExplorationContext();
  const { isCollapsed } = useExplorationCellContext();

  const pipeline = dereferencePipeline(cell.pipeline, exploration);
  const cohortOperation = getCohortOperation(cell.pipeline);
  const variables = filterVariablesForPipeline(pipeline, getVariables());

  const [onCompleted, onError, skip] = useQueryLoadCondition(
    props.isInView,
    !isCollapsed,
    pipelineHasCohortOperation(cell.pipeline),
  );

  const { data, loading, error, fetchMore, refetch } = useExplorationDataQuery({
    variables: {
      accountId,
      baseModelId: pipeline.baseModelId,
      pipeline: pipeline.operations,
      first: 500,
      variables,
    },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'cache-and-network',
    skip,
    onCompleted,
    onError,
  });

  useEffect(() => {
    if (
      data?.account?.query?.pageInfo?.hasNextPage === true &&
      !isNil(data?.account?.query?.pageInfo?.endCursor)
    ) {
      fetchMore({ variables: { after: data?.account?.query?.pageInfo?.endCursor } });
    }
  }, [data, fetchMore]);

  const hasEnoughData = cell.pipeline.operations.length > 0;
  const cohortValidation = validateCohortOperation(cell.pipeline, exploration, {
    models,
    variables,
    metrics,
  });
  const cohortTimeInterval = cohortOperation?.parameters.cohortTimeInterval;
  const eventTimeInterval = cohortOperation?.parameters.eventTimeInterval;
  const cohortData = convertData(getNodes(data?.account?.query), eventTimeInterval);

  return (
    <div className={styles.cellSection}>
      {!hasEnoughData ? (
        <EmptyView requiresAction={!selected} />
      ) : !cohortValidation.isValid ? (
        <InvalidView message={cohortValidation.error ?? ''} />
      ) : skip || (loading && data === undefined) ? (
        <TableSkeleton rows={10} cols={5} key="loading" />
      ) : error !== undefined && isTimeoutError(error) ? (
        <ErrorBanner
          title="Database didn't respond in time"
          actions={[{ label: 'Retry', onClick: () => refetch() }]}
        />
      ) : error !== undefined ? (
        <ErrorBanner
          details={error.message}
          actions={[{ label: 'Retry', onClick: () => refetch() }]}
        />
      ) : (
        <CohortChart
          data={cohortData}
          height={MinHeight}
          cohortTimeInterval={cohortTimeInterval}
          eventTimeInterval={eventTimeInterval}
        />
      )}
    </div>
  );
};
