import { format } from 'date-fns';

import { nameToKey } from '../edit-pipeline/utils';

/**
 * Download a blob as a file
 * @param data Blob data
 * @param filename Name of the file
 */
const downloadBlob = (data: Blob, filename: string) => {
  const anchor = document.createElement('a');
  const blobURL = URL.createObjectURL(data);
  anchor.href = blobURL;
  anchor.download = filename;
  anchor.style.display = 'none';
  document.body.appendChild(anchor);
  anchor.click();
  URL.revokeObjectURL(blobURL);
  document.body.removeChild(anchor);
};

/**
 * Download a CSV string as a file
 * @param csvString CSV string
 * @param filename Name of the file
 */
export const downloadCsv = (csvString: string, filename: string) =>
  downloadBlob(new Blob([csvString], { type: 'text/csv' }), `${filename}.csv`);

/**
 * Generate a timestamped filename
 * @param name Name to include in the filename
 * @returns Timestamped filename e.g. `202101011200_name`
 */
export const generateTimestampedFilename = (name: string) => {
  const timestamp = format(new Date(), 'yyyyMMddHHmm');
  return `${timestamp}_${nameToKey(name)}`;
};
