import { MouseEventHandler, ReactNode, useEffect, useRef, useState } from 'react';
import { omit } from 'lodash';
import classNames from 'classnames';

import { Select } from '@/components/form/select';
import { CheckboxWithLabel } from '@/components/form/checkbox';
import { Badge } from '@/components/badge';
import { DateRangeSelector } from '@/components/form/date-range-selector';
import { Input } from '@/components/form/input';
import { Dropdown, DropdownMenuItem } from '@/components/dropdown';
import { IconButton } from '@/components/button';
import { Icon } from '@/components/icon';
import { createBackNavigationToast, useToastContext } from '@/components/toast';
import { useTrackEvent } from '@/lib/analytics';
import { DateTimeInput } from '@/components/form/datetime-input';
import { timePrecisionOptions } from '@/explore/utils/grouping';

import { useExplorationContext } from '../exploration-context';
import {
  getVariableColor,
  replaceDefinition,
  setVariableDefinitionDefaultValue,
} from '../../utils';
import { variableAlternateColors, variableColors } from './utils';
import {
  DateRangeParameter,
  VariableCell,
  VariableDefinition,
  booleanParameter,
  dateRangeParameter,
  enumParameter,
  dateParameter,
  DateParameter,
  ExplorationParameter,
  numberParameter,
  stringParameter,
  timeIntervalParameter,
} from '../../types';

import styles from './variable-cell.module.scss';
import explorationStyles from '../exploration.module.scss';

interface VariableInputProps {
  label: string;
  children: ReactNode;
  variables: VariableDefinition[];
  inputRef: React.RefObject<HTMLDivElement>;
}

const VariableInput = ({ label, children, variables, inputRef }: VariableInputProps) => (
  <div className={styles.container}>
    <Badge
      bgColor={getVariableColor(label, variables, variableAlternateColors)}
      textColor={getVariableColor(label, variables, variableColors)}
      iconName="Variable"
      label={label}
    />
    <div className={styles.input} ref={inputRef}>
      {children}
    </div>
  </div>
);

interface TextInputProps {
  value: string;
  type: string;
  onChange: (value: string) => void;
}

// Wrapper component around Input to trigger changes only when user has stopped changing the value. It mimicks
// the behavior of a normal input field. In React, the onChange has different behavior and this wrapper is meant
// to provide a similar behavior.
const TextInput = ({ value, type, onChange }: TextInputProps) => {
  const [internalValue, setInternalValue] = useState(value);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => setInternalValue(value), [value]);

  const handleKeyUp = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      triggerChange();
    }
  };

  const triggerChange = () => {
    if (value === internalValue) {
      return;
    }

    onChange(internalValue);
  };

  return (
    <Input
      value={internalValue}
      type={type}
      onBlur={triggerChange}
      onChange={(event) => setInternalValue(event.currentTarget.value)}
      onKeyDown={handleKeyUp}
    />
  );
};

interface StringInputProps {
  value: string;
  onChange: (value: string) => void;
}

const StringInput = ({ value, onChange }: StringInputProps) => (
  <TextInput value={value} type="text" onChange={onChange} />
);

interface NumberInputProps {
  value: number;
  onChange: (value: number) => void;
}

const NumberInput = ({ value, onChange }: NumberInputProps) => (
  <TextInput
    value={value.toString()}
    type="number"
    onChange={(value) => onChange(parseFloat(value))}
  />
);

interface DateRangeInputProps {
  value: DateRangeParameter;
  onChange: (value: DateRangeParameter) => void;
}

const DateRangeInput = ({ value, onChange }: DateRangeInputProps) => {
  const { precision, start, end, range } = value;

  return (
    <DateRangeSelector
      range={range}
      startDate={new Date(start)}
      endDate={new Date(end)}
      precision={precision}
      onChange={({ range, precision, startDate, endDate }) =>
        onChange({
          ...value,
          range,
          precision,
          start: startDate.toISOString(),
          end: endDate.toISOString(),
        })
      }
    />
  );
};

interface DateInputProps {
  value: DateParameter;
  onChange: (value: DateParameter) => void;
}

const DateInput = ({ value, onChange }: DateInputProps) => (
  <DateTimeInput value={value} onChange={onChange} />
);

interface VariableCellViewProps {
  cell: VariableCell;
  variables: VariableDefinition[];
  onSelectCell?: () => void;
  onSetDraggable: (value: boolean) => void;
}

export const VariableCellView = (props: VariableCellViewProps) => {
  const { cell, variables } = props;
  const { definition } = cell;

  const { exploration, deleteCell, deselectCell, setParameter, getParameter, setExploration } =
    useExplorationContext();
  const addToast = useToastContext();
  const trackEvent = useTrackEvent();
  const value = getParameter(definition.key) ?? '';

  const handleChange = (value: ExplorationParameter) => {
    trackEvent('Variable Value Changed', {
      explorationId: exploration.explorationId,
      name: exploration.name,
      definition,
      value,
    });
    setParameter(definition.key, value);
  };

  const handleSetDefaultValue = (value: ExplorationParameter) =>
    setExploration(
      replaceDefinition(setVariableDefinitionDefaultValue(definition, value), exploration, cell.id),
    );

  const handleDeleteCell = () => {
    deleteCell(cell.id);
    deselectCell();

    addToast(
      createBackNavigationToast(
        'Block Deleted',
        "If you didn't mean to delete it, you can use your browser back button or",
        'click undo',
      ),
    );

    trackEvent('Exploration Cell Deleted', {
      explorationId: exploration.explorationId,
      name: exploration.name,
      cell,
    });
  };

  // Avoid selecting cell when click happens either inside dropdown menu or inside variable input.
  const menuContainerRef = useRef<HTMLDivElement>(null);
  const variableInputRef = useRef<HTMLDivElement>(null);
  const handleCellClick: MouseEventHandler<HTMLDivElement> = (event) => {
    if (
      (variableInputRef.current?.contains(event.target as HTMLElement) ?? false) ||
      (menuContainerRef.current?.contains(event.target as HTMLElement) ?? false) ||
      props.onSelectCell === undefined
    ) {
      return;
    }

    props.onSelectCell();
  };

  return (
    <div className={styles.variableCell} onClick={handleCellClick}>
      <Icon
        name="DragHandle"
        size={10}
        className={classNames(explorationStyles.dragHandle, styles.dragHandle)}
        onMouseOver={() => props.onSetDraggable(true)}
        onMouseOut={() => props.onSetDraggable(false)}
      />
      <div className={styles.menu} ref={menuContainerRef}>
        <Dropdown
          align="right"
          trigger={(isOpen, setIsOpen) => (
            <IconButton
              icon="MoreHorizontal"
              size="small"
              title="More..."
              type="gray"
              onClick={() => setIsOpen(!isOpen)}
            />
          )}
          items={[
            {
              label: 'Delete variable',
              icon: <Icon name="Trash2" size={16} />,
              onClick: handleDeleteCell,
              sort: 10,
            },
            {
              label: 'Set value as default',
              icon: <Icon name="Bookmark" size={16} />,
              onClick: () => handleSetDefaultValue(value),
              sort: 20,
            },
          ]
            .sort((a, b) => a.sort - b.sort)
            .map((item) => omit(item, ['sort']) as DropdownMenuItem)}
        />
      </div>

      <VariableInput
        key={definition.key}
        label={definition.key}
        variables={variables}
        inputRef={variableInputRef}>
        {definition.kind === 'string' ? (
          <StringInput
            value={stringParameter.parse(value)}
            onChange={(value) => handleChange(value)}
          />
        ) : definition.kind === 'number' ? (
          <NumberInput
            value={numberParameter.parse(value)}
            onChange={(value) => handleChange(value)}
          />
        ) : definition.kind === 'enum' ? (
          <Select
            value={enumParameter.parse(value)}
            onChange={(value) => handleChange(value)}
            options={
              definition.options.map(({ value }) => ({
                label: value,
                value,
              })) ?? []
            }
          />
        ) : definition.kind === 'time_interval' ? (
          <Select
            value={timeIntervalParameter.parse(value)}
            onChange={(value) => handleChange(value)}
            options={timePrecisionOptions}
          />
        ) : definition.kind === 'date_range' ? (
          <DateRangeInput
            key={definition.key}
            value={dateRangeParameter.parse(value)}
            onChange={handleChange}
          />
        ) : definition.kind === 'boolean' ? (
          <CheckboxWithLabel
            checked={booleanParameter.parse(Boolean(value))}
            onChange={(value) => handleChange(value.checked)}
            id={definition.key}>
            {definition.key}
          </CheckboxWithLabel>
        ) : definition.kind === 'date' ? (
          <DateInput
            key={definition.key}
            value={dateParameter.parse(value)}
            onChange={handleChange}
          />
        ) : null}
      </VariableInput>
    </div>
  );
};
