import classNames from 'classnames';
import { useRef, useMemo } from 'react';

import { ErrorBoundary, GenericFallback } from '@/lib/error';
import { useResizeHandle } from '@/lib/hooks/use-resize-handle';
import { useScreenSize } from '@/lib/hooks';

import { useExplorationContext } from '../exploration-context';
import { ExplorationCellContextProvider } from '../exploration-cell-context';
import { CellSidebar } from './cell-sidebar';
import { ExplorationSidebar } from './exploration-sidebar';

import styles, { sidebarMinWidth, sidebarMaxWidth } from './sidebar.module.scss';

const SidebarMinWidth = parseInt(sidebarMinWidth);
const SidebarMaxWidth = parseInt(sidebarMaxWidth);

interface SidebarProps {
  onAddSection: () => void;
}
export const Sidebar = (props: SidebarProps) => {
  const { onAddSection } = props;

  const { selectedCell, selectedCellIndex, isEditorOpen } = useExplorationContext();
  const screenSize = useScreenSize();

  const resizeHandleRef = useRef<HTMLDivElement>(null);
  const sidebarRef = useRef<HTMLDivElement>(null);

  const maxSize = useMemo(
    () => Math.min(screenSize.width * 0.5, SidebarMaxWidth),
    [screenSize.width],
  );

  const sidebarWidth = useResizeHandle<HTMLDivElement, HTMLDivElement>(
    resizeHandleRef,
    sidebarRef,
    (target) => target.offsetWidth,
    (size, { dx }) => size - dx,
    (target, size) => (target.style.width = `${size}px`),
    { minSize: SidebarMinWidth, maxSize },
  );

  return (
    <div
      ref={sidebarRef}
      className={classNames(styles.sidebarContainer, {
        [styles.editorOpen]: isEditorOpen,
      })}
      style={{ width: sidebarWidth !== null ? `${sidebarWidth}px` : undefined }}>
      <div className={styles.resizeHandle} ref={resizeHandleRef} />
      <div className={styles.sidebar}>
        <ErrorBoundary fallback={(errorData) => <GenericFallback {...errorData} />}>
          {selectedCellIndex !== null && selectedCell !== null ? (
            <ExplorationCellContextProvider
              cell={selectedCell}
              cellIndex={selectedCellIndex}
              isCollapsible>
              <CellSidebar />
            </ExplorationCellContextProvider>
          ) : (
            <ExplorationSidebar onAddSection={onAddSection} />
          )}
        </ErrorBoundary>
      </div>
    </div>
  );
};
