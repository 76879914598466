import { generateUUID } from '@/lib/utils';
import { BasePipeline, Pipeline, PipelineOperation, PipelineWithParent } from '@/explore/types';

export const generatePipelineId = generateUUID;

export const isBasePipeline = (pipeline: Pipeline): pipeline is BasePipeline =>
  'baseModelId' in pipeline;

export const isPipelineWithParent = (pipeline: Pipeline): pipeline is PipelineWithParent =>
  'parentId' in pipeline;

export const createPipeline = (pipeline: Pipeline) =>
  isBasePipeline(pipeline) ? createBasePipeline(pipeline) : createPipelineWithParent(pipeline);

export const createBasePipeline = ({
  baseModelId,
  operations,
}: {
  baseModelId: string;
  operations: PipelineOperation[];
}): BasePipeline => ({
  pipelineId: generatePipelineId(),
  baseModelId,
  operations,
});

export const createPipelineWithParent = ({
  parentId,
  operations,
}: {
  parentId: string;
  operations: PipelineOperation[];
}): PipelineWithParent => ({
  pipelineId: generatePipelineId(),
  parentId,
  operations,
});
