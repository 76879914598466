import styles from './pipeline-model.module.scss';

const colors = [
  styles.color1,
  styles.color2,
  styles.color3,
  styles.color4,
  styles.color5,
  styles.color6,
];

export const pipelineColor = (index: number | undefined) =>
  index !== undefined ? colors[index % colors.length] : undefined;
