import { uniq } from 'lodash';

import { Exploration } from '@/explore/types';
import { notNil } from '@/lib/utils';

export const isModelExploration = (exploration: Exploration) =>
  getModelIdFromModelExploration(exploration) !== undefined;

const getModelIdFromModelExploration = (exploration: Exploration) =>
  exploration.options?.explorationForModelId;

const getExplorationSection = (exploration: Exploration) => exploration.labels.section;

export const hasExplorationSection = (exploration: Exploration) =>
  notNil(getExplorationSection(exploration));

export const getExplorationSections = (explorations: Exploration[]) =>
  uniq(explorations.map(getExplorationSection).filter(notNil)).sort();
