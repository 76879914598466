import { isString, uniq } from 'lodash';
import { parseISO, isValid, format } from 'date-fns';
import { toZonedTime } from 'date-fns-tz';
import { common, model } from '@gosupersimple/types';

import { dateRangeFromPrecision } from '@/explore/utils/drilldown';
import {
  containsISODateString,
  formatLocalDate,
  getDefaultFormattedLocalDate,
  LocalDateTimeFormat,
} from '@/lib/date';
import { isValueExpression } from '@/explore/pipeline/operation';
import { notNil } from '@/lib/utils';

import {
  FilterOperator,
  FilterValue,
  type CompositeFilterCondition,
  type Field,
  type FilterCondition,
  type NumberRange,
} from '../../types';
import { isNumberType } from '../../utils';
import { UIFilterOperator } from '../edit-filter';

export const requiresArrayValue = (operator: UIFilterOperator | FilterOperator) =>
  operator === 'in' || operator === 'notin';

export const parseFilterValue = (
  value: string,
  type: model.PropertyType | null,
  operator: UIFilterOperator,
): FilterValue => {
  if (isNumberType(type) && operator !== 'in' && value.length > 0 && !isNaN(Number(value))) {
    return Number(value);
  }
  if (requiresArrayValue(operator)) {
    const split = value.split(',').map((v) => v.trim());
    if (
      isNumberType(type) &&
      split.every((v) => v !== '' && !isNaN(Number(v)) && String(v).at(-1) !== '.')
    ) {
      return split.map((v) => Number(v));
    }
    return split;
  }
  return value;
};

export const stringifyFilterValue = (value: FilterValue): string => {
  if (Array.isArray(value)) {
    return value.join(', ').trim();
  }
  return value?.toString() ?? '';
};

export const createEmptyFilterCondition = (
  field?: Field,
  value?: FilterValue,
): FilterCondition => ({
  key: field?.key ?? '',
  operator: '==',
  value: ensureStableValue(field, undefined, value ?? ''),
});

export const createRangeFilterParameters = (
  range: NumberRange,
  field?: Field,
): CompositeFilterCondition => {
  const { start, end } = range;
  return {
    operator: 'and',
    operands: [
      {
        key: field?.key ?? '',
        operator: '>=',
        value: start,
      },
      {
        key: field?.key ?? '',
        operator: '<',
        value: end,
      },
    ],
  };
};

export const createDateFilterParameters = (
  field?: Field,
  value?: FilterValue,
): CompositeFilterCondition => {
  const date = new Date(String(value));
  const precision = field?.precision || 'month';
  const { startDate, endDate } = dateRangeFromPrecision(date, precision);

  return {
    operator: 'and',
    operands: [
      {
        key: field?.key ?? '',
        operator: '>=',
        value: precision === 'hour' ? startDate.toISOString() : formatLocalDate(startDate),
      },
      {
        key: field?.key ?? '',
        operator: '<=',
        value: precision === 'hour' ? endDate.toISOString() : formatLocalDate(endDate),
      },
    ],
  };
};

export const ensureValidDateValue = (value: Exclude<FilterValue, common.ExpressionValue>) => {
  return isString(value) && containsISODateString(value) ? value : getDefaultFormattedLocalDate();
};

export const ensureStableNumberValue = (value: Exclude<FilterValue, common.ExpressionValue>) => {
  return value === '' || isNaN(Number(value)) ? undefined : Number(value);
};

/**
 * Ensure the value is of the correct shape and displayable in the corresponding input.
 */
export const ensureStableValue = (
  field?: Field,
  operator?: UIFilterOperator,
  value?: FilterValue,
): FilterCondition['value'] => {
  if (isValueExpression(value)) {
    return value;
  }
  const requiresArray = operator !== undefined && requiresArrayValue(operator);
  switch (field?.type) {
    case 'Date':
      return requiresArray
        ? uniq([value].flat().map(ensureValidDateValue))
        : ensureValidDateValue(value);
    case 'Boolean':
      return requiresArray ? uniq([value].flat().map(Boolean)) : Boolean(value);
    case 'Number':
    case 'Integer':
    case 'Float':
      if (requiresArray) {
        const valueAsArray = [value].flat().filter(notNil);
        return valueAsArray.every((v) => !isNaN(Number(v)))
          ? valueAsArray.map(Number)
          : valueAsArray.map(String);
      }
      return ensureStableNumberValue(value);
    default:
      return requiresArray
        ? uniq(
            [value].flatMap((value) =>
              String(value)
                .split(',')
                .filter((v) => v !== ''),
            ),
          )
        : String(value);
  }
};

export const parseValueForFilter = (
  value: unknown,
  type: model.PropertyType | null,
  timezone: string,
): FilterValue => {
  const parsedValue = common.filterValue.parse(value);
  if (type === 'Date' && isString(parsedValue)) {
    // Convert UTC ISO format to account timezone
    const d = parseISO(parsedValue);
    return isValid(d) ? format(toZonedTime(d, timezone), LocalDateTimeFormat) : '';
  }
  return parsedValue;
};
