import { ReactNode, useState } from 'react';
import classNames from 'classnames';

import { Panel } from '@/components/panel';
import { Button } from '@/components/button';
import { Input } from '@/components/form/input';
import { useToastContext } from '@/components/toast';

import { useAccountContext } from '@/lib/accounts/context';
import { sanitizeEmail } from '@/lib/utils/email';

import { UserRole, useAddUserToAccountMutation } from '../../../graphql';

import styles from './invite-form.module.scss';
import stepStyles from '../step/step.module.scss';
import formStyles from '@/components/form/form.module.scss';

interface InviteFormProps {
  onClose: () => void;
  infoText: ReactNode;
}

export const InviteForm = ({ infoText, onClose }: InviteFormProps) => {
  const toast = useToastContext();
  const { account } = useAccountContext();
  const [addUser, { loading }] = useAddUserToAccountMutation();

  const [email, setEmail] = useState('');

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    try {
      await addUser({ variables: { accountId: account.accountId, email, role: UserRole.Admin } });

      setTimeout(
        () =>
          toast({
            kind: 'success',
            title: 'Invite sent',
            content: () => <div>Your team member will get an email shortly.</div>,
          }),
        500,
      );

      onClose();
    } catch {
      toast({
        kind: 'error',
        title: 'Error adding user',
        content: () => <div>Something went wrong. Please contact us if the issue persists.</div>,
      });
    }
  };

  return (
    <Panel className={classNames(stepStyles.panel, styles.inviteForm)}>
      <h1>Invite a team member</h1>
      {infoText}
      <form onSubmit={handleSubmit}>
        <div className={formStyles.formRow}>
          <label style={{ minWidth: '23%' }}>Email</label>
          <Input
            required
            type="email"
            size="large"
            value={email}
            placeholder="john@company.com"
            onChange={(e) => setEmail(sanitizeEmail(e.currentTarget.value))}
          />
        </div>
        <div className={formStyles.formRow}>
          <Button variant="primary" type="submit" size="large" loading={loading}>
            Send invite
          </Button>
          <Button variant="secondary" size="large" onClick={onClose} disabled={loading}>
            Cancel
          </Button>
        </div>
      </form>
    </Panel>
  );
};
