import { ApolloClient } from '@apollo/client';
import { pick } from 'lodash';

import { model } from '@gosupersimple/types';

import { convertRecordTypeTypes } from '@/explore/input';

import {
  ExplorationDataQuery,
  ExplorationDataQueryVariables,
  QuerySortDirection,
  getNodes,
} from '../../../graphql';
import { dereferencePipeline } from '../../pipeline/utils';
import {
  DereferencedPipelineOperation,
  Exploration,
  Fields,
  Model,
  Pipeline,
  PipelineOperation,
  PythonOperation,
  QueryVariables,
} from '../../types';
import { PythonBlockResult, TableResult } from './types';
import { filterVariablesForPipeline } from '../../utils';
import { flattenNestedList } from '../../grouping';
import { defaultPythonType, tsToPytonTypeMap } from './python';
import * as queries from '../../../generated/operation-documents';

const isPythonOperation = (op: PipelineOperation): op is PythonOperation =>
  op.operation === 'python';

export const getPythonOperation = (pipeline: Pipeline): PythonOperation | undefined =>
  pipeline.operations.find(isPythonOperation);

export const setPythonOperation = (
  pipeline: Pipeline,
  code: string,
  result: PythonBlockResult,
): Pipeline => {
  let tableContent = {};
  if (result?.type === 'table') {
    const { rows, fields: rawFields } = result;
    const fields = rawFields.map(({ key, type }) => ({ key, type }));

    tableContent = {
      fields,
      rows,
    };
  }

  return {
    ...pipeline,
    operations: [{ operation: 'python', parameters: { code, ...tableContent } }],
  };
};

export const parseCustomPackages = (rawCode: string) => {
  const regexp = /^\s*!(?!#)pip install (.+)/gm;
  const pyCode = rawCode
    .split('\n')
    .filter((line) => !line.trim().match(regexp))
    .join('\n');

  // custom packages from pypi with micropip
  const pypiPackages = [];
  if (rawCode.includes('!pip install ')) {
    const matches = rawCode.matchAll(regexp);
    for (const match of matches) {
      pypiPackages.push(match[1]);
    }
  }

  return { rawCode, pyCode, pypiPackages };
};

export class NoPythonDataSourceError extends Error {
  constructor(message: string) {
    super(message);
    this.name = 'NoPythonDataSourceError';
  }
}

export type PyDataSource = {
  baseModelId: string;
  operations: DereferencedPipelineOperation[];
  variables: QueryVariables;
  sort: { key: string; direction: QuerySortDirection }[];
};

export const findDataSource = (
  title: string,
  exploration: Exploration,
  models: Model[],
  getVariables: () => QueryVariables,
): PyDataSource => {
  const requestedCell = exploration.view.cells.find((c) =>
    'title' in c ? c.title === title : false,
  );
  const requestedModel = models.find((m) => m.name === title);

  if (requestedCell === undefined && requestedModel === undefined) {
    throw new NoPythonDataSourceError(`Cannot find "${title}" in this exploration or models`);
  }

  if (requestedCell !== undefined && !('pipeline' in requestedCell)) {
    throw new NoPythonDataSourceError(`Cannot load data from block "${title}"`);
  }

  if (requestedCell !== undefined) {
    const pipeline = dereferencePipeline(requestedCell.pipeline, exploration);
    const variables = filterVariablesForPipeline(pipeline, getVariables());
    const sort =
      'sort' in requestedCell
        ? (requestedCell.sort ?? []).map(({ key, direction }) => ({
            key: key,
            direction: direction === 'ASC' ? QuerySortDirection.Asc : QuerySortDirection.Desc,
          }))
        : [];

    return {
      baseModelId: pipeline.baseModelId,
      operations: pipeline.operations,
      variables: variables,
      sort: sort,
    };
  } else if (requestedModel !== undefined) {
    return {
      baseModelId: requestedModel.modelId,
      operations: [],
      variables: [],
      sort: [],
    };
  }
  throw new NoPythonDataSourceError(`Cannot find data source "${title}"`);
};

export const convertToPythonType = (type: model.PropertyType | null): string =>
  tsToPytonTypeMap[type as model.PropertyType] ?? defaultPythonType;

export const loadDataByDataSource = async (
  pyDataSource: PyDataSource,
  client: ApolloClient<object>,
  accountId: string,
  page?: boolean,
  limit?: number,
  page_size?: number,
  columns?: string[],
): Promise<TableResult | undefined> => {
  const rows: any[] = [];
  let recordType: Fields | undefined = undefined;
  let dynamicPageSize = page_size ?? 2000;
  if (limit !== undefined && dynamicPageSize > limit) {
    dynamicPageSize = limit;
  }

  let pageInfo: { endCursor?: string | null; hasNextPage: boolean } | undefined = undefined;
  do {
    const { data: result } = await client.query<
      ExplorationDataQuery,
      ExplorationDataQueryVariables
    >({
      query: queries.ExplorationData,
      variables: {
        accountId: accountId,
        baseModelId: pyDataSource.baseModelId,
        pipeline: pyDataSource.operations,
        variables: pyDataSource.variables,
        ...(dynamicPageSize !== undefined ? { first: dynamicPageSize } : {}),
        ...(pageInfo !== undefined && pageInfo.endCursor !== undefined
          ? { after: pageInfo?.endCursor }
          : {}),
        sort: pyDataSource.sort,
      },
    });
    const data = result as ExplorationDataQuery;

    pageInfo = data?.account?.query?.pageInfo ?? undefined;
    if (recordType === undefined) {
      recordType = convertRecordTypeTypes(data?.account?.query?.recordType);
    }

    const grouping =
      data?.account?.query?.grouping?.map(({ key }) => ({
        key,
      })) ?? [];
    const isGrouped = grouping.length > 0;

    const recordNodes = getNodes(data?.account?.query);
    let records: any[] = isGrouped ? flattenNestedList(recordNodes) : recordNodes;
    if (columns !== undefined) {
      const nameToKeyMap: { [key: string]: string } = recordType!.reduce(
        (acc, field) => ({
          ...acc,
          [field.name]: field.key,
        }),
        {},
      );

      const columnKeys = columns.map((columnName) => {
        if (!(columnName in nameToKeyMap)) {
          throw new Error(
            `Column: "${columnName}" does not exist in:\n${Object.entries(nameToKeyMap)
              .map((item) => item[0])
              .join(', ')}`,
          );
        }
        return nameToKeyMap[columnName];
      });
      records = records.map((record) => pick(record, columnKeys));
    }

    rows.push(...records);
    if (limit !== undefined && limit - rows.length < dynamicPageSize) {
      dynamicPageSize = limit - rows.length;
    }
  } while (!(page ?? false) && (pageInfo?.hasNextPage ?? false) && dynamicPageSize > 0);

  let fields = (recordType ?? []).map((field) => pick(field, ['key', 'name', 'type']));
  if (columns !== undefined) {
    fields = fields.filter((field) => columns.includes(field.name));
  }

  return {
    type: 'table',
    fields,
    rows: rows as [],
  };
};
