import {
  useState,
  useEffect,
  useLayoutEffect,
  useCallback,
  useMemo,
  memo,
  createContext,
  useContext,
  ReactNode,
  CSSProperties,
} from 'react';
import classnames from 'classnames';
import { isEqual } from 'lodash';

import { generateUUID } from '@/lib/utils';

import { Icon } from '../icon';
import { CloseButton, InlineButton } from '../button';

import styles, { exitTransitionDuration } from './toast.module.scss';

export type AddToastFn = (toast: ToastData) => void;

const ToastContext = createContext<AddToastFn>(() => {});

export const useToastContext = () => useContext(ToastContext);

interface ToastData {
  kind: 'success' | 'error' | 'info';
  title: string;
  icon?: ReactNode;
  content: (removeToast: () => void) => ReactNode;
}

interface ToastState extends ToastData {
  id: string;
  toastedAt: Date;
  isExiting: boolean;
}

const MaxStack = 3;
const ToastDuration = 5500;
const ExitDelay = Number(exitTransitionDuration) / 2;

interface ToastProps extends ToastState {
  idx: number;
  stackLength: number;
  style: CSSProperties;
  onRemove: (id: string) => void;
  onExpand: () => void;
}

const Toast = memo(function Toast({
  id,
  idx,
  kind,
  isExiting,
  stackLength,
  onRemove,
  onExpand,
  title,
  icon,
  content,
  style,
  toastedAt,
}: ToastProps) {
  return (
    <div
      key={id}
      style={style}
      data-time={toastedAt}
      className={classnames(styles.toast, {
        [styles.toastSuccess]: kind === 'success',
        [styles.toastError]: kind === 'error',
        [styles.toastInfo]: kind === 'info',
        [styles.toastExit]: isExiting,
      })}
      onMouseEnter={() => idx === stackLength - 1 && onExpand()}>
      {icon ?? <Icon name={kind === 'error' ? 'Info' : 'CheckCircle'} className={styles.icon} />}
      <div className={styles.content}>
        <div className={styles.title}>{title}</div>
        <div className={styles.body}>{content(() => onRemove(id))}</div>
      </div>
      <CloseButton iconSize="regular" className={styles.closeButton} onClick={() => onRemove(id)} />
    </div>
  );
});

export function ToastContextProvider({ children }: { children: ReactNode }) {
  const [toasts, setToasts] = useState<ToastState[]>([]);
  const [isExpanded, setIsExpanded] = useState(false);
  const [toastHeights, setToastHeights] = useState<number[]>([]);

  const cleanupToast = useCallback(() => {
    const now = Date.now();

    setToasts((currentToasts) => {
      const updatedToasts = currentToasts.map((toast) => {
        const isExpired = now - toast.toastedAt.getTime() >= ToastDuration;
        return isExpired && !toast.isExiting ? { ...toast, isExiting: true } : toast;
      });

      return !isEqual(updatedToasts, currentToasts) ? updatedToasts : currentToasts;
    });

    setTimeout(() => {
      setToasts((currentToasts) => {
        const filteredToasts = currentToasts.filter(
          (toast) => !toast.isExiting || now - toast.toastedAt.getTime() < ToastDuration,
        );

        return !isEqual(filteredToasts, currentToasts) ? filteredToasts : currentToasts;
      });
    }, ExitDelay);
  }, []);

  useEffect(() => {
    if (toasts.length > 0 && !isExpanded) {
      const interval = setInterval(cleanupToast, 1000);
      return () => clearInterval(interval);
    }
  }, [toasts.length, isExpanded, cleanupToast]);

  const addToast: AddToastFn = useCallback(
    (toast: ToastData) =>
      setToasts((toasts) => [
        { ...toast, id: generateUUID(), toastedAt: new Date(), isExiting: false },
        ...toasts,
      ]),
    [],
  );

  const removeToast = useCallback((id: string) => {
    setToasts((currentToasts) =>
      currentToasts.map((toast) => (toast.id === id ? { ...toast, isExiting: true } : toast)),
    );

    setTimeout(() => {
      setToasts((currentToasts) => currentToasts.filter((toast) => toast.id !== id));
    }, ExitDelay);
  }, []);

  const getToastStyle = (index: number, total: number) => {
    const position = total - 1 - index;
    if (position >= MaxStack) {
      return {
        opacity: 0,
        visibility: 'hidden',
        position: 'fixed',
        pointerEvents: 'none',
      } as CSSProperties;
    }

    if (isExpanded || position === 0) {
      return {};
    }

    const scale = 1 - 0.02 * position;
    const previousToastHeights = toastHeights
      .slice()
      .reverse()
      .slice(1, position)
      .reduce((acc, height) => acc + height, 0);
    const offset = previousToastHeights + (toastHeights[visibleToasts.length - 1 - position] || 0);

    return {
      transform: `translate3d(0, ${Math.round(offset)}px, 0) scale(${scale})`,
    };
  };

  const visibleToasts = useMemo(() => toasts.slice(0, MaxStack + 1), [toasts]);

  useLayoutEffect(() => {
    const toasts = Array.from(document.querySelectorAll(`.${styles.toast}`)) as HTMLElement[];
    const heights = toasts.map((el) => Math.round(el.offsetHeight));
    setToastHeights(heights);
  }, [toasts]);

  return (
    <ToastContext.Provider value={addToast}>
      <div className={styles.toasts} onMouseLeave={() => setIsExpanded(false)}>
        {visibleToasts
          .slice()
          .reverse()
          .map((toast, idx) => (
            <Toast
              idx={idx}
              key={toast.id}
              stackLength={visibleToasts.length}
              onRemove={() => removeToast(toast.id)}
              onExpand={() => setIsExpanded(true)}
              style={getToastStyle(idx, visibleToasts.length)}
              {...toast}
            />
          ))}
      </div>
      {children}
    </ToastContext.Provider>
  );
}

export const createBackNavigationToast = (
  title: string,
  caption: string,
  buttonLabel: string,
  kind: ToastData['kind'] = 'success',
): ToastData => ({
  kind,
  title,
  icon: <Icon name="Check" size={32} style={{ height: '24px' }} />,
  content: (removeToast) => (
    <span>
      {caption}{' '}
      <InlineButton
        color="white"
        onClick={() => {
          history.back();
          removeToast();
        }}>
        {buttonLabel}
      </InlineButton>
    </span>
  ),
});
