import React, { useMemo } from 'react';
import { first } from 'lodash';

import { ScaleLinear, ScaleTime } from 'd3-scale';

import { Bar, Line } from '@visx/shape';
import { Grid, GridColumns } from '@visx/grid';
import { AxisLeft, AxisRight } from '@visx/axis';
import { LinearGradient } from '@visx/gradient';
import { Group } from '@visx/group';
import { getStringWidth } from '@visx/text';
import { common, model } from '@gosupersimple/types';

import { formatPropertyValue } from '@/explore/utils/format';
import { getFormattedDateForPeriod } from '@/lib/date';
import { ChartType, TimeAggregationPeriod, ValueClickHandler } from '@/explore/types';

import { ChartTooltip } from '../common';
import { getBarSeriesPosition, getHighlightedTickValues, getTickValues } from './utils';
import { TimeSeriesData } from '../grouped-chart/types';
import { BarSeries } from './bar-series';
import { LineSeries } from './line-series';
import { AreaSeries } from './area-series';
import { SeriesValues } from './series-values';
import { increaseBrightness } from '../utils';
import { getLeftRightAxisFormats, getLeftRightAxisTypes } from '../grouped-chart/utils';

import commonStyles, {
  gridLineColor,
  gridHighlightedLineColor,
  hoverLineColor,
  graphVerticalPadding,
} from '../charts.module.scss';

const NumYTicks = 7;
const GraphVerticalPadding = parseInt(graphVerticalPadding);

export interface ChartColor {
  fill: string;
  highlight: string;
}

interface TimeSeriesChartProps {
  width: number;
  height: number;
  data: TimeSeriesData;
  dateScale: ScaleTime<number, number>;
  valueScales: {
    left: ScaleLinear<number, number>;
    right: ScaleLinear<number, number>;
  };
  aggPeriod: TimeAggregationPeriod | null;
  highlightedDate: Date | null;
  groupKey: string | undefined;
  onMouseMove?: (
    event: React.TouchEvent<SVGRectElement> | React.MouseEvent<SVGRectElement>,
  ) => void;
  onMouseLeave?: () => void;
  onValueClick?: ValueClickHandler;
  hideGrid: boolean;
  timezone: string;
}

export const TimeSeriesChart = (props: TimeSeriesChartProps) => {
  const {
    width,
    height,
    data,
    dateScale,
    valueScales,
    aggPeriod,
    highlightedDate,
    groupKey,
    onMouseMove,
    onMouseLeave,
    onValueClick,
    hideGrid,
    timezone,
  } = props;

  const tooltipData: TooltipData | null = useMemo(() => {
    if (highlightedDate === null) {
      return null;
    }

    const dataPoint = data.items.find(
      ({ dateValue }) => dateValue.getTime() === highlightedDate.getTime(),
    );

    if (dataPoint === undefined) {
      return null;
    }

    const points = data.series.map(({ key, label, type, format, axis, chartType, color }) => ({
      label,
      value: dataPoint.values[key] ?? null,
      type,
      format,
      date: dataPoint.dateValue,
      color,
      axis,
      chartType,
    }));

    return {
      date: highlightedDate,
      timezone,
      aggPeriod: aggPeriod,
      points,
    };
  }, [highlightedDate, data, timezone, aggPeriod]);

  const axisTypes = getLeftRightAxisTypes(data.series);
  const axisFormats = getLeftRightAxisFormats(data.series);

  const formatLeftAxisValue = (n: number) =>
    formatPropertyValue(n, {
      type: axisTypes.left,
      format: axisFormats.left,
      style: 'compact',
    });

  const formatRightAxisValue = (n: number) =>
    formatPropertyValue(n, {
      type: axisTypes.right,
      format: axisFormats.right,
      style: 'compact',
    });

  const yAxisWidths = {
    left: Math.max(
      ...valueScales.left
        .ticks(NumYTicks)
        .map((i) => formatLeftAxisValue(i.valueOf()))
        .map((i) => getStringWidth(i) ?? 0),
    ),
    right: Math.max(
      ...valueScales.right
        .ticks(NumYTicks)
        .map((i) => formatRightAxisValue(i.valueOf()))
        .map((i) => getStringWidth(i) ?? 0),
    ),
  };

  const renderBarSeries = () => {
    const barSeries = data.series.filter(({ chartType }) => chartType === 'bar');

    if (barSeries.length === 0) {
      return null;
    }

    let barSeriesIdx = 0;
    return barSeries.map(({ key, axis, color }) => {
      const seriesData = data.items.map((item) => ({
        date: item.dateValue,
        value: item.values[key] ?? 0,
      }));

      const { barWidth, x } = getBarSeriesPosition(
        dateScale,
        aggPeriod,
        barSeries.length,
        barSeriesIdx++,
      );

      return (
        <BarSeries
          key={key}
          data={seriesData}
          fillColor={color}
          valueScale={valueScales[axis]}
          dateScale={dateScale}
          barWidth={barWidth}
          x={x}
        />
      );
    });
  };

  const renderLineSeries = () => {
    const lineSeries = data.series.filter(({ chartType }) => chartType === 'line');
    return lineSeries.map(({ key, axis, chartType, color }) => {
      const seriesData = data.items.map((item) => ({
        date: item.dateValue,
        value: item.values[key] ?? 0,
      }));

      switch (chartType) {
        case 'line':
          return (
            <LineSeries
              key={key}
              data={seriesData}
              strokeColor={color}
              valueScale={valueScales[axis]}
              dateScale={dateScale}
            />
          );
        default:
          return (
            <AreaSeries
              key={key}
              data={seriesData}
              fillColor={color}
              strokeColor={increaseBrightness(color, 20)}
              valueScale={valueScales[axis]}
              dateScale={dateScale}
            />
          );
      }
    });
  };

  const renderAreaSeries = () => {
    const areaSeries = data.series.filter(({ chartType }) => chartType === 'area');
    return areaSeries.map(({ key, axis, color }) => {
      const seriesData = data.items.map((item) => ({
        date: item.dateValue,
        value: item.values[key] ?? 0,
      }));

      return (
        <AreaSeries
          key={key}
          data={seriesData}
          fillColor={color}
          strokeColor={increaseBrightness(color, 20)}
          valueScale={valueScales[axis]}
          dateScale={dateScale}
        />
      );
    });
  };

  const renderSeries = () => (
    <>
      {renderAreaSeries()}
      {renderLineSeries()}
      {renderBarSeries()}
    </>
  );

  const renderSeriesValues = () => {
    const numBarSeries = data.series.filter(({ chartType }) => chartType === 'bar').length;
    let barSeriesIdx = 0;
    return data.series.map((series) => {
      if (!series.showValues) {
        return null;
      }
      const seriesData = data.items.map((item) => ({
        date: item.dateValue,
        value: item.values[series.key],
      }));

      switch (series.chartType) {
        case 'bar': {
          const { barWidth, x } = getBarSeriesPosition(
            dateScale,
            aggPeriod,
            numBarSeries,
            barSeriesIdx++,
          );
          return (
            <SeriesValues
              key={series.key}
              series={series}
              data={seriesData}
              valueScale={valueScales[series.axis]}
              dateScale={dateScale}
              x={x + barWidth / 2}
            />
          );
        }
        case 'line':
        case 'area':
          return (
            <SeriesValues
              key={series.key}
              series={series}
              data={seriesData}
              valueScale={valueScales[series.axis]}
              dateScale={dateScale}
              avoidOverflow={numBarSeries === 0}
            />
          );
        default:
          return null;
      }
    });
  };

  const domainSizes = {
    left: valueScales.left.domain()[1] - valueScales.left.domain()[0],
    right: valueScales.right.domain()[1] - valueScales.right.domain()[0],
  };

  // Default to right as main axis in case no series on left axis
  const primaryValueScale = domainSizes.left > 0 ? valueScales.left : valueScales.right;

  // Force right axis ticks to align with primary axis ticks
  const rightAxisTicks = primaryValueScale
    .ticks(NumYTicks)
    .map((tick) => valueScales.right.invert(primaryValueScale(tick)));

  return (
    <div className={commonStyles.graph}>
      <svg width={width} height={height - GraphVerticalPadding}>
        {!hideGrid && (
          <>
            <Grid
              width={width}
              height={height}
              xScale={dateScale}
              yScale={primaryValueScale}
              rowTickValues={primaryValueScale.ticks(NumYTicks)}
              stroke={gridLineColor}
              columnTickValues={getTickValues(aggPeriod ?? 'month', dateScale)}
            />
            <GridColumns
              width={width}
              height={height}
              scale={dateScale}
              stroke={gridHighlightedLineColor}
              tickValues={getHighlightedTickValues(aggPeriod ?? 'month', dateScale)}
            />
          </>
        )}
        {data.series.map(({ key, color }) => (
          <LinearGradient
            key={key}
            id={`gradient-${color}`}
            from={color}
            to={color}
            fromOpacity={0.8}
            toOpacity={0.2}
          />
        ))}
        {renderSeries()}
        {renderSeriesValues()}
        {hideGrid ? null : (
          <>
            {domainSizes.left > 0 && (
              <AxisLeft
                left={yAxisWidths.left + 4}
                scale={valueScales.left}
                hideTicks
                hideAxisLine
                tickClassName={commonStyles.tickLabel}
                tickLabelProps={() => ({ dx: 4, dy: 10, textAnchor: 'end' })}
                tickValues={valueScales.left.ticks(NumYTicks)}
                tickFormat={(n) => formatLeftAxisValue(n.valueOf())}
              />
            )}
            {domainSizes.right > 0 && (
              <AxisRight
                left={width - yAxisWidths.right - 4}
                scale={valueScales.right}
                hideTicks
                hideAxisLine
                tickClassName={commonStyles.tickLabel}
                tickLabelProps={() => ({ dx: 7, dy: 10, textAnchor: 'end' })}
                tickValues={rightAxisTicks}
                tickFormat={(n) => formatRightAxisValue(n.valueOf())}
              />
            )}
          </>
        )}
        {highlightedDate && (
          <>
            <Group>
              <Line
                from={{ x: dateScale(highlightedDate), y: 0 }}
                to={{ x: dateScale(highlightedDate), y: height }}
                stroke={hoverLineColor}
                strokeWidth={1}
                pointerEvents="none"
                strokeDasharray="5,2"
              />
            </Group>
            {tooltipData?.points.map((point, i) => {
              if (point.date === null || point.chartType === 'bar') {
                return null;
              }

              return (
                <circle
                  key={i}
                  cx={dateScale(point.date)}
                  cy={valueScales[point.axis](point.value)}
                  r={5}
                  fill={point.color}
                  stroke="white"
                  strokeOpacity={0.2}
                  strokeWidth={1}
                  pointerEvents="none"
                />
              );
            })}
          </>
        )}
        <Bar
          width={width}
          height={height}
          fill="transparent"
          onTouchStart={onMouseMove}
          onTouchMove={onMouseMove}
          onMouseMove={onMouseMove}
          onMouseLeave={onMouseLeave}
          onClick={(e) => {
            if (highlightedDate === null || groupKey === undefined) {
              return;
            }
            const { clientX, clientY } = e;
            const date = highlightedDate.toISOString();
            onValueClick?.(clientX, clientY, groupKey, date, aggPeriod);
          }}
        />
      </svg>

      {tooltipData && (
        <Tooltip top={height * 0.3} left={dateScale(tooltipData.date)} tooltipData={tooltipData} />
      )}
    </div>
  );
};

interface TooltipData {
  date: Date;
  timezone: string;
  aggPeriod: TimeAggregationPeriod | null;
  points: {
    label: string;
    value: number;
    type: model.PropertyType | null;
    format?: common.PropertyValueFormat;
    date: Date;
    color: string;
    axis: 'left' | 'right';
    chartType: ChartType;
  }[];
}

const Tooltip = (props: { tooltipData: TooltipData; left: number; top: number }) => {
  const { tooltipData, left, top } = props;

  if (tooltipData.points.length === 0) {
    return null;
  }

  const totalValueType = first(tooltipData.points)?.type ?? 'Number';
  const totalValueFormat = tooltipData.points.every(
    (point) => point.format === first(tooltipData.points)?.format,
  )
    ? first(tooltipData.points)?.format
    : undefined;

  return (
    <ChartTooltip
      top={top}
      left={left}
      title={getFormattedDateForPeriod(
        tooltipData.date,
        tooltipData.aggPeriod,
        tooltipData.timezone,
      )}>
      <ul>
        {tooltipData.points.length > 1 && (
          <li>
            Total:{' '}
            <strong>
              {formatPropertyValue(
                tooltipData.points
                  .map((point) => point.value)
                  .reduce((sum, value) => sum + value, 0),
                { type: totalValueType, format: totalValueFormat },
              )}
            </strong>
          </li>
        )}
        {tooltipData.points.map((point, i) => (
          <li key={i}>
            <span className={commonStyles.seriesMarker} style={{ background: point.color }} />
            {point.label ?? '-'}:{' '}
            <strong>
              {formatPropertyValue(point.value, { type: point.type, format: point.format })}
            </strong>
          </li>
        ))}
      </ul>
    </ChartTooltip>
  );
};
