import { first, last } from 'lodash';

import {
  JoinStrategyDirect,
  JoinStrategySteps,
  Model,
  ModelKind,
  Relation,
  modelKinds,
} from '../types';

export const getModel = (models: Model[], modelId: string) =>
  models.find((model) => model.modelId === modelId);

export const getModelOrThrow = (models: Model[], modelId: string) => {
  const model = getModel(models, modelId);

  if (model === undefined) {
    throw new Error(`Model '${modelId}' not found`);
  }

  return model;
};

export const getRelatedModel = (models: Model[], modelId: string, relationKey: string) => {
  const model = getModelOrThrow(models, modelId);
  const relation = getModelRelationOrThrow(relationKey, model);
  return getModelOrThrow(models, relation.modelId);
};

const hasModel = (models: Model[], modelId: string) =>
  models.some((model) => model.modelId === modelId);

export const getModelRelation = (relationKey: string, model: Model) =>
  model.relations.find((relation) => relation.key === relationKey);

export const getModelRelationOrThrow = (relationKey: string, model: Model) => {
  const relation = getModelRelation(relationKey, model);

  if (relation === undefined) {
    throw new Error(`Relation not found: ${relationKey}`);
  }

  return relation;
};

export const hasModelRelation = (model: Model, relationKey: string) =>
  model.relations.some((relation) => relation.key === relationKey);

const isValidModelRelation = (model: Model, relationKey: string, allModels: Model[]) =>
  hasModelRelation(model, relationKey) &&
  hasModel(allModels, getModelRelationOrThrow(relationKey, model).modelId);

export const getValidModelRelations = (model: Model, models: Model[]) =>
  model.relations.filter((relation) => isValidModelRelation(model, relation.key, models));

export const getModelKind = (model: Pick<Model, 'labels'>): ModelKind | null =>
  modelKinds.find((kind) => model.labels.kind === kind) ?? null;

export const getRelationToSemanticModel = (
  model: Model,
  models: Model[],
  modelKind: ModelKind,
  validRelationTypes?: string[],
) => {
  return (
    getValidModelRelations(model, models)
      .filter((relation) => validRelationTypes?.includes(relation.type) ?? true)
      .find(
        (relation) => getModelOrThrow(models, relation.modelId).semantics?.kind === modelKind,
      ) ?? null
  );
};

export const getJoinKeyToSemanticModel = (model: Model, models: Model[], modelKind: ModelKind) => {
  const relation = getRelationToSemanticModel(model, models, modelKind);
  return relation === null ? null : getJoinKeys(relation, model.relations, models)?.joinKeyOnBase;
};

/**
 * Get the joinKeyOnBase and final joinKeyOnRelated for a relation.
 */
export const getJoinKeys = (relation: Relation, relations: Relation[], models: Model[]) => {
  if (relation.joinStrategy === undefined) {
    throw new Error(`Relation ${relation.key} has no join keys`);
  }
  const resolved = resolveJoinStrategy(relation.joinStrategy, relations, models);
  const firstStep = first(resolved);
  const lastStep = last(resolved);
  if (firstStep === undefined || lastStep === undefined) {
    throw new Error(`Relation ${relation.key} has no join keys`);
  }
  return {
    joinKeyOnBase: firstStep.joinKeyOnBase,
    joinKeyOnRelated: lastStep.joinKeyOnRelated,
  };
};

export const getJoinKeyOnBase = (relation: Relation, model: Model): string => {
  const { joinStrategy } = relation;
  if (joinStrategy === undefined) {
    throw new Error(`Relation ${relation.key} has no join strategy`);
  }
  if (!('steps' in joinStrategy)) {
    return joinStrategy.joinKeyOnBase;
  }
  if (joinStrategy.steps.length === 0) {
    throw new Error(`Join strategy has no steps`);
  }
  return getJoinKeyOnBase(getModelRelationOrThrow(joinStrategy.steps[0].relationKey, model), model);
};

/**
 * Resolve any join strategy into an array of joined models and the corresponding join keys used for joining them.
 */
export const resolveJoinStrategy = (
  joinStrategy: JoinStrategyDirect | JoinStrategySteps | undefined,
  relations: Relation[],
  models: Model[],
): { joinKeyOnBase: string; joinKeyOnRelated: string }[] => {
  if (joinStrategy === undefined) {
    return [];
  }
  if (!('steps' in joinStrategy)) {
    return [
      {
        joinKeyOnBase: joinStrategy.joinKeyOnBase,
        joinKeyOnRelated: joinStrategy.joinKeyOnRelated,
      },
    ];
  }
  if (joinStrategy.steps.length === 0) {
    return [];
  }
  return joinStrategy.steps.reduce(
    (acc, step) => {
      const relation = relations.find((relation) => relation.key === step.relationKey);
      if (relation === undefined) {
        throw new Error(`Relation ${step.relationKey} not found`);
      }
      if (relation.joinStrategy === undefined) {
        throw new Error(`Relation ${step.relationKey} has no join strategy specified`);
      }
      const resolved = resolveJoinStrategy(relation.joinStrategy, relations, models);
      relations = getModelOrThrow(models, relation.modelId).relations;
      return [...acc, ...resolved];
    },
    [] as { joinKeyOnBase: string; joinKeyOnRelated: string }[],
  );
};

export const getIconForFieldType = (type: string | null) => {
  switch (type) {
    case 'Number':
    case 'Integer':
    case 'Float':
      return 'Hash';
    case 'String':
      return 'AlignLeft';
    case 'Boolean':
      return 'CheckSquare';
    case 'Date':
      return 'Calendar';
    case 'Enum':
      return 'Triangle';
    case 'Array':
      return 'List';
    case 'Object':
      return 'Box';
    case 'Interval':
      return 'Clock';
    default:
      return 'Box';
  }
};
