import {
  useRef,
  useState,
  useEffect,
  useCallback,
  useMemo,
  createContext,
  useContext,
} from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import { useScrollLock } from 'usehooks-ts';
import classnames from 'classnames';

import { useNavigationQuery, useExplorationsQuery } from '@/graphql';
import { useBuildAccountUrl, useSelectedAccount } from '@/lib/accounts/context';
import { useScreenSize, Breakpoint } from '@/lib/hooks';
import { useTrackEvent } from '@/lib/analytics';
import { buildExplorationUrl } from '@/explore/utils/url';
import { IconButton, InlineButton } from '@/components/button';
import { Tooltip, DefaultTooltipDelay } from '@/components/tooltip';
import { convertModelTypes } from '@/explore/input';
import { getModelExploration } from '@/explore/utils';
import { useResizeHandle } from '@/lib/hooks/use-resize-handle';
import { showChatWidget } from '@/lib/utils/support';
import { getModelSections } from '@/core/model';

import { hasExplorationSection } from '@/core/exploration';

import { AccountMenu, DefaultAccountMenu } from '../header/account-menu';
import { Dropdown, DropdownMenuItem } from '../dropdown';
import { Icon } from '../icon';

import styles, { sidebarMinWidth, sidebarMaxWidth } from './sidebar-layout.module.scss';

const SidebarMinWidth = parseInt(sidebarMinWidth);
const SidebarMaxWidth = parseInt(sidebarMaxWidth);

interface Layout {
  isLeftSidebarOpen: boolean;
  toggleLeftSidebar: (newVal?: boolean) => void;
  isRightSidebarOpen: boolean;
  toggleRightSidebar: (newVal?: boolean) => void;
  setActiveExplorationSourceId: (explorationId: string | null) => void;
}
const LayoutContext = createContext<Layout>({
  isLeftSidebarOpen: false,
  toggleLeftSidebar: () => {},
  isRightSidebarOpen: false,
  toggleRightSidebar: () => {},
  setActiveExplorationSourceId: () => {},
});
export const useLayoutContext = () => useContext(LayoutContext);

const Logo = () => (
  <img src="/logo-pinguin.svg" style={{ width: 24 }} title="Supersimple" alt="Supersimple logo" />
);

interface NavItemProps {
  idx: number;
  isSavedExploration?: boolean;
  item: {
    explorationId: string;
    name: string;
    description?: string | null;
  };
}

interface ModelSection {
  section: string;
  children: React.ReactNode[];
}
const ModelSection = ({ section, children }: ModelSection) => {
  const [isCollapsed, setIsCollapsed] = useState(false);
  return (
    <>
      <InlineButton
        className={styles.subsectionTitle}
        onClick={() => setIsCollapsed((val) => !val)}>
        {section}
        {isCollapsed ? <Icon name="ChevronDown" size={12} /> : <Icon name="ChevronUp" size={12} />}
      </InlineButton>
      {!isCollapsed && <div className={styles.sidebarListings}>{children}</div>}
    </>
  );
};

export function LayoutContextProvider({
  onClickLogOut,
  children,
}: {
  children: React.ReactNode;
  onClickLogOut: () => void;
}) {
  const [isLeftSidebarOpen, setIsLeftSidebarOpen] = useState(true);
  const [isRightSidebarOpen, setIsRightSidebarOpen] = useState(true);
  const [activeExplorationSourceId, setActiveExplorationSourceId] = useState<string | null>(null);
  const trackEvent = useTrackEvent();
  // Used for re-opening the sidebar when the user expands the window
  const leftSidebarNarrowState = useRef(false);
  // TODO: Add keyboard shortcuts to toggle sidebar
  const toggleLeftSidebar = (newVal?: boolean) => setIsLeftSidebarOpen((prev) => newVal ?? !prev);
  const toggleRightSidebar = (newVal?: boolean) => setIsRightSidebarOpen((prev) => newVal ?? !prev);

  const location = useLocation();
  const account = useSelectedAccount();
  const buildAccountUrl = useBuildAccountUrl();
  const screenSize = useScreenSize();
  const { data } = useNavigationQuery({
    variables: {
      accountId: account?.accountId ?? '',
    },
    skip: account === null,
    fetchPolicy: 'cache-first',
  });

  // Scroll lock for mobile
  const { lock, unlock, isLocked } = useScrollLock({
    autoLock: false,
  });
  useEffect(() => {
    if (screenSize.breakpoint <= Breakpoint.lg) {
      isLeftSidebarOpen ? lock() : unlock();
    } else if (screenSize.breakpoint > Breakpoint.lg && isLocked) {
      unlock();
    }
  }, [isLeftSidebarOpen, lock, unlock, isLocked, screenSize.breakpoint]);

  useEffect(() => {
    if (screenSize.breakpoint <= Breakpoint.lg && !leftSidebarNarrowState.current) {
      leftSidebarNarrowState.current = true;
      setIsLeftSidebarOpen(false);
    } else if (screenSize.breakpoint > Breakpoint.lg && leftSidebarNarrowState.current) {
      setIsLeftSidebarOpen(true);
      leftSidebarNarrowState.current = false;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [screenSize.breakpoint]);

  useEffect(() => {
    if (!location.pathname.includes('/explore/')) {
      setActiveExplorationSourceId(null);
    }
  }, [location.pathname]);

  const resizeHandleRef = useRef<HTMLDivElement>(null);
  const sidebarRef = useRef<HTMLDivElement>(null);
  const sidebarWidth = useResizeHandle<HTMLDivElement, HTMLDivElement>(
    resizeHandleRef,
    sidebarRef,
    (target) => target.offsetWidth,
    (size, { dx }) => size + dx,
    (target, size) => (target.style.width = `${size}px`),
    { minSize: SidebarMinWidth, maxSize: SidebarMaxWidth },
  );

  const { data: explorations } = useExplorationsQuery({
    variables: { accountId: account.accountId },
    fetchPolicy: 'cache-first',
  });

  const models = useMemo(() => {
    if (explorations?.account?.models === undefined) {
      return [];
    }
    return convertModelTypes(explorations.account.models) ?? [];
  }, [explorations]);

  const modelSections = useMemo(() => getModelSections(models), [models]);
  const modelExplorations = useMemo(
    () => models.map((model) => getModelExploration(model)),
    [models],
  );
  const modelExplorationsWithoutSections = useMemo(
    () => modelExplorations.filter((exploration) => !hasExplorationSection(exploration)),
    [modelExplorations],
  );

  const savedExplorations = useMemo(
    () => explorations?.account?.explorations ?? [],
    [explorations],
  );

  const pinnedExplorations = useMemo(() => {
    return (
      data?.account?.navigation.map((item) => {
        const explorationDetails = savedExplorations.find(
          (exploration) => exploration.explorationId === item.explorationId,
        );

        return {
          explorationId: item.explorationId,
          name: item.label,
          description: explorationDetails?.description,
          url: buildAccountUrl(buildExplorationUrl(item)),
        };
      }) ?? []
    );
  }, [data?.account, buildAccountUrl, savedExplorations]);

  const handleMobileNavigation = useCallback(() => {
    if (screenSize.breakpoint <= Breakpoint.lg) {
      setIsLeftSidebarOpen(false);
    }
  }, [screenSize.breakpoint]);

  const handleNavClick = useCallback(() => {
    setActiveExplorationSourceId(null);
    handleMobileNavigation();
  }, [setActiveExplorationSourceId, handleMobileNavigation]);

  const helpMenuItems: DropdownMenuItem[] = [
    {
      type: 'link',
      label: 'Account Settings',
      icon: <Icon name="Settings" size={16} />,
      href: buildAccountUrl('/settings'),
    },
    { type: 'divider' },
    {
      type: 'button',
      label: 'Get Help',
      icon: <Icon name="MessageSquare" size={16} />,
      className: 'intercom-launcher',
      onClick: () => {
        showChatWidget();
        handleMobileNavigation();
      },
    },
    {
      type: 'link',
      label: 'Documentation',
      icon: <Icon name="Edit2" size={16} />,
      href: 'https://docs.supersimple.io',
      external: true,
    },
    { type: 'divider' },
    {
      type: 'button',
      label: 'Log out',
      icon: <Icon name="X" size={16} />,
      onClick: onClickLogOut,
    },
  ];

  const NavItem = ({ item, idx, isSavedExploration = false }: NavItemProps) => {
    const tooltipContent = (
      <>
        <div className={styles.sidebarTooltipTitle}>{item.name}</div>
        <div className={styles.sidebarTooltipContent}>{item.description}</div>
      </>
    );

    return (
      <Tooltip content={tooltipContent} placement="right" delay={DefaultTooltipDelay}>
        <NavLink
          key={idx}
          to={buildAccountUrl(buildExplorationUrl(item))}
          className={({ isActive }) =>
            classnames(styles.sidebarListing, {
              [styles.active]:
                isActive ||
                (isSavedExploration && item.explorationId === activeExplorationSourceId),
            })
          }
          onClick={handleNavClick}>
          {item.name}
        </NavLink>
      </Tooltip>
    );
  };

  return (
    <LayoutContext.Provider
      value={{
        isLeftSidebarOpen,
        toggleLeftSidebar,
        isRightSidebarOpen,
        toggleRightSidebar,
        setActiveExplorationSourceId,
      }}>
      {isLeftSidebarOpen ? (
        <aside
          className={classnames(styles.leftSidebar)}
          style={{ width: sidebarWidth !== null ? `${sidebarWidth}px` : undefined }}
          ref={sidebarRef}>
          <div className={styles.resizeHandle} ref={resizeHandleRef} />
          <div className={styles.topRow}>
            <NavLink
              to={buildAccountUrl('/explore')}
              style={{ textDecoration: 'none', color: 'inherit' }}>
              <Logo />
            </NavLink>

            <Tooltip content="Collapse sidebar">
              <IconButton
                icon="ChevronLeft"
                size="small"
                className={styles.toggleBtn}
                onClick={() => {
                  toggleLeftSidebar(false);
                  trackEvent('Left Sidebar Closed');
                }}
              />
            </Tooltip>
          </div>

          <AccountMenu
            Component={DefaultAccountMenu}
            accountSettingsUrl={buildAccountUrl('/settings')}
            onClickLogOut={onClickLogOut}
          />

          <div className={styles.sidebarContent}>
            <div className={styles.sidebarListings}>
              <NavLink
                end
                to={buildAccountUrl('/explore')}
                className={({ isActive }) =>
                  classnames(styles.sidebarListing, {
                    [styles.active]: isActive,
                  })
                }
                onClick={handleNavClick}>
                Explore
              </NavLink>
            </div>
            {pinnedExplorations.length > 0 && (
              <>
                <div className={styles.sectionTitle}>
                  <Icon name="Bookmark" size={16} />
                  Pinned
                </div>
                <div className={styles.sidebarListings}>
                  {pinnedExplorations.map((item, idx) => (
                    <NavItem key={item.explorationId} item={item} idx={idx} isSavedExploration />
                  ))}
                </div>
              </>
            )}
            {savedExplorations.length > 0 && (
              <>
                <div className={styles.sectionTitle}>
                  <Icon name="Exploration" size={16} />
                  Explorations
                </div>
                <div className={styles.sidebarListings}>
                  {savedExplorations.map((item, idx) => (
                    <NavItem key={item.explorationId} item={item} idx={idx} isSavedExploration />
                  ))}
                </div>
              </>
            )}

            <div className={styles.sectionTitle}>
              <Icon name="Model" size={16} />
              Models
            </div>
            {modelSections.map((section) => (
              <ModelSection key={section} section={section}>
                {modelExplorations
                  .filter(({ labels }) => labels.section === section)
                  .map((item, idx) => (
                    <NavItem key={item.explorationId} item={item} idx={idx} />
                  ))}
              </ModelSection>
            ))}
            {modelExplorationsWithoutSections.length > 0 && (
              <>
                {modelSections.length > 0 ? (
                  <ModelSection key="other-models" section="Other Data Models">
                    {modelExplorationsWithoutSections.map((item, idx) => (
                      <NavItem key={`model-${item.explorationId}`} item={item} idx={idx} />
                    ))}
                  </ModelSection>
                ) : (
                  <div className={styles.sidebarListings}>
                    {modelExplorationsWithoutSections.map((item, idx) => (
                      <NavItem key={`model-${item.explorationId}`} item={item} idx={idx} />
                    ))}
                  </div>
                )}
              </>
            )}
          </div>

          <footer className={styles.sidebarFooter}>
            <Dropdown
              items={helpMenuItems}
              align="left"
              className={styles.sidebarListing}
              trigger={(isOpen, setIsOpen) => (
                <button className={styles.footerBtn} onClick={() => setIsOpen(!isOpen)}>
                  <Icon name="Settings" size={16} />
                  Settings
                </button>
              )}
            />
          </footer>
        </aside>
      ) : (
        <aside className={styles.collapsedSidebar}>
          <Tooltip content="Expand sidebar">
            <IconButton
              icon="ChevronRight"
              size="small"
              className={styles.toggleBtn}
              onClick={() => {
                toggleLeftSidebar(true);
                trackEvent('Left Sidebar Opened');
              }}
            />
          </Tooltip>
        </aside>
      )}
      {children}
    </LayoutContext.Provider>
  );
}
